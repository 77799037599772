import React from 'react';

import InsightGroup from '../listItem/insightGroup';

class InsightsCarousel extends React.Component {
  onSelectNumber=(number)=>{
    this.props.onSelectNumber(number);
  }
  
  renderGroup=(item, index)=>{
    const {
      sections,
      selection,
      numberOption
    } = this.props;    
    return (
      <InsightGroup
        key={index} 
        title={item.title}
        options={numberOption[index]}
        selectedNumber={selection}
        onSelectNumber={this.onSelectNumber} />
    );
  }
  
  render() {
    const {
      sections,
      numberOption
    } = this.props;
    const sectionList = sections.map(this.renderGroup);
    return (
      <section id="content" className="pbc-deeppurple-bg">
        <div className="content-wrap nopadding nomargin">
          <div className="section pbc-section nobg nomargin pt-4">
            <div className="container clearfix">
              <div className="row clearfix mb-3">
                <div className="col-12 nopadding">
                  <div id="oc-testi" className="owl-carousel carousel-widget clearfix" 
                       data-margin="0" data-pagi="false" data-items-sm="1" data-items-md="3" data-items-xl="3" data-loop="true">
                    {sectionList}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

InsightsCarousel.defaultProps = {
  sections: [],
  selection: [],
  numberOption: [],
  onSelectNumber: ()=>{}
};

export default InsightsCarousel;