const MONTH = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 
               'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'];
const MS_PER_SECOND = 1000;
const MS_PER_MINUTE = MS_PER_SECOND * 60;
const MS_PER_HOUR   = MS_PER_MINUTE * 60;
const MS_PER_DAY    = MS_PER_HOUR * 24;

export default class DateTime {
  static getCurrentDate() {
    const date = new Date();
    return this.getDateFromMS(date.getTime());
  }
  
  static getCurrentMonthYear() {
    const date = new Date();
    return this.getMonthYearFromMS(date.getTime());
  }
  
  static getDateFromMS(miliseconds) {
    const date = new Date(miliseconds);
    const d = date.getDate();
    const m = MONTH[date.getMonth()];
    const y = date.getFullYear();
    return d + ' ' + m + ' ' + y;
  }
  
  static getMonthYearFromMS(miliseconds) {
    const date = new Date(miliseconds);
    const m = MONTH[date.getMonth()];
    const y = date.getFullYear();
    return m + ' ' + y;
  }
  
  static getCountBreakdown(endtime) {
    const dt = new Date();
    const cur = dt.getTime();
    const diff = endtime - cur;
    if (diff <= 0) {
      return {day: 0, hour: 0, min: 0, sec: 0};
    }
    const day  = parseInt(diff / MS_PER_DAY);
    const hour = parseInt((diff - (day * MS_PER_DAY)) / MS_PER_HOUR);
    const min  = parseInt((diff - ((day * MS_PER_DAY) + (hour * MS_PER_HOUR))) / MS_PER_MINUTE);
    const sec  = parseInt((diff - ((day * MS_PER_DAY) + (hour * MS_PER_HOUR) + (min * MS_PER_MINUTE))) / MS_PER_SECOND);
    return {day: day, hour: hour, min: min, sec: sec};
  }
  
  static havePassed(miliseconds) {
    const expired = new Date(miliseconds);
    const date = new Date();
    return expired.getTime() < date.getTime();
  }
}
