import React from 'react';

import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      secondName: '',
      email: '',
      password: '',
      confirmPassword: '',
      referralCode: ''      
    };
  }
  
  onChangeFirstName=(evt)=>{
    this.setState({
      firstName: evt.target.value
    });
  }
  
  onChangeSecondName=(evt)=>{
    this.setState({
      secondName: evt.target.value
    });
  }
  
  onChangeEmail=(evt)=>{
    this.setState({
      email: evt.target.value
    });
  }
  
  onChangePassword=(evt)=>{
    this.setState({
      password: evt.target.value
    });
  }
  
  onChangeConfirmPassword=(evt)=>{
    this.setState({
      confirmPassword: evt.target.value
    });
  }
  
  onChangeReferralCode=(evt)=>{
    this.setState({
      referralCode: evt.target.value
    });
  }
  
  handleRegister=()=>{
    const {
      firstName,
      secondName,
      email,
      password,
      confirmPassword,
      referralCode      
    } = this.state;
    SoundManager.playPlay();
    this.props.registerUser(firstName, secondName, email, password, referralCode);
  }
  
  render() {
    const {
      firstName,
      secondName,
      email,
      password,
      confirmPassword,
      referralCode      
    } = this.state;
    return (
      <div className="tab-content clearfix" id="tabs-3">
        <form className="login-form mb-3">
          <div className="col_half col_mb">
            <input type="text" id="signup-first-name" name="signup-first-name" className="form-control not-dark" 
                   value={firstName} placeholder={LanguageData.getText('InitialSignup', 'isu_008')} onChange={this.onChangeFirstName} />
          </div>

          <div className="col_half col_mb col_last">
            <input type="text" id="signup-last-name" name="signup-last-name" className="form-control not-dark" 
                   value={secondName} placeholder={LanguageData.getText('InitialSignup', 'isu_009')} onChange={this.onChangeSecondName}  />
          </div>

          <div className="col_full col_mb">
            <input type="email" id="signup-form-email" name="signup-form-email" className="form-control not-dark" 
                   value={email} placeholder={LanguageData.getText('InitialSignup', 'isu_010')} onChange={this.onChangeEmail}  />
          </div>

          <div className="col_full col_mb">
            <input type="password" id="signup-form-password" name="signup-form-password" className="form-control not-dark" 
                   value={password} placeholder={LanguageData.getText('InitialSignup', 'isu_011')} onChange={this.onChangePassword}  />
          </div>

          <div className="col_full col_mb">
            <input type="password" id="signup-form-confirm-password" name="signup-form-confirm-password" className="form-control not-dark" 
                   value={confirmPassword} placeholder={LanguageData.getText('InitialSignup', 'isu_012')} onChange={this.onChangeConfirmPassword}  />
          </div>

          <div className="col_full col_mb">
            <input type="text" id="signup-referral-code" name="signup-referral-code" className="form-control not-dark"
                   value={referralCode} placeholder={LanguageData.getText('InitialSignup', 'isu_013')} onChange={this.onChangeReferralCode}  />
          </div>

          <div className="col_full nobottommargin">
            <button className="button nomargin btn-block pbc-btn" id="signup-form-submit" name="signup-form-submit" 
                    value="signup" onClick={this.handleRegister} >
              <span>{LanguageData.getText('InitialSignup', 'isu_018')}</span>
            </button>
          </div>
          <div className="col_full mt-4 nobottommargin clearfix center">
            <p>{LanguageData.getText('InitialSignup', 'isu_014')} <a className="text-yellow" href="#">{LanguageData.getText('InitialSignup', 'isu_015')}</a> {LanguageData.getText('InitialSignup', 'isu_016')} <a className="text-yellow" href="#">{LanguageData.getText('InitialSignup', 'isu_017')}</a>.</p>
          </div>
        </form>
      </div>
    );
  }
}

export default Register;