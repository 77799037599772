import React from 'react';

import TitleButton from '../button/titleButton';
import SmartPickItemList from '../listItem/SmartPickItemList';
import LanguageData from '../../helper/languageData';
import Instyle from '../../theme/instyle';

class SmartPickListSection extends React.Component {
  render() {
    const {
      selectAll,
      itemList,
      onPressSelectAll
    } = this.props;
    return (
      <div className="col-md-4 mb-4">
        <div className="notification-side">
          <header className="clearfix d-flex align-items-center pbc-border-bottom pb-3 pt-3">
            <div className="header-left">
              <input id="checkbox-7" className="checkbox-style" name="checkbox-7" type="checkbox" checked={selectAll} onChange={onPressSelectAll} />
              <label htmlFor="checkbox-7" className="checkbox-style-2-label">{LanguageData.getText('AccountNotifications', 'an_003')}</label>
            </div>
            <div className="header-right">
              <ul className="top-nav-list d-flex align-items-center justify-content-end">
                <TitleButton icon={'images/pbc/del-icon.png'} text={'Delete'} />
                <TitleButton icon={'images/pbc/add-new.png'} text={'Add New'} />
              </ul>
            </div>
          </header>
          <section className="notification-side-body">
            <ul className="list-unstyled note-list">
              <SmartPickItemList />
              <SmartPickItemList />
              <SmartPickItemList />
              <SmartPickItemList />
            </ul>
          </section>
        </div>
      </div>
    );
  }
}

SmartPickListSection.defaultProps = {
  selectAll: false,
  itemList: [],
  onPressSelectAll: ()=>{}
};

export default SmartPickListSection;