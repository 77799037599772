import React from 'react';

import DateTime from '../../helper/datetime';

class WalletTransactionRow extends React.Component {
  render() {
    const {
      index,
      data
    } = this.props;
    const className = index % 2 === 0? "result-detail-item clearfix result-detail-tr deep-body" : "result-detail-item clearfix result-detail-tr";
    const inValue = data.value > 0? data.value + 'ETH' : '-';
    const inColour = data.value > 0? {color: '#1BD741'} : null;
    const outValue = data.value < 0? data.value + 'ETH' : '-';
    const outColour = data.value < 0? {color: '#E20000'} : null;
    return (
      <div className={className}>
          <div className="container">
              <div className="row clearfix align-items-center">
                  <div className="col-md-2">
                    <span className="tr-title">Date :</span>
                    <span>{DateTime.getDateFromMS(parseInt(data.timeStamp) * 1000)}</span>
                  </div>
                  <div className="col-md-6">
                    <span className="tr-title">Ticket :</span>
                    <span>{data.hash}</span>
                  </div>
                  <div className="col-md-2">
                    <span className="tr-title">In :</span>
                    <span style={inColour}>{inValue}</span>
                  </div>
                  <div className="col-md-2">
                    <span className="tr-title">Out :</span>
                    <span style={outColour}>{outValue}</span>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

WalletTransactionRow.defaultProps = {
  index: 0,
  data: {},
};

export default WalletTransactionRow;