import React from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ResultHistoryRow from '../../component/card/resultHistoryRow';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import DateTime from '../../helper/datetime';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';

class ResultHistory extends React.Component {
  constructor(props) {
    super(props);    
    const {
      gameData
    } = this.props.location.state;
    const {
      objectId,
      GameName,
      GameType
    } = gameData;
    this.gameId = objectId;
    
    this.state = {
      gameName: GameName,
      gameType: GameType,
      resultList: [],
      refreshed: false,
    };
  }
  
  componentDidMount() {
    this.searchResult();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  goToHelp=()=>{
    const {
      gameData
    } = this.props.location.state;
    SoundManager.playNumber();
    this.navigateTo('help', { gameData: gameData });
  }
  
  searchResult = async() => {
    const response = await PlayAPI.getGameResult(this.gameId);
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
        const {
          gameData
        } = this.props.location.state;
        const {
          objectId,
          DrawAt,
          DrawNumber,
          JackpotValue,
          WinnerNumbers,
          Price,
          GameName,
          BallsCount
        } = gameData;
        const result = [];
        for (let i=0; i<BallsCount; i++) {
          result.push(0);
        }
        const current = {
          GameId: objectId,
          DrawAt,
          GameName,
          DrawNumber,
          JackpotValue,
          WinnerNumbers: result,
          Price,
          BallsCount
        };
        this.setState({
          resultList: [current, ...body.Data]
        });
      }
    }
  }
  
  openResultDetail=(index)=>{
    const { resultList } = this.state;
    SoundManager.playNumber();
    this.navigateTo('resultdetails', {data: resultList[index]});
  }
  
  openTop10=()=>{
    const {
      gameData
    } = this.props.location.state;
    SoundManager.playNumber();
    this.navigateTo('resulttop10', {gameData: gameData});
  }
  
  renderResultRow=(item, index)=>{
    return (
      <ResultHistoryRow key={index}
                        index={index}
                        resultMonth={DateTime.getMonthYearFromMS(item.DrawAt)}
                        resultDate={DateTime.getDateFromMS(item.DrawAt)}
                        drawAt={item.DrawAt}
                        drawNumber={item.DrawNumber}
                        amount={item.JackpotValue}
                        result={item.WinnerNumbers}
                        ballCount={item.BallsCount}
                        openResultDetail={this.openResultDetail} />
    );
  }
  
  render() {
    const {
      gameName,
      gameType,
      resultList
    } = this.state;
    const results = resultList.map(this.renderResultRow);
    return (
      <React.Fragment>        
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          <section id="page-header">
			<div className="container clearfix header-container">
              <div className="row clearfix">
                <div className="col-md-9">
                  <span className="award-title">{gameName} : {LanguageData.getText('webapp', 'webapp_020')}</span>
                </div>
                <div className="col-md-3">
                  <ul className="top-nav-list">
                    <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
                    {/*<li><a className="justify-content-center" style={Instyle.WhiteText} 
                           onClick={this.goToHelp}><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>*/}	
                  </ul>
                </div>
              </div>
			</div>
          </section>
          
          <section id="content" className="bg-transparent">
			<div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <section className="main-content clearfix">
                        <header className="new-ticket-header">
                            <div className="row">
                              <div className="col-md-6 col-10">
                                <ul className="header-tab">
                                  <li><a className="current" style={Instyle.CursorPointer}>{LanguageData.getText('GameResult', 'gr_001')}</a></li>
                                  <li><a onClick={this.openTop10} style={Instyle.CursorPointer}>{LanguageData.getText('GameResult', 'gr_002')}</a></li>
                                </ul>	
                              </div>
                              <div className="col-md-6 col-2">
                                <ul className="favourite-search clearfix float-right">
                                  <li><a href="#" className="clearfix nobg">
                                    <i><img className="icon-32" src="images/pbc/icon-filter.png" /></i>
                                    <span className="d-none d-sm-block">{LanguageData.getText('webapp', 'webapp_021')}</span>
                                  </a></li>
                                </ul>	
                              </div>
                            </div>
                        </header>
                        <div className="favourite-list-body">
                          {results}
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jqueryResult', src:"./js/jquery.js"},
          {id: 'pluginsResult', src:"./js/plugins.js"},
          {id: 'insightCollapseResult', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbarResult', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignmentResult', src:"./js/html-script-insightAlignment.js"},
          {id: 'functionsHistory', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}
          
const mapStateToProps = state => ({
  wallet: state.common.wallet,
  games: state.common.games
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultHistory);