import React from 'react';

import Instyle from '../../theme/instyle';

class SmartPickItemList extends React.Component {
  render() {
    const {
      index,
      name,
      isSelected,
      isOpen
    } = this.props;
    const checkboxId = "sp-" + index.toString();
    const switchId = "switch-toggle-" + index.toString();
    const openStyle = isOpen? " new-sp" : "";
    return (
      <li className=" d-flex align-items-center">
        <input id={checkboxId} className="checkbox-style" name={checkboxId} type="checkbox" />
        <label htmlFor={checkboxId} className="checkbox-style-2-label">
        </label>
        <a href="#">
          <div className={"sp-item-wrap d-flex align-items-start reading" + openStyle}>
            <div className="sp-item-body">
              <h5>{name}</h5>
            </div>
            <div className="switch">
              <input id={switchId} className="switch-toggle sp-switch switch-rounded-mini switch-toggle-round" type="checkbox" />
              <label htmlFor={switchId} className="mb-1"></label>
            </div>
          </div>
        </a>
      </li>
    );
  }
}

SmartPickItemList.defaultProps = {
  index: 0,
  name: '',
  isSelected: false,
  isOpen: false
};
export default SmartPickItemList;