import {
  applyMiddleware, createStore, combineReducers, compose
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';

let enhancers = null;

enhancers = compose(
  applyMiddleware(thunkMiddleware)
);

const store = createStore(
  combineReducers({
    ...reducers
  }),
  enhancers
);

export default store;

/*const persistConfig = {
  key: 'root',
  storage: storage,
}

const combined = combineReducers({ ...reducers});
const persistedReducer = persistReducer(persistConfig, combined);

export const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware));
export const persistor = persistStore(store);*/
