import React from 'react';

import {DecimalPointsSettings} from '../../config/constant';

class ResultDetailsRow extends React.Component {
  render() {
    const {
      data,
      winningNumber
    } = this.props;
    const ticket = data.ticket.sort(function(a, b){ return a-b; });
    let numbers = [];
    for (let i=0; i<ticket.length; i++) {
      numbers.push(winningNumber.includes(ticket[i])? <li key={i} className="actived">{ticket[i]}</li> : <li key={i}>{ticket[i]}</li>);
    }
    
    return (
      <div className="result-detail-item clearfix result-detail-tr deep-body">
        <div className="container">
          <div className="row clearfix align-items-center">
              <div className="col-md-4">
                <span className="tr-title">Winners :</span>
                <span>{data.address}</span>
              </div>
              <div className="col-md-6">
                <span className="tr-title">Ticket :</span>
                <ul className="list-unstyled result-ball green-ball">
                  {numbers}
                </ul>
              </div>
              <div className="col-md-2">
                <span className="tr-title">Payout :</span>
                <span>{parseFloat(data.payout).toFixed(DecimalPointsSettings)}ETH</span>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

ResultDetailsRow.defaultProps = {
  data: {},
  winningNumber: []
};

export default ResultDetailsRow;