import React from 'react';
import moment from 'moment';

import ScriptLoader from '../../component/scriptLoader';
import Utils from '../../helper/utils';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import { MINIMUM_YEAR } from '../../config/constant';

class SelectMonthYearDialog extends React.Component {
  constructor(props) {
    super(props);
    this.monthArray = [LanguageData.getText('DialogMonthPickerDialog', 'dmpd_001')];
    this.monthFullArray = [LanguageData.getText('DialogMonthPickerDialog', 'dmpd_001')];
    this.yearArray = [LanguageData.getText('DialogMonthPickerDialog', 'dmpd_001')];
    const month = new Date().getMonth();
    for (let i=0; i<=month; i++) {
      this.monthArray.push((i + 1).toString());
    }
    for (let i=0; i<=12; i++) {
      this.monthFullArray.push((i + 1).toString());
    }
    const year = new Date().getFullYear();
    for (let i=year-19; i<=year; i++) {
      if (i >= MINIMUM_YEAR) {
        this.yearArray.push(i.toString());
      }
    }
  }
  
  closeDialog=()=>{ 
    const {
      closeDialog
    } = this.props;
    SoundManager.playNumber();
    closeDialog();
  }
  
  onSelectDate=()=>{
    const {
      onSelect
    } = this.props;
    SoundManager.playPlay();
    const yearPicker = document.getElementById('year-picker');
    const yearIndex = yearPicker.value? parseInt(yearPicker.value) : this.yearArray.length-1;
    const monthPicker = document.getElementById('month-picker');
    const monthList = yearIndex === this.yearArray.length-1? this.monthArray : this.monthFullArray;
    const monthIndex = monthPicker.value? parseInt(monthPicker.value) : monthList.length-1;
    
    const year = this.yearArray[yearIndex];
    const month = monthList[monthIndex];
    const value = { year, month };
    const dateArray = [];
    if (year !== this.yearArray[0]) {
      dateArray.push(year);
      if (month !== monthList[0]) {
        dateArray.push(month - 1);
        value.start_at = moment(dateArray).startOf('month').format('YYYY-MM-DD');
        value.end_at = moment(dateArray).endOf('month').format('YYYY-MM-DD');
      } else {
        value.start_at = moment(dateArray).startOf('year').format('YYYY-MM-DD');
        value.end_at = moment(dateArray).endOf('year').format('YYYY-MM-DD');
      }
    }
    onSelect(value);
  }
  
  render() {
    const {
      isVisible
    } = this.props;
    
    return (
      <div id="myDateModal" 
           className="dialog-modal"
           style={{display: isVisible? 'block' : 'none', paddingTop: 0}}>
                
            <div className="container-fluid clearfix"
                 style={{position: 'absolute', top: '25%'}}>
              <div className="row justify-content-center shadow-sm">
                <div className="col-lg-3 col-md-6">
                  <div className="card nobg divcenter noradius noborder">
                    <div className="pbc-card-header center position-relative">
                      <div className="btn-close">
                        <a style={Instyle.CursorPointer}
                           onClick={this.closeDialog}><img src="images/pbc/button-close.png" /></a>
                      </div>
                      <h3>{LanguageData.getText('DialogMonthPickerDialog', 'dmpd_005')}</h3>
                    </div>
                    <div className="card-body pbc-card-body p-3">
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div id="month-picker" className="picker-container mb-4"></div>
                        <div id="year-picker" className="picker-container mb-4"></div>
                      </div>
                      <button className="button nomargin btn-block pbc-btn" id="select-ticket" name="select-ticket" value=""
                              onClick={this.onSelectDate}>
                        <span>{LanguageData.getText('DialogMonthPickerDialog', 'dmpd_004')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                
      </div>
    );
  }
}

SelectMonthYearDialog.defaultProps = {
  isVisible: false,
  closeDialog: () => {},
  onSelect: () => {}
}

export default SelectMonthYearDialog;