import React from 'react';
import Auth from '@aws-amplify/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import SettingsButton from '../../component/button/settingsButton';
import DialogConfirmation from '../../component/dialog/dialogConfirmation';
import ScriptLoader from '../../component/scriptLoader';
import Instyle from '../../theme/instyle';
import UserData from '../../helper/userData';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import { VERSION_NUMBER, API_URL_STAGE, API_URL_PROD, API_URL } from '../../config/constant';
import { resetRedux } from '../../actions/common';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogout: false
    };
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  goToHelp=()=>{
    SoundManager.playNumber();
    this.navigateTo('help');
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  openSmartPick=()=>{
    this.navigateTo('smartpick');
  }
  
  openChangePIN=()=>{
    this.navigateTo('changepincode');
  }
  
  openNotificationSettings=()=>{
    this.navigateTo('notificationSettings');
  }
  
  openSoundSettings=()=>{
    this.navigateTo('soundSettings');
  }
  
  openTermsAndConditions=()=>{
    this.navigateTo('termsandconditions');
  }
  
  openSendFeedback=()=>{
    this.navigateTo('sendfeedback');
  }
  
  openFAQ=()=>{
    this.navigateTo('faq');
  }
  
  openLanguageSettings=()=>{
    this.navigateTo('languagesettings');
  }
  
  showLogout=()=>{
    this.setState({ showLogout: true });
  }
  
  hideLogout=()=>{
    this.setState({ showLogout: false });
  }
  
  logoutUser = async() => {
    await Auth.signOut();
    await this.props.resetRedux();
    //await this.props.persistor.purge();
    UserData.resetUser();
    this.navigateTo('');
  }
  
  renderTitle() {
    return (
      <section id="page-header" className="pbc-border-bottom mb-3">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('AccountSettings', 'as_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center"style={Instyle.WhiteText}
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
                {/*<li><a className="justify-content-center" style={Instyle.WhiteText}
                       onClick={this.goToHelp}><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>*/}	
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderLeftColumn() {
    const isStage = API_URL === API_URL_STAGE;
    return (
      <div className="col-md-6 col-lg-6">
        <div className="mb-5 pt-4 setting-left">
          <ul className="list-unstyled setting-list">
            {/*<SettingsButton text={'Smart Pick'} onClick={this.openSmartPick} />*/}
            <SettingsButton text={LanguageData.getText('AccountSettings', 'as_003')} onClick={this.openChangePIN} />
            <SettingsButton text={LanguageData.getText('AccountNotificationSettings', 'ans_001')} onClick={this.openNotificationSettings} />
            <SettingsButton text={LanguageData.getText('AccountSettings', 'as_005')} onClick={this.openLanguageSettings} />
            <SettingsButton text={LanguageData.getText('AccountMusicSound', 'ams_001')} onClick={this.openSoundSettings} />
          </ul>
        </div>
      </div>
    );
  }
  
  renderTextOnlyRow(prefix, value) {
    return (
      <li>
        <a className="justify-content-between" style={{color: '#FFFFFF'}}>
          <span>{prefix}</span>
          <span>{value}</span>
        </a>
      </li>
    );
  }
  
  renderRightColumn() {
    const isStage = API_URL === API_URL_STAGE;
    return (
      <div className="col-md-6 col-lg-6">
        <div className="mb-5 setting-right">
          <span className="text-white mb-3" style={{lineHeight: '24px', paddingLeft: '15px'}}>{LanguageData.getText('AccountSettings', 'as_004')}</span>
          <ul className="list-unstyled setting-list">
            <SettingsButton text={LanguageData.getText('AccountSettings', 'as_006')} onClick={this.openTermsAndConditions} />
            <SettingsButton text={LanguageData.getText('AccountSettings', 'as_007')} onClick={this.openSendFeedback} />
            {this.renderTextOnlyRow(LanguageData.getText('AccountSettings', 'as_009'), VERSION_NUMBER)}
            {isStage? this.renderTextOnlyRow('Server', 'STAGE') : null}
            <SettingsButton text={LanguageData.getText('AccountSettings', 'as_010')} isLogout={true} onClick={this.showLogout} />
          </ul>
        </div>
      </div>
    );
  }
  
  render() {
    const {
      showTnC,
      showLogout
    } = this.state;
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <DialogConfirmation title={'Logout'}
                            text={'Logout from the website?'}
                            buttonText={'Logout'}
                            onClose={this.hideLogout}
                            buttonFunction={this.logoutUser}
                            isVisible={showLogout} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functions', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  resetRedux: bindActionCreators(resetRedux, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps) (Settings);