export const FIELD = {
  PINCODE_AGREED: 'pincode_agreed',
  NOTIFICATION_CHANEL_ID: 'aether_play',
  LANGUAGE_SETTING: 'language_setting',
  SOUND_ENABLE: 'sound_enable',
  MUSIC_ENABLE: 'music_enable'
};

export const USER_NUMBER_TYPE = {
  FAVORITE: 'F',
  SMART_PICK: 'S',
  NEW_NORMAL: 'N'
};