import React from 'react';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import PINInput from '../../component/card/pinInput';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import LanguageData from '../../helper/languageData';

class LoginWithPIN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      userId: '',
    };
  }
  
  componentDidMount() {
    const {
      userId,
      email,
      name
    } = this.props.location.state;
    this.setState({
      name: name,
      email: email,
      userId: userId
    });
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    history.goBack();
  }
  
  loginWithPIN = async(pincode) => {
    const {
      userId
    } = this.state;
    const response = await PlayAPI.checkPINCode(userId, pincode);
    if (response.status === 200) {
      const body = await response.json();
      if (body.Data === true) {
        this.props.history.push('/main')
      }else {
        ToastsStore.error('Incorrect PIN');
      }
    }
  }
  
  render() {
    const {
      name,
      email,
      userId
    } = this.state;
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
            <div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix">
                  <div className="row justify-content-center shadow-sm">
                    <PINInput title={LanguageData.getText('InitialLogin', 'il_012')}
                              text={LanguageData.getText('GamePaymentPincodeVerify', 'gppcv_002')}
                              buttonText={LanguageData.getText('InitialIntro', 'ii_009')}
                              onSubmit={this.loginWithPIN}
                              onClose={this.goBack}/>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

export default LoginWithPIN;