import React from 'react';

import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';

class NotificationItem extends React.Component {
  onChange=()=>{
    const {
      index,
      onChangeSelect
    } = this.props;
    SoundManager.playNumber();
    onChangeSelect(index);
  }
  
  openMessage=()=>{
    const {
      index,
      openMessage
    } = this.props;
    SoundManager.playNumber();
    openMessage(index);
  }
  
  render() {
    const {
      index,
      isSelected,
      unread,
      checked,
      title,
      text
    } = this.props;
    const id = "note-" + (index + 1).toString();
    let style = unread? "new-unread" : "";
    style = isSelected? "reading" : style;
    
    return (
      <li className="d-flex align-items-center">
        <input id={id} className="checkbox-style" name="note-1" type="checkbox" checked={checked} onChange={this.onChange} />
        <label htmlFor={id} className="checkbox-style-2-label">
        </label>
        <a style={Instyle.CursorPointer} onClick={this.openMessage}>
          <div className={"note-item-wrap d-flex align-items-start " + style}>
            <div className="note-icon-header">
              <div className="message-icon">
                <img src="images/pbc/message-icon.png" />
              </div>
            </div>
            <div className="note-item-body">
              <h5>{title}</h5>
              <p>{text}</p>
            </div>
          </div>
        </a>
      </li>
    );
  }
}

NotificationItem.defaultProps = {
  index: 0,
  isSelected: false,
  unread: false,
  checked: false,
  title: '',
  text: '',
  openMessage: ()=>{},
  onChangeSelect: ()=>{}
};

export default NotificationItem;