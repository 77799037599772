import React from 'react';

import Instyle from '../../theme/instyle';
import LanguageData from '../../helper/languageData';
import { LANGUAGE_LIST } from '../../config/constant';

class SelectLanguageDialog extends React.Component {
  onSelectLanguage=()=>{
    const {
      onSelect
    } = this.props;
    const language = document.getElementById('language-picker');
    const languageIndex = language.value? parseInt(language.value) : 0;
    onSelect(LANGUAGE_LIST[languageIndex].code);
  }
  
  render() {
    const {
      isVisible,
      closeDialog
    } = this.props;
    return (
      <div id="mylanguageModal" 
           className="dialog-modal"
           style={{display: isVisible? 'block' : 'none', paddingTop: 0}}>
                
            <div className="container-fluid clearfix"
                 style={{position: 'absolute', top: '25%'}}>
              <div className="row justify-content-center shadow-sm">
                <div className="col-lg-3 col-md-6">
                  <div className="card nobg divcenter noradius noborder">
                    <div className="pbc-card-header center position-relative">
                      <div className="btn-close">
                        <a style={Instyle.CursorPointer}
                           onClick={closeDialog}><img src="images/pbc/button-close.png" /></a>
                      </div>
                      <h3>{LanguageData.getText('DialogLanguageDialog', 'dld_003')}</h3>
                    </div>
                    <div className="card-body pbc-card-body p-3">
                      <div id="language-picker" className="picker-container mb-4"></div>
                      <button className="button nomargin btn-block pbc-btn" id="select-ticket" name="select-ticket" value=""
                              onClick={this.onSelectLanguage}>
                        <span>{LanguageData.getText('DialogLanguageDialog', 'dld_002')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                
      </div>
    );
  }
}

SelectLanguageDialog.defaultProps = {
  isVisible: false,
  closeDialog: () => {},
  onSelect: () => {}
}

export default SelectLanguageDialog;