import React from 'react';

import ScriptLoader from '../../component/scriptLoader';
import SoundManager from '../../helper/soundManager';

class SuccessAction extends React.Component {
  buttonFunction=()=>{
    const {
      buttonFunction
    } = this.props;
    SoundManager.playPlay();
    buttonFunction();
  }
  
  renderPopup() {
    const {
      title,
      text,
      buttonText,
      buttonFunction
    } = this.props;
    return (
      <div className="col-lg-3">
        <div className="card nobg divcenter noradius noborder">
          <div className="pbc-card-header center position-relative">
            <h3>{title}</h3>
          </div>
          <div className="card-body pbc-card-body">
            <div className="center mb-5">
              <span className="text-white">{text}</span>
            </div>
            <button className="button nomargin btn-block pbc-btn" id="form-submit" name="form-submit" value="login"
                    onClick={this.buttonFunction}>
              <span>{buttonText}</span>
            </button>
          </div>									
        </div>
      </div>
    );
  }
  
  render() {
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
			<div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix">
                  <div className="row justify-content-center shadow-sm">
                    {this.renderPopup()}
                  </div>
                </div>
              </div>
              <div className="pbc-wrap-footer-v2"></div>
			</div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

SuccessAction.defaultProps = {
  title: '',
  text: '',
  buttonText: '',
  buttonFunction: null
};

export default SuccessAction;