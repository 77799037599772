import React from 'react';

import Sidebar from '../../component/navigation/sidebar';
import NavigationBar from '../../component/navigation/navigationBar';
import NewNavBar from '../../component/navigation/NewNavBar';
import FAQItem from '../../component/listItem/faqItem';
import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import { FAQ_JSON_FILE } from '../../config/constant';

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftList: [],
      rightList: []
    };
  }
  
  async componentDidMount() {
    const response = await fetch(FAQ_JSON_FILE);
    if (response.status === 200) {
      const body = await response.json();
      let left = [];
      let right = [];
      const list = body.section.list;
      for (let i=0; i<list.length; i += 2) {
        left.push(list[i]);
        right.push(list[i + 1]);
      }
      
      this.setState({
        leftList: left,
        rightList: right,
      });
    }
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  renderTitle() {
    return (
      <section id="page-header" className="pbc-border-bottom mb-3">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('AccountFAQ', 'afq_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center"style={Instyle.WhiteText} 
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderSectionLeft(item, index) {
    const extension = index.toString() + '-left';
    return (
      <FAQItem key={extension}
               item={item} 
               extension={extension} />
    );
  }
  
  renderSectionRight(item, index) {
    const extension = index.toString() + '-right';
    return (
      <FAQItem key={extension}
               item={item} 
               extension={extension} />
    );
  }
  
  render() {
    const {
      sectionList, leftList, rightList
    } = this.state;
    const left = leftList.map(this.renderSectionLeft);
    const right = rightList.map(this.renderSectionRight);
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix" id="accordion" role="tablist">
                    
                    <div className="col-md-6 col-lg-6">
                      <div className="prizes-wrap mb-5">
                        <div className="help-quest">
                          <div>{left}</div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-6">
                      <div className="prizes-wrap mb-5">
                        <div className="help-quest">
                          <div>{right}</div>
                        </div>
                      </div>
                    </div>                    
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

export default FAQ;