import React from 'react';

import ScriptLoader from '../scriptLoader';
import TicketKeyboard from '../input/ticketKeyboard';
import LanguageImage from '../../helper/languageImage';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import {USER_NUMBER_TYPE} from '../../config/enum';

class PlayTicket extends React.Component {
  setTicketRef=(item)=>{
    this.refTicket = item;
    this.props.sendTicketRef(item);
  }
  
  get ticketReference() {
    return this.refTicket;
  }
  
  onPressKey=(number)=>{
    const {
      index,
      onPressKey
    } = this.props;
    SoundManager.playNumber();
    onPressKey(index, number);
  }
  
  onPressFree=()=>{
    const {
      index,
      setFreeStatus
    } = this.props;
    SoundManager.playNumber();
    setFreeStatus(index);
  }
  
  onPressRandom=()=>{
    const {
      index,
      setRandomNumber
    } = this.props;
    SoundManager.playRandom();
    setRandomNumber(index);
  }
  
  onPressEdit=()=>{
    const {
      index,
      onPressEdit
    } = this.props;
    SoundManager.playNumber();
    onPressEdit(index, this.refTicket);
    
  }
  
  onPressDelete=()=>{
    const {
      index,
      deleteTicket
    } = this.props;
    SoundManager.playNumber();
    deleteTicket(index);
  }
  
  render() {
    const {
      index,
      isFree,
      selectedNumbers,
      ballCount,
      maxNumber,
      onPressKey,
      ticketType
    } = this.props;
    
    const ticketNumber = index + 1;
    const ticketId = 'collapse-' + ticketNumber.toString();
    let numbers = [];
    for (let i=0; i<ballCount; i++) {
      let number = i < selectedNumbers.length? selectedNumbers[i] : 0;
      numbers.push(number > 0? <li key={i}><span>{number}</span></li> : <li key={i}><span style={{color: 'transparent'}}>0</span></li>);
    }
    
    return (
      <div className={isFree? "card noborder ticket-free" : "card noborder"}>
        <div className="card-header" role="tab">
          <a data-toggle="collapse" aria-expanded="true" className="btn-header collapsed" 
             href={"#" + ticketId} aria-controls={ticketId}
             ref={this.setTicketRef}>
            <ul className="list-unstyled result-number-list">
              <li>{ticketNumber}</li>	
              {numbers}
            </ul>
          </a>
          
          <div className="option-wrap d-flex">
            <ul className="list-unstyled card-option text-center align-items-center d-none d-sm-block">
              <li className="list-inline-item align-middle"><a className="option-free" style={Instyle.CursorPointer} onClick={this.onPressFree}><i></i></a></li>
              {ticketType === USER_NUMBER_TYPE.NEW_NORMAL? 
                <li className="list-inline-item align-middle"><a className="option-dice" style={Instyle.CursorPointer} onClick={this.onPressRandom}><i></i></a></li>
                : null
              }
              {ticketType === USER_NUMBER_TYPE.FAVORITE? 
                <li className="list-inline-item align-middle"><a style={{background: 'url(images/pbc/edit.png) no-repeat', backgroundPosition: 'center',  cursor:'pointer'}} onClick={this.onPressEdit}><i></i></a></li>
                : null
              }
              <li className="list-inline-item align-middle"><a className="option-delete" style={Instyle.CursorPointer} onClick={this.onPressDelete}><i></i></a></li>
            </ul>

            <a className="nav-link dropdown-toggle option-menu-toggle d-block d-sm-none" data-toggle="dropdown" href="#">
              <i className="icon-line-ellipsis"></i>
            </a>
            <ul className="dropdown-menu ticket-drop-option" role="menu">
              <a className="dropdown-item option-free" onClick={this.onPressFree}><i></i></a>
              {ticketType === USER_NUMBER_TYPE.NEW_NORMAL? 
                <a className="dropdown-item option-dice" onClick={this.onPressRandom}><i></i></a> : null
              }
              {ticketType === USER_NUMBER_TYPE.FAVORITE? 
                <a style={{background: 'url(images/pbc/edit.png) no-repeat', backgroundPosition: 'center',  cursor:'pointer'}}
                   className="dropdown-item option-dice" 
                   onClick={this.onPressEdit}><i></i></a> : null                
              }
              <a className="dropdown-item option-delete" onClick={this.onPressDelete}><i></i></a>
            </ul>
          </div>
        </div>
        {onPressKey === null? null :
          <TicketKeyboard ticketId={ticketId}
                          onPressKey={this.onPressKey}
                          selectedNumbers={selectedNumbers}
                          maxNumber={maxNumber} /> }
        <ScriptLoader scriptArray={[
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
        ]} />
      </div>
    );
  }
}

PlayTicket.defaultProps = {
  index: 0,
  selectedNumbers: [],
  ballCount: 1,
  maxNumber: 25,
  isFree: false,
  onPressKey: null,
  setFreeStatus: null,
  setRandomNumber: null,
  deleteTicket: null,
  onPressEdit: null,
  ticketType: USER_NUMBER_TYPE.NEW_NORMAL,
  sendTicketRef: ()=>{}
};

export default PlayTicket;