export default {
  SET_USER: 'SET_USER',
  SET_ALERT_REF: 'SET_ALERT_REF',
  SET_WALLET: 'SET_WALLET',
  SET_WALLET_ADDRESSES: 'SET_WALLET_ADDRESSES',
  SET_GAMES: 'SET_GAMES',
  SET_INBOXES: 'SET_INBOXES',
  SET_ENABLE_SOUND: 'SET_SOUND',
  SET_ENABLE_MUSIC: 'SET_MUSIC',
  RESET_REDUX: 'RESET_REDUX'
};
