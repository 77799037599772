import React from 'react'

class ScriptLoader extends React.Component{

	loadScriptWithCallback = (id, src, callback = () => {}, namespace='') => {
		const script = document.createElement('script');
		script.defer = true;
		script.id = id;
		script.src = src;
		document.body.appendChild(script);
		script.onload = function(){
			// console.log('Loaded ' + src);
			callback();
		}
	}

	loadScriptAsync = async (id, src, namespace='') => {
		return new Promise((resolve, reject)=>{
			const script = document.createElement('script');
			script.defer = true;
			script.id = id;
			script.src = src;
			script.setAttribute('data-namespace', namespace);
			document.body.appendChild(script);
			script.onload = function(){
				// console.log('Loaded ' + src);
				resolve();
			}
		});
	}

	loadScriptArrayByOrder = async () => {
		for (const script of this.props.scriptArray){
			await this.loadScriptAsync(script.id, script.src, script.namespace);
		}
	}

    async componentDidMount(){
		// console.log('Script loader did mount');

		// this.loadScriptWithCallback('jquery', '/static/js/jquery.js', ()=>{
		// 	this.loadScriptWithCallback('plugins', '/static/js/plugins.js', ()=>{
		// 		this.loadScriptWithCallback('functions', '/static/js/functions.js', ()=>{
		// 			this.loadScriptWithCallback('gmapsrc', 'https://maps.google.com/maps/api/js?key=YOUR_API_KEY', ()=>{
		// 				this.loadScriptWithCallback('gmap', '/static/js/jquery.gmap.js', ()=>{
		// 					console.log('Completed loading scripts.');
		// 				})
		// 			})
		// 		})
		// 	})
		// })

		// await this.loadScriptAsync('jquery', '/static/js/jquery.js');
		// await this.loadScriptAsync('plugins', '/static/js/plugins.js');
		// await this.loadScriptAsync('functions', '/static/js/functions.js');
		// await this.loadScriptAsync('gmapsrc', 'https://maps.google.com/maps/api/js?key=YOUR_API_KEY');
		// await this.loadScriptAsync('gmap', '/static/js/jquery.gmap.js');
		// console.log('Completed loadinggggg');
		await this.loadScriptArrayByOrder();
		
		// if (!document.getElementById('jquery')){
		// 	console.log('script load no. 1');
		// 	const script1 = document.createElement('script');
		// 	script1.defer = true;
		// 	script1.id = 'jquery';
		// 	script1.src = '/static/js/jquery.js';
		// 	document.body.appendChild(script1);

		// 	script1.onload = function(){
		// 		console.log('script load no. 2');
		// 		const script2 = document.createElement('script');
		// 		script2.defer = true;
		// 		script2.id = 'plugins';
		// 		script2.src = '/static/js/plugins.js';
		// 		document.body.appendChild(script2);

		// 		script2.onload = function(){
		// 			console.log('script load no. 3');
		// 			const script3 = document.createElement('script');
		// 			script3.defer = true;
		// 			script3.id = 'functions';
		// 			script3.src = '/static/js/functions.js';
		// 			document.body.appendChild(script3);

		// 			script3.onload = function(){
		// 				console.log('script load no. 4');
		// 				const script4 = document.createElement('script');
		// 				script4.defer = true;
		// 				script4.id = 'gmapsrc';
		// 				script4.src = 'https://maps.google.com/maps/api/js?key=YOUR_API_KEY';
		// 				document.body.appendChild(script4);

		// 				script4.onload = function(){
		// 					console.log('script load no. 5');
		// 					const script5 = document.createElement('script');
		// 					script5.defer = true;
		// 					script5.id = 'gmap';
		// 					script5.src = '/static/js/jquery.gmap.js';
		// 					document.body.appendChild(script5);
							
		// 				}
		// 			}
		// 		}
		// 	}
		// }
    }
    
    render(){
        return(
			<div></div>
        )
    }
}

export default ScriptLoader;