import React from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

class LoadSpinner extends React.Component {
  render() {
    const {
      type,
      color,
      height,
      width,
      timeout,
      isProcessing
    } = this.props;
    
    return (
      <div style={{marginRight: 10, marginTop: -2}}>
        <Loader type={type}
                color={color}
                width={width}
                height={height}
                timeout={timeout}
                visible={isProcessing} />
      </div>
    );
  }
}

LoadSpinner.defaultProps = {
  isProcessing: false,
  type: "Oval",
  color: "#371F00",
  height: 18,
  width: 18,
  timeout: 0
};

export default LoadSpinner;