import React from 'react';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import SettingsButton from '../../component/button/settingsButton';
import ScriptLoader from '../../component/scriptLoader';
import Instyle from '../../theme/instyle';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';

class Main extends React.Component {
  constructor(props) {
    const tutorial = {
      buyTicket: [
        {title: LanguageData.getText('TutorialMain', 'tm_005'), text: LanguageData.getText('TutorialScreenshot', 'ts_001'), image: 'images/tutorial/hiw-step-01.png'},
        {title: LanguageData.getText('TutorialMain', 'tm_005'), text: LanguageData.getText('TutorialScreenshot', 'ts_002'), image: 'images/tutorial/hiw-step-02.png'},
        {title: LanguageData.getText('TutorialMain', 'tm_005'), text: LanguageData.getText('TutorialScreenshot', 'ts_003'), image: 'images/tutorial/hiw-step-03.png'},
        {title: LanguageData.getText('TutorialMain', 'tm_005'), text: LanguageData.getText('TutorialScreenshot', 'ts_004'), image: 'images/tutorial/hiw-step-04.png'},
      ],
      changePIN: [
        {title: LanguageData.getText('TutorialMain', 'tm_003'), text: LanguageData.getText('TutorialScreenshot', 'ts_005'), image: 'images/tutorial/cpc-step-01.png'},
        {title: LanguageData.getText('TutorialMain', 'tm_003'), text: LanguageData.getText('TutorialScreenshot', 'ts_006'), image: 'images/tutorial/cpc-step-02.png'},
        {title: LanguageData.getText('TutorialMain', 'tm_003'), text: LanguageData.getText('TutorialScreenshot', 'ts_007'), image: 'images/tutorial/cpc-step-03.png'},
      ],
      result: [
        {title: LanguageData.getText('TutorialMain', 'tm_006'), text: LanguageData.getText('TutorialScreenshot', 'ts_008'), image: 'images/tutorial/gr-step-01.png'},
        {title: LanguageData.getText('TutorialMain', 'tm_006'), text: LanguageData.getText('TutorialScreenshot', 'ts_009'), image: 'images/tutorial/gr-step-02.png'},
        {title: LanguageData.getText('TutorialMain', 'tm_006'), text: LanguageData.getText('TutorialScreenshot', 'ts_010'), image: 'images/tutorial/gr-step-03.png'},
      ],
      tickets: [
        {title: LanguageData.getText('TutorialMain', 'tm_007'), text: LanguageData.getText('TutorialScreenshot', 'ts_011'), image: 'images/tutorial/ct-step-01.png'},
        {title: LanguageData.getText('TutorialMain', 'tm_007'), text: LanguageData.getText('TutorialScreenshot', 'ts_012'), image: 'images/tutorial/ct-step-02.png'},
        {title: LanguageData.getText('TutorialMain', 'tm_007'), text: LanguageData.getText('TutorialScreenshot', 'ts_013'), image: 'images/tutorial/ct-step-03.png'},
      ],
      deposit: [
        {title:  LanguageData.getText('TutorialMain', 'tm_004'), text: LanguageData.getText('TutorialScreenshot', 'ts_014'), image: 'images/tutorial/diw-step-01.png'},
        {title:  LanguageData.getText('TutorialMain', 'tm_004'), text: LanguageData.getText('TutorialScreenshot', 'ts_015'), image: 'images/tutorial/diw-step-02.png'},
        {title:  LanguageData.getText('TutorialMain', 'tm_004'), text: LanguageData.getText('TutorialScreenshot', 'ts_016'), image: 'images/tutorial/diw-step-03.png'},
        {title:  LanguageData.getText('TutorialMain', 'tm_004'), text: LanguageData.getText('TutorialScreenshot', 'ts_017'), image: 'images/tutorial/diw-step-04.png'},
        {title:  LanguageData.getText('TutorialMain', 'tm_004'), text: LanguageData.getText('TutorialScreenshot', 'ts_018'), image: 'images/tutorial/diw-step-05.png'},
        {title:  LanguageData.getText('TutorialMain', 'tm_004'), text: LanguageData.getText('TutorialScreenshot', 'ts_019'), image: 'images/tutorial/diw-step-06.png'},
      ]
    };
    
    super(props);
    this.leftColumn = [
      {title: LanguageData.getText('TutorialMain', 'tm_002'), onClick: this.navigateTo.bind(this, 'quicktutorial')},
      {title: LanguageData.getText('TutorialMain', 'tm_003'), onClick: this.navigateTo.bind(this, 'screenshottutorial', {slideList: tutorial.changePIN})},
      {title: LanguageData.getText('TutorialMain', 'tm_004'), onClick: this.navigateTo.bind(this, 'screenshottutorial', {slideList: tutorial.deposit})},
    ];
    this.rightColumn = [
      {title: LanguageData.getText('TutorialMain', 'tm_005'), onClick: this.navigateTo.bind(this, 'screenshottutorial', {slideList: tutorial.buyTicket})},
      {title: LanguageData.getText('TutorialMain', 'tm_006'), onClick: this.navigateTo.bind(this, 'screenshottutorial', {slideList: tutorial.result})},
      {title: LanguageData.getText('TutorialMain', 'tm_007'), onClick: this.navigateTo.bind(this, 'screenshottutorial', {slideList: tutorial.tickets})},
    ];
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  renderTitle() {
    return (
      <section id="page-header" className="pbc-border-bottom mb-3">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('TutorialMain', 'tm_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center"style={Instyle.WhiteText}
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderButton=(item, index)=>{
    return (
      <SettingsButton key={index.toString()} text={item.title} onClick={item.onClick} />
    );
  }
  
  renderLeftColumn() {
    const buttons = this.leftColumn.map(this.renderButton);
    return (
      <div className="col-md-6 col-lg-6">
        <div className="mb-5 setting-left">
          <ul className="list-unstyled setting-list">
            {buttons}
          </ul>
        </div>
      </div>
    );
  }
  
  renderRightColumn() {
    const buttons = this.rightColumn.map(this.renderButton);
    return (
      <div className="col-md-6 col-lg-6">
        <div className="mb-5 setting-right">
          <ul className="list-unstyled setting-list">
            {buttons}
          </ul>
        </div>
      </div>
    );
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functions', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

export default Main;