import React from 'react';

import LanguageData from '../../helper/languageData';

class HelpBall extends React.Component {
  render() {
    return (
      <li><img src="images/pbc/help-ball.png" /></li>
    );
  }
}

class HelpPrize extends React.Component {
  renderBall() {
    return (
      <HelpBall />
    );
  }
  
  getRankImage(rank) {
    switch(rank) {
      case 1:
        return <img className="align-middle" src="images/pbc/help-money-01.png" />
      case 2:
        return <img className="align-middle" src="images/pbc/help-money-02.png" />
      case 3:
        return <img className="align-middle" src="images/pbc/help-money-03.png" />
      case 4:
        return <img className="align-middle" src="images/pbc/help-money-04.png" />
      default:
         return <img className="align-middle" src="images/pbc/help-money-01.png" />
    }
  }
  
  render() {
    const {
      rank,
      match,
      prize
    } = this.props;
    const balls = [];
    for (let i=0; i<match; i++) {
      balls.push(<li key={i}><img src="images/pbc/help-ball.png" /></li>);
    }
    return (
      <div className="prizes-item d-flex align-items-center justify-content-between">
        <div className="prizes-img">
          <figure className="d-flex align-self-center">
            {this.getRankImage(rank)}
          </figure>
        </div>
        <div className="prizes-match align-start">
          <ul className="list-unstyled">
            {balls}
          </ul>
          <span>{LanguageData.getText('GameHelp', 'gh_003')} {match}</span>
        </div>
        <div className="prizes-reward">
          <span>{prize} ETH</span>
        </div>
    </div>
    );
  }
}

HelpPrize.defaultProps = {
  rank: 1,
  match: 6,
  prize: 1000
};

export default HelpPrize;