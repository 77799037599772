import React from 'react';
import LoadSpinner from './loadSpinner';

class BuyTicketButton extends React.Component {
  onPressBuy=()=>{
    const { disable, isProcessing, onPress } = this.props;
    if (!disable && !isProcessing) {
      onPress();
    }
  }
  
  render() {
    const {
      disable,
      isProcessing,
      buttonText,
    } = this.props;
    return (
      <button className="button nomargin btn-block pbc-btn" 
              id="signup-form-submit" name="signup-form-submit" value="signup" onClick={this.onPressBuy}>
        <div style={{display:'flex', justifyContent:'center'}}>
          <LoadSpinner isProcessing={isProcessing} />
          <span>{buttonText}</span>
        </div>
      </button>
    );
  }
}

BuyTicketButton.defaultProps = {
  disable: false,
  isProcessing: false,
  buttonText: 'Buy Now',
  onPress: ()=>{}
};

export default BuyTicketButton;