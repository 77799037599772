import React from 'react';
import LanguageData from '../../helper/languageData';
import {DecimalPointsSettings} from '../../config/constant';

class ResultHistoryRow extends React.Component {
  openDetails=()=>{
    const {
      index,
      openResultDetail
    } = this.props;
    openResultDetail(index);
  }
  
  renderBall(item, index) {
    if (item === 0) {      
      return (
        <li key={index}>?</li>
      );
    }
    return (
      <li key={index}>{item}</li>
    );
  }
  
  render() {
    const {
      resultMonth,
      resultDate,
      drawNumber,
      amount,
      result,
      drawAt,
      ballCount
    } = this.props;
    const resultNum = result.sort(function(a, b){ return a-b; });
    const numbers = resultNum.map(this.renderBall);
    const draw = LanguageData.getText('ListItemsHistoryListItem', 'lihli_001').replace("<draw number>", drawNumber);
    const isNewest = new Date().getTime() < drawAt  || result.includes(0);
    return (
      <div className="history-con">
        <span className="history-date">{resultMonth}</span>
        <div className="fav-item-wrap">
          <div className="row align-items-center">
            <div className="col-md-3">
              <div className="list-title">
                <span className="history-list-date">{resultDate}</span>
                <span className="history-list-draw">{draw}</span>
                <span className="history-list-amount">{parseFloat(amount).toFixed(DecimalPointsSettings)}ETH</span>
              </div>
            </div>
            <div className="col-md-6">
              {isNewest?
                <ul className="list-unstyled result-list-ball blue-ball">
                  {numbers}
                </ul> :
                <ul className="list-unstyled result-list-ball red-ball">
                  {numbers}
                </ul>
              }
            </div>
            <div className="col-md-3">
              <a className="button nomargin btn-block pbc-btn pbc-btn-blue"
                 onClick={this.openDetails}>
                <span>{LanguageData.getText('ListItemsHistoryListItem', 'lihli_002')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResultHistoryRow.defaultProps = {
  index: 0,
  resultMonth: '',
  resultDate: '',
  drawNumber: 0,
  amount: 0,
  result: [],
  ballCount: 6,
  drawAt: 0,
  openResultDetail: null
}

export default ResultHistoryRow;