import PostProperty from './postProperty';
import { API_URL } from '../config/constant';

const PlayAPI = {
  getGame: async() => {
    const response = await fetch(API_URL + '/game/getGame', PostProperty.getGetRequestForm());
    return response;
  },
  
  getGameResult: async(gameId) => {    
    const response = await fetch(API_URL + '/gameHistory/getGameHistory?Order=-createdAt&GameId=' + gameId, PostProperty.getGetRequestForm());
    return response;
  },
  
  getGameResultByDrawNumber: async(gameId, drawNumber) => {    
    const response = await fetch(API_URL + '/gameHistory/getGameHistory?Order=-createdAt&GameId=' + gameId +
                                                                                   '&DrawNumber=' + drawNumber, PostProperty.getGetRequestForm());
    return response;
  },
  
  getTopWinner: async(gameId, drawNumber) => {
    const params = {
      GameId: gameId,
      FromDraw: drawNumber,
      ToDraw: drawNumber
    };
    const response = await fetch(API_URL + '/winner/topWinners', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  getTop10Winner: async(gameId) => {
    const response = await fetch(API_URL + '/winner/top10Winners?GameId=' + gameId, PostProperty.getGetRequestForm());
    return response;
  },
  
  getWinningNumber: async(gameId, drawNumber, userId) => {
    const response = await fetch(API_URL + '/winner/winningNumber?GameId=' + gameId +
                                                            '&DrawNumber=' + drawNumber +
                                                                '&UserId=' + userId, PostProperty.getGetRequestForm());
    return response;
  },
  
  getPurchase: async(userId, startDate, endDate) => {
    const response = await fetch(API_URL + '/purchase/getPurchase?Order=-createdAt&UserId=' + userId + 
                                                                              '&StartDate=' + startDate +
                                                                                '&EndDate=' + endDate, PostProperty.getGetRequestForm());
    return response;
  },
  
  checkETHValue: async() => {
    const params = {
      ETH: 1
    };
    const response = await fetch(API_URL + '/wallet/checkETHValue', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  getWallet: async(userId) => {
    const params = {
      UserId: userId
    };
    const response = await fetch(API_URL + '/wallet/getWallet?UserId=' + userId, PostProperty.getGetRequestForm());
    return response;
  },
  
  getWalletBalance: async(userId, walletIndex) => {
    const params = {
      UserId: userId,
      Index: walletIndex
    };
    const response = await fetch(API_URL + '/wallet/getAccountBalance?UserId=' + userId +
                                                                     '&Index=' + walletIndex, PostProperty.getGetRequestForm());
    return response;
  },
  
  addWallet: async(userId, walletId, index) => {
    const params = {
      UserId  : userId, 
      WalletId: walletId,
      Demo: true,
      Index: index
    };
    const response = await fetch(API_URL + '/wallet/addWallet', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  getWalletTransaction: async(address) => {
    const response = await fetch(API_URL + '/etherScan/getTransaction?address=' + address, PostProperty.getGetRequestForm());
    return response;
  },
  
  switchDefaultWallet: async(userId, walletId, index) => {
    const params = {
      UserId: userId, 
      WalletId: walletId,
      Index: index,
      Demo: true
    };
    const response = await fetch(API_URL + '/wallet/addWallet', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  getInbox: async(userId) => {
    const response = await fetch(API_URL + '/inbox/getInbox?Order=-createdAt&UserId=' + userId, PostProperty.getGetRequestForm());
    return response;
  },
  
  updateReadInbox: async(notificationId) => {
    const params = {
      IsRead: true,
      InboxId: notificationId
    };
    const response = await fetch(API_URL + '/inbox/updateInbox', PostProperty.getPutRequestForm(params));
    return response;
  },
  
  deleteInbox: async(notificationId) => {
    const params = {
      InboxId: notificationId
    };
    const response = await fetch(API_URL + '/inbox/deleteInbox', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  setUserPINCode: async(userId, pincode) => {
    const params = {
      UserId: userId, 
      Pin: pincode
    };
    const response = await fetch(API_URL + '/pin/setPin', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  checkPINCode: async(userId, pincode) => {
    const response = await fetch(API_URL + '/pin/checkPin?UserId=' + userId +
                                                           '&Pin=' + pincode, PostProperty.getGetRequestForm());
    return response;
  },
  
  getFreeTicketById: async(userId) => {
    const response = await fetch(API_URL + '/ticket/getFreeTicketsByUserId?UserId=' + userId, PostProperty.getGetRequestForm());
    return response;
  },
  
  buyTicket: async(params) => {
    const response = await fetch(API_URL + '/purchase/buyTicket', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  addFavourite: async(userId, gameId, type, name, tickets) => {
    const params = {
      UserId: userId,
      GameId: gameId,
      Type: type,
      Name: name,
      Numbers: tickets,
      IsActive: true
    };
    const response = await fetch(API_URL + '/userNumber/addUserNumber', PostProperty.getDeleteRequestForm(params));
    return response;
  },
  
  getAllFavourite: async(userId, gameId) => {
    const response = await fetch(API_URL + '/userNumber/getUserNumber?UserId=' + userId + 
                                                                    '&GameId=' + gameId, PostProperty.getGetRequestForm());
    return response;
  },
  
  getFavourite: async(userId, gameId, type) => {
    const response = await fetch(API_URL + '/userNumber/getUserNumber?UserId=' + userId + 
                                                                    '&GameId=' + gameId + 
                                                                      '&Type=' + type, PostProperty.getGetRequestForm());
    return response;
  },
  
  deleteFavourite: async(favouriteId) => {
    const params = {
      UserNumberId: favouriteId
    };
    const response = await fetch(API_URL + '/userNumber/deleteUserNumber', PostProperty.getDeleteRequestForm(params));
    return response;
  },
  
  getReferralCode: async(userId) => {
    const response = await fetch(API_URL + '/referral/getReferral?UserId=' + userId, PostProperty.getGetRequestForm());
    return response;
  },
  
  addReferral: async(userId, referrorCode, referralCode) => {
    const params = {
      UserId: userId,
      ReferrorCode: referrorCode,
      ReferralCode: referralCode,
    };
    const response = await fetch(API_URL + '/referral/addReferral', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  addUserSmartPick: async(userId, type, gameId, numbers) => {
    const params = {
      UserId: userId,
      Type: type,
      GameId: gameId,
      Numbers: numbers,
      IsActive: true
    };
    const response = await fetch(API_URL + '/userNumber/addUserNumber', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  getUserSmartPick: async(userId, type) => {
    const response = await fetch(API_URL + '/userNumber/getUserNumber?UserId=' + userId +
                                                                      '&Type=' + type, PostProperty.getGetRequestForm());
    return response;
  },
  
  updateUserSmartPick: async(numberId, userId, type, gameId, numbers, isActive) => {
    const params = {
      UserNumberId: numberId,
      UserId: userId,
      Type: type,
      GameId: gameId,
      Numbers: numbers,
      IsActive: isActive
    };
    const response = await fetch(API_URL + '/userNumber/updateUserNumber', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  deleteUserSmartPick: async(numberId) => {
    const params = {
      UserNumberId: numberId
    };
    const response = await fetch(API_URL + '/userNumber/deleteUserNumber', PostProperty.getDeleteRequestForm(params));
    return response;
  },
  
  getGasEstimate: async() => {
    const response = await fetch(API_URL + '/wallet/gasEstimate', PostProperty.getGetRequestForm());
    return response;
  },
  
  sendETH: async(userId, walletIndex, receiverAddress, amount) => {
    const params = {
      SenderId: userId,
      Index: walletIndex,
      ReceiverAddress: receiverAddress,
      Amount: amount,
    };
    const response = await fetch(API_URL + '/wallet/sendETHToUser', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  exportMnemonic: async(userId, mnemonic) => {
    const params = {
      UserId: userId,
      Mnemonic: mnemonic
    };
    const response = await fetch(API_URL + '/wallet/exportMnemonic', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  sendFeedback: async(userId, feedback) => {
    const params = {
      Feedback: feedback,
      UserId: userId,
      Status: "1"
    };
    const response = await fetch(API_URL + '/feedback/addFeedback', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  setUserDevice: async(userId, fcmToken) => {
    const params = {
      UserId: userId,
      Token: fcmToken,
      Type: 'W'
    };
    const response = await fetch(API_URL + '/device/setUserDevice', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  getUserDevice: async(userId) => {
    const response = await fetch(API_URL + '/device/getDevice?UserId=' + userId, PostProperty.getGetRequestForm());
    return response;
  },
  
  switchNotification: async(userId, switchOff) => {
    const params = {
      UserId: userId,
      SwitchOff: switchOff,
      Type: "W"
    };
    const response =  await fetch(API_URL + '/device/switchNotification', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  getMostDraw: async(gameId, size) => {
    const response = await fetch(API_URL + '/count/getMostDraw?GameId=' + gameId +
                                                               '&Size=' + size, PostProperty.getGetRequestForm());
    return response;
  },
  
  getLeastDraw: async(gameId, size) => {
    const response = await fetch(API_URL + '/count/getLeastDraw?GameId=' + gameId +
                                                                '&Size=' + size, PostProperty.getGetRequestForm());
    return response;
  },
  
  getMostPurchase: async(gameId, size) => {
    const response = await fetch(API_URL + '/count/getMostPurchase?GameId=' + gameId +
                                                               '&Size=' + size, PostProperty.getGetRequestForm());
    return response;
  },
  
  getLeastPurchase: async(gameId, size) => {
    const response = await fetch(API_URL + '/count/getLeastPurchase?GameId=' + gameId +
                                                                    '&Size=' + size, PostProperty.getGetRequestForm());
    return response;
  },
  
  createInsightFavourite: async(userId, gameId, type, name, ticket) => {
    const params = {
      UserId: userId,
      GameId: gameId,
      Type: type,
      IsActive: true,
      Name: name,
      Numbers: [ticket]
    };
    const response = await fetch(API_URL + '/userNumber/addUserNumber', PostProperty.getPostRequestForm(params));
    return response;
  },
  
  updateFavourite: async(userId, gameId, type, favouriteId, ticket) => {
    const params = {
      UserId: userId,
      GameId: gameId,
      Type: type,
      IsActive: true,
      UserNumberId: favouriteId,
      Numbers: ticket
    };
    const response = await fetch(API_URL + '/userNumber/updateUserNumber', PostProperty.getPostRequestForm(params));
    return response;
  }
}

export default PlayAPI;