import UserData from '../helper/userData';
import LanguageData from '../helper/languageData';

const PostProperty = {
  getGetRequestForm: () =>{
    const form = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-language': LanguageData.getLanguageCode(),
        'x-token': UserData.getJWTToken()
      },
    }
    return form;
  },
  
  getPostRequestForm: (params) => {
    const form = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-language': LanguageData.getLanguageCode(),
        'x-token': UserData.getJWTToken()
      },
      body: JSON.stringify(params)
    }
    return form;
  },
  
  getDeleteRequestForm: (params) => {
    const form = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-language': LanguageData.getLanguageCode(),
        'x-token': UserData.getJWTToken()
      },
      body: JSON.stringify(params)
    }
    return form;
  },
  
  getPutRequestForm: (params) => {
    const form = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-language': LanguageData.getLanguageCode(),
        'x-token': UserData.getJWTToken()
      },
      body: JSON.stringify(params)
    }
    return form;
  }
}

export default PostProperty;