import React from 'react';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ResultTop10Row from '../../component/listItem/resultTop10Row';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import {DecimalPointsSettings} from '../../config/constant';

class ResultTop10 extends React.Component {
  constructor(props) {
    super(props);
    
    const {
      gameData
    } = this.props.location.state;
    const {
      objectId,
      GameName,
      GameType
    } = gameData;
    this.gameId = objectId;
    
    this.state = {
      gameName: GameName,
      gameType: GameType,
      resultList: []
    };
  }
  
  componentDidMount() {
    this.search();
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }

  goToHelp=()=>{
    const {
      gameData
    } = this.props.location.state;
    SoundManager.playNumber();
    this.navigateTo('help', { gameData: gameData });
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  search = async() => {
    const response = await PlayAPI.getTop10Winner(this.gameId);
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
        this.setState({
          resultList: body.Data
        });
      }
    }
  }
  
  openTop10=()=>{
    const {
      gameData
    } = this.props.location.state;
    SoundManager.playNumber();
    this.navigateTo('resulthistory', {gameData: gameData});
  }
  
  openDetails = async(index) => {
    const { resultList } = this.state;
    const result = resultList[index];
    const response = await PlayAPI.getGameResultByDrawNumber(result.GameId, result.DrawNumber);
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
        this.navigateTo('resultdetails', {data: body.Data[0]});
      }
    }
  }
  
  renderTitle() {
    const {
      gameName,
      gameType
    } = this.state;
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{gameName} : {LanguageData.getText('webapp', 'webapp_020')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goToHelp}><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>	
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderTabRow() {
    return (
      <header className="new-ticket-header">
        <div className="row">
          <div className="col-md-6 col-10">
            <ul className="header-tab">
              <li><a style={Instyle.CursorPointer} onClick={this.openTop10}>{LanguageData.getText('GameResult', 'gr_001')}</a></li>
              <li><a className="current" style={Instyle.CursorPointer}>{LanguageData.getText('GameResult', 'gr_002')}</a></li>
            </ul>	
          </div>
          <div className="col-md-6 col-2">
            <ul className="favourite-search clearfix float-right">
              <li><a href="#" className="clearfix nobg">
                <i><img className="icon-32" src="images/pbc/icon-filter.png" /></i>
                <span className="d-none d-sm-block">{LanguageData.getText('webapp', 'webapp_021')}</span>
              </a></li>
            </ul>	
          </div>
        </div>
      </header>
    );
  }
  
  renderWinner=(item, index)=>{
    return (
      <ResultTop10Row key={index} 
                      data={item}
                      index={index}
                      openDetails={this.openDetails} />
    );
  }
  
  render() {
    const {
      resultList
    } = this.state;
    const results = resultList.map(this.renderWinner);
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <section className="main-content clearfix">
                        {this.renderTabRow()}
                        
                        <div className="top-list-body">
                          <div className="table-header clearfix">
                            <div className="result-table-header"><span>{LanguageData.getText('ListItemsPayoutListItem', 'lipli_001')}</span></div>
                            <div className="result-table-header"><span>{LanguageData.getText('ListItemsPayoutListItem', 'lipli_002')}</span></div>
                            <div className="result-table-header"><span>{LanguageData.getText('ListItemsPayoutListItem', 'lipli_003')}</span></div>
                            <div className="result-table-header"><span>{LanguageData.getText('ListItemsPayoutListItem', 'lipli_004')}</span></div>
                            <div className="result-table-header"><span></span></div>
                          </div>
                          {results}
                        </div>
                      </section>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functions', src:"./js/functions.js"},
          {id: 'htmlscripttooltip', src:"./js/html-script-tooltip.js"}
        ]} />
      </React.Fragment>
    );
  }
}

export default ResultTop10;