import React from 'react';
import moment from 'moment';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import MyTicketDetailsRow from '../../component/listItem/myTicketDetailsRow';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import {DecimalPointsSettings} from '../../config/constant';

class MyTicketDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      ticketData
    } = this.props.location.state;
    this.state = {
      winDraw: ticketData.WinInfo.win,
      data: ticketData,
    };
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  goBack=()=>{
    /*const {
      history
    } = this.props;
    history.goBack();*/    
    const {
      resultList,
      failedList
    } = this.props.location.state;
    SoundManager.playNumber();
    this.navigateTo('myticket', {
      resultList: resultList,
      failedList: failedList
    });
  }
  
  openTransactions=()=>{
    const {
      EcUrl
    } = this.state.data;
    SoundManager.playPlay();
    if (EcUrl) {
      window.open(EcUrl, "_blank"); 
    }else {
      ToastsStore.warning(LanguageData.getText('AccountMyTicketDetails', 'amtd_001'));
    }
  }
  
  renderTitle() {
    const {
      data
    } = this.state;
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
          <div className="row clearfix">
              <div className="col-md-9">
                <span className="award-title">{LanguageData.getText('AccountMyTicket', 'amt_001')} : {data.Game.GameName}</span>
              </div>
              <div className="col-md-3">
                <ul className="top-nav-list d-flex align-items-center justify-content-end">
                  <li><a className="justify-content-center" style={Instyle.WhiteText}
                         onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
                </ul>
              </div>
          </div>
          <div className="row">
          </div>
        </div>
      </section>
    );
  }
  
  renderTop() {
    const {
      data
    } = this.state;
    const drawAt = data.DrawResult === null? data.Game.DrawAt : data.DrawAt;
    const draw = LanguageData.getText('ListItemsMyTicketListItem', 'limtli_003').replace("<draw number>", data.DrawNumber);
    const purchased = LanguageData.getText('ListItemsMyTicketListItem', 'limtli_004').replace("<date>", moment(data.CreateDate).format('MMMM Do YYYY, h:mm:ss a'));
    return (
      <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="main-content clearfix">
                    <header className="new-ticket-header">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-6 col-md-8">
                              <div className="row clearfix ticket-detail-header">
                                <div className="col-md-3">
                                  <span className="text-yellow">{data.Items.length} {LanguageData.getText('AccountMyTicketDetails', 'amtd_002')}</span>
                                </div>
                                <div className="col-md-3">
                                  <span className="text-yellow">{LanguageData.getText('AccountMyTicketDetails', 'amtd_004')}: {data.Price}ETH</span>
                                </div>
                                <div className="col-md-3">
                                  <span>{draw}</span>
                                </div>
                                <div className="col-md-3">
                                  <span>{moment(drawAt).format('D MMM YYYY')}</span>
                                </div>
                                <div className="col-12">
                                  <span className="text-purple mt-2 mb-2 d-block">{purchased}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-3 center">
                              <a className="button nomargin btn-block pbc-btn ticket-view-btn"
                                 onClick={this.openTransactions}>
                                <span>{LanguageData.getText('AccountMyTicketDetails', 'amtd_005')}</span>
                              </a>
                            </div>
                        </div>
                    </header>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  renderWinVersion() {
    const {
      data
    } = this.state;
    const payout = data.DrawResult.PayoutBreakdown;
    const winNumList = data.DrawResult.WinningNumbers.sort((a, b) => { return a - b});
    let winningNumber = [];
    for (let i=0; i<winNumList.length; i++) {
      winningNumber.push(<li key={i}>{winNumList[i]}</li>)
    }
    
    let tickets = [];
    for (let i=0; i<data.Items.length; i++) {
      tickets.push(<MyTicketDetailsRow key={i} win={true} index={i} ticket={data.Items[i]} result={winNumList} payout={payout[i]} />);
    }
    
    const win = parseFloat(data.WinInfo.amountETH).toFixed(DecimalPointsSettings);
    return (
      <React.Fragment>
        <div className="section ticketwon-detail-header notoppadding nomargin center clearfix">
          <h2><span className="congrat-word">{LanguageData.getText('AccountMyTicketDetails', 'amtd_006')}</span>{win}ETH</h2>
        </div>
        
        <div className="section pbc-section nobg notoppadding nomargin clearfix">
          <div className="result-content-header">
            <span>{LanguageData.getText('AccountMyTicketDetails', 'amtd_007')}</span>
            <ul className="list-unstyled result-list-ball red-ball">
              {winningNumber}
            </ul>
          </div>
          
          <div className="ticketwon-detail-item clearfix result-detail-tr">
            <div className="container">
              <div className="row clearfix justify-content-center">
                <div className="col-md-6">                  
                  {tickets}                  
                </div>
              </div>
            </div>
          </div>          
        </div>
      </React.Fragment>
    );
  }
  
  renderDefaultVersion() {
    const {
      data
    } = this.state;
    let tickets = [];
    for (let i=0; i<data.Items.length; i++) {
      tickets.push(<MyTicketDetailsRow key={i} win={false} index={i} ticket={data.Items[i]} />);
    }
    return (
      <React.Fragment>
        {tickets}
      </React.Fragment>
    );
  }
  
  render() {
    const {
      winDraw
    } = this.state;
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          
          {this.renderTitle()}
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                {this.renderTop()}
                {winDraw? this.renderWinVersion() : this.renderDefaultVersion()}                
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'bsSwitches', src:"js/components/bs-switches.js"},
          {id: 'functions', src:"./js/functions.js"},
          {id: 'bootstrapSwitch', src:"./js/html-script-ticketSwitch.js"},
        ]} />
      </React.Fragment>
    );
  }
}

export default MyTicketDetails;