import React from 'react';

import Game from '../card/game';

class Main extends React.Component {
  render() {
    const {
      gameList,
      openGame,
      openResult,
      openInsight
    } = this.props;
    if (gameList.length === 0) {
      return null;
    }
    var games = [];
    for (let i=0; i<gameList.length; i++) {
      games.push(
        <Game key={i} index={i}
              gameData={gameList[i]}
              openResult={openResult}
              openGame={openGame}
              openInsight={openInsight} />
      );
    }
    const middle = parseInt((games.length * 5) / 2) - 1;
    
    /*INSERTING MULTIPLE COPIES OF THE LIST DUE TO THE 
      ISSUE WHERE CLONED OBJECTS ARE UNRESPONSIVE AS A WORKAROUND*/
    return (
      <div id="oc-testi" className="owl-carousel gameitem-carousel carousel-widget clearfix" 
           data-margin="0" data-pagi="false" data-loop="true" data-center="true" 
           data-items-sm="1" data-items-md="3" data-items-xl="3"
           data-start={middle}>
          {games}
          {games}
          {games}
          {games}
          {games}
      </div>      
    );
  }
}

Main.defaultProps = {
  gameList: [],
  openGame: null,
  openResult: null,
  openInsight: null
};

export default Main;