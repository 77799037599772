import React from 'react';
import { connect } from 'react-redux';

import Utils from '../../helper/utils';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showId: false
    };
  }
  
  flipUserID=()=>{
    SoundManager.playNumber();
    this.setState({
      showId: !this.state.showId
    });
  }
  
  openGame=(gameData)=>{
    SoundManager.playNumber();
    this.props.navigateTo('playnew', {gameData: gameData, useFreeTicket: false});
    document.getElementById("side-panel-trigger-close").click();
  }
  
  openProfile=()=>{
    this.changeScreen('profile');
  }
  
  openMyWallet=()=>{
    this.changeScreen('mywallet');
  }
  
  openMyTicket=()=>{
    this.changeScreen('myticket');
  }
  
  openNotifications=()=>{
    this.changeScreen('notifications');
  }
  
  openReferral=()=>{
    this.changeScreen('referralrewards');
  }
  
  openSettings=()=>{
    this.changeScreen('settings');
  }
  
  changeScreen(screen) {
    SoundManager.playNumber();
    this.props.navigateTo(screen);
    document.getElementById("side-panel-trigger-close").click();
  }
  
  playSound() {    
    SoundManager.playNumber();
  }
  
  renderGame=(game)=>{
    const { games } = this.props;
    const data = Utils.getGameDataByCode(games, game.objectId);
    if (data.disabled) {
      return null;
    }else {
      return (
        <li key={game.objectId}>
          <a style={Instyle.CursorPointer} 
             onClick={()=>{ this.openGame(game); }}>{game.GameName}</a>
        </li>
      );
    }
  }
  
  render() {
    const {
      user,
      games,
      inboxes
    } = this.props;
    const name = user === null? '' : user.attributes.given_name;
    const familyName = user === null? '' : user.attributes.family_name;
    const id = user === null? '' : user.username;
    const notification = inboxes.unreadCount > 0? <span className="badge badge-light float-right pbc-badge" style={{marginTop: "3px"}}>{inboxes.unreadCount}</span> : null;
    
    let gameList = [];
    if (games) {
      for (let i=0; i<games.length; i++) {
        gameList.push(this.renderGame(games[i]));
      }
    }
    const profileStr = LanguageData.getText('settings', 'profile')
    const idText = LanguageData.getText('InitialLoginWithPINCode', 'ilwpc_002').replace("<id>", id);
    return (
      <div id="side-panel" className="dark">
		<div className="side-panel-wrap">
			<div id="side-panel-trigger-close" className="side-panel-trigger">
              <a href="#" onClick={this.playSound}><i className="icon-plus"></i></a>
			</div>
			<div className="widget clearfix border-0 mt-2 pt-0">
              <div className="avatar-wrap">
                <img src="images/icons/avatar.jpg" className="alignleft img-circle img-thumbnail notopmargin nobottommargin" 
                     alt="Avatar" style={{maxWidth: "84px"}} />
                <div className="heading-block noborder mb-0">
                  <h3 onClick={this.flipUserID} style={Instyle.CursorPointer}>{name} {familyName}</h3>
                  {this.state.showId? <span>{idText}</span> : null}
                </div>
              </div>
			</div>
            <div className="widget clearfix border-0 mt-2 pt-0">
              <nav className="nav-tree nobottommargin">
                <ul>
                  <li><a href="#" onClick={this.playSound}><i className="side-icon"><img src="images/pbc/nav-games.svg" /></i>{LanguageData.getText('webapp', 'webapp_018')}</a>
                    <ul>
                      {gameList}
                    </ul>
                  </li>
                    {/*<li><a href="insights"><i className="side-icon"><img src="images/pbc/nav-insights.svg" /></i>Insights</a></li>*/}
                    <li><a href="#" onClick={this.playSound}><i className="side-icon"><img src="images/pbc/nav-account.svg" /></i>{LanguageData.getText('webapp', 'webapp_019')}</a>
                      <ul>
                        <li><a style={Instyle.CursorPointer} onClick={this.openProfile}>{LanguageData.getText('settings', 'profile')}</a></li>
                        <li><a style={Instyle.CursorPointer} onClick={this.openMyWallet}>{LanguageData.getText('settings', 'wallet')}</a></li>
                        <li><a style={Instyle.CursorPointer} onClick={this.openMyTicket}>{LanguageData.getText('settings', 'tickets')}</a></li>
                        <li><a style={Instyle.CursorPointer} onClick={this.openNotifications}>{LanguageData.getText('settings', 'notification')} {notification}</a></li>
                        <li><a style={Instyle.CursorPointer} onClick={this.openReferral}>{LanguageData.getText('settings', 'referral')}</a></li>
                        <li><a style={Instyle.CursorPointer} onClick={this.openSettings}>{LanguageData.getText('settings', 'settings')}</a></li>
                      </ul>
                    </li>
                </ul>
              </nav>
			</div>
          </div>
        </div>
    );
  }
}

Sidebar.defaultProps = {
  history: {},
  navigateTo: null
};

const mapStateToProps = state => ({
  inboxes: state.common.inboxes,
  user: state.common.user,
  games: state.common.games
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);