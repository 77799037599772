import React from 'react';
import LoadSpinner from '../button/loadSpinner';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';

class SendEHTSuccess extends React.Component {
  close=()=>{
    const {
      onClose,
      isProcessing
    } = this.props;
    if (!isProcessing) {
      SoundManager.playNumber();
      onClose();
    }
  }
  
  onPressOkay=()=>{
    const {
      onClose,
      isProcessing
    } = this.props;
    if (!isProcessing) {
      SoundManager.playPlay();
      onClose();
    }
  }
  
  render() {
    const {
      isProcessing
    } = this.props;
    return (
      <div className="col-lg-3">
        <div className="card nobg divcenter noradius noborder">
          <div className="pbc-card-header center position-relative">
            <div className="btn-close">
              <a style={Instyle.CursorPointer} 
                 onClick={this.close}><img src="images/pbc/button-close.png" /></a>
            </div>
            <h3>{LanguageData.getText('AccountSendETHSuccess', 'ases_003')}</h3>
          </div>
          <div className="card-body pbc-card-body">
            <figure className="center verify-img mb-0">
              <img src="images/pbc/transaction-success.png" />
            </figure>
            <div className="ver-des center mb-4">
              <span>{LanguageData.getText('AccountSendETHSuccess', 'ases_001')}</span>
            </div>
            <button className="button nomargin btn-block pbc-btn" id="login-form-submit" name="login-form-submit" value="login"
                    onClick={this.onPressOkay}>
              <div style={{display:'flex', justifyContent:'center'}}>
                <LoadSpinner isProcessing={isProcessing} />
                <span>{LanguageData.getText('AccountSendETHSuccess', 'ases_002')}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

SendEHTSuccess.defaultProps = {
  isProcessing: false,
  onClose: ()=>{}
};

export default SendEHTSuccess;