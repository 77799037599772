import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SoundManager from '../../helper/soundManager';
import LanguageData from '../../helper/languageData';
import SaveData from '../../helper/saveData';
import Instyle from '../../theme/instyle';
import { setEnableSound, setEnableMusic } from '../../actions/common';

const styles = {
  container: {
    maxWidth: "400px", 
    marginTop: "200px",
    zIndex: 100
  }
};

class MusicAndSound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSound: props.enableSound,
      enableMusic: props.enableMusic
    };
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.enableSound !== prevProps.enableSound ||
        this.props.enableMusic !== prevProps.enableMusic) {      
      this.setState({
        enableSound: this.props.enableSound,
        enableMusic: this.props.enableMusic
      });
    }
  }
  
  handleClose=()=>{
    this.props.setEnableMusic(this.state.enableMusic);
    this.props.setEnableSound(this.state.enableSound);
    this.props.closeSoundDialog();
  }
  
  onChangeMusic=async()=>{
    await this.setState({
      enableMusic: !this.state.enableMusic
    });
    SoundManager.setEnableMusic(this.state.enableMusic, true);
    SaveData.setEnableMusic(this.state.enableMusic);
  }
  
  onChangeSound=async()=>{
    await this.setState({
      enableSound: !this.state.enableSound
    });
    SoundManager.setEnableEffect(this.state.enableSound);
    SaveData.setEnableSound(this.state.enableSound);
  }
  
  render() {
    const {
      isVisible,
    } = this.props;
    if (!isVisible) {
      return null;
    }
    
    const {      
      enableSound,
      enableMusic
    } = this.state;
    return (
      <div className="card nobg divcenter noradius noborder"
           style={styles.container}>
        <div className="pbc-card-header center position-relative">
            <div className="btn-close">
              <a style={Instyle.CursorPointer} 
                 onClick={this.handleClose}><img src="images/pbc/button-close.png" /></a>
            </div>
            <h3>{LanguageData.getText('AccountMusicSound', 'ams_001')}</h3>
        </div>
        <div className="card-body pbc-card-body">
            <div className="setting-container">
                <div className="setting-wrap clearfix">
                    <figure className="setting-icon">
                      <img src="images/icons/icon-music.png" />
                      <figcaption>{LanguageData.getText('AccountMusicSound', 'ams_002')}</figcaption>
                    </figure>
                    <div className="switch-wrap">
                      <div className="switch-item">
                        <input id="setting-toggle-1" className="switch-toggle switch-rounded-mini switch-toggle-round" type="checkbox" 
                               checked={enableMusic} onChange={this.onChangeMusic} />
                        <label htmlFor="setting-toggle-1" className="mb-1"></label>
                      </div>
                    </div>
                </div>
                <div className="setting-wrap clearfix">
                    <figure className="setting-icon">
                      <img src="images/icons/icon-sound.png" />
                      <figcaption>{LanguageData.getText('AccountMusicSound', 'ams_003')}</figcaption>
                    </figure>
                    <div className="switch-wrap">
                      <div className="switch-item">
                        <input id="setting-toggle-2" className="switch-toggle switch-rounded-mini switch-toggle-round" type="checkbox" 
                               checked={enableSound} onChange={this.onChangeSound} />
                        <label htmlFor="setting-toggle-2" className="mb-1"></label>
                      </div>
                    </div>
                </div>
            </div>
            <button className="button nomargin btn-block pbc-btn" id="login-form-submit" name="login-form-submit" value="login" 
                    onClick={this.handleClose}>
              <span>{LanguageData.getText('DialogPhonePrefixDialog', 'dppd_002')}</span>
            </button>
        </div>
      </div>
    );
  }
}

MusicAndSound.defaultProps = {
  isVisible: false, 
  closeSoundDialog: ()=>{}
};

const mapStateToProps = state => ({
  enableSound: state.common.enableSound,
  enableMusic: state.common.enableMusic,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setEnableSound: bindActionCreators(setEnableSound, dispatch),
  setEnableMusic: bindActionCreators(setEnableMusic, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MusicAndSound);