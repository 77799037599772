import React from 'react';
import QRCode from 'qrcode.react';

import LanguageData from '../../helper/languageData';
import Instyle from '../../theme/instyle';
import {DecimalPointsSettings} from '../../config/constant';

class WalletDetailsInfo extends React.Component {
  openSendETH=()=>{
    this.props.openSendETH(this.props.data);
  }
  
  copyAddress=()=>{
    const {
      data,
      copyAddress
    } = this.props;
    copyAddress(data.address);
  }
  
  openRevealSeedWords=()=>{
    this.props.openRevealSeedWords(this.props.data);
  }
  
  openEdit=()=>{
    this.props.openEdit(this.props.data);
  }
  
  render() {
    const {
      data
    } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="main-content clearfix">
              <header className="new-ticket-header noborder">
                <div className="row align-items-center justify-content-between">
                  <div className="col-6 col-md-4">
                    <div className="fav-title-wrap">
                      <h4 className="nomargin">{data.name} <a className="text-yellow" style={Instyle.YellowText} onClick={this.openEdit}><i className="icon-edit"></i></a></h4>
                    </div>
                  </div>
                  <div className="col-6 col-md-2 center">
                    <a className="button nomargin btn-block pbc-btn withdraw-btn"
                       onClick={this.openSendETH}>
                      <span>{LanguageData.getText('AccountMyWalletDetails', 'amwd_007')}</span>
                    </a>
                  </div>
                </div>
              </header>
            </div>
          </div>
        </div>        
        
        <div className="transaction-header">
          <div className="row clearfix d-flex align-items-center justify-content-between">
            <div className="col-md-2">
              <figure className="wallet-qr">
                <QRCode value={data.address} 
                        size={164} />
              </figure>
            </div>
            <div className="col-md-9 order-last">
              <div className="row pt-3 pb-3 wallet-boder-bottom align-items-center">
                <div className="col-md-4">
                  <span className="wallet-title">{LanguageData.getText('AccountMyWalletDetails', 'amwd_001')}</span>
                </div>
                <div className="col-md-8">
                  <div className="wallet-detail-wrap">
                    <ul className="list-unstyled mb-0 align-items-baseline wallet-detail-list">
                      <li><span className="wallet-balance-s">$ {parseFloat(data.usdBalance).toFixed(DecimalPointsSettings)}</span></li>
                      <li><span className="wallet-balance-b">{parseFloat(data.balance).toFixed(DecimalPointsSettings)} ETH</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row pt-3 pb-3 wallet-boder-bottom align-items-center">
                <div className="col-md-4">
                  <span className="wallet-title">{LanguageData.getText('AccountMyWalletDetails', 'amwd_002')}</span>
                </div>
                <div className="col-md-8">
                  <div className="wallet-address-v2">
                    <span className="wallet-address-copy-text">{data.address}</span>
                    <a className="button nomargin pbc-btn" 
                       onClick={this.copyAddress}>
                        <span>{LanguageData.getText('AccountMyWalletDetails', 'amwd_004')}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row pt-3 pb-3 align-items-center">
                <div className="col-10">
                  <span className="wallet-title">{LanguageData.getText('AccountMyWalletDetails', 'amwd_005')}</span>
                </div>
                <div className="col-2">
                  <div className="wallet-address-v2">
                    <a className="reveal-seed-btn" 
                       onClick={this.openRevealSeedWords}>
                      <span><i className="toggle-icon"><img src="images/pbc/help-arrow.png" /></i></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>              
      </div>
    );
  }
}

WalletDetailsInfo.defaultProps = {
  data: {
    usdBalance: 0,
    balance: 0,
    name: '',
    address: '',
    enabled: false
  },
  openSendETH: ()=>{},
  copyAddress: ()=>{},
  openRevealSeedWords: ()=>{},
  openEdit: ()=>{}
};

export default WalletDetailsInfo;