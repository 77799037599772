import React from 'react';

class SubItem extends React.Component {
  render() {
    const {
      item,
      description
    } = this.props.item;
    return (
      <div className="dialog-row dialog-subitem">
        <p className="dialog-text">{item}. </p>
        <p className="dialog-text">{description}</p>
      </div>
    );
  }
}

class Clause extends React.Component {
  renderSubItem(item, index) {
    return (
      <SubItem key={index}
               item={item} />
    );
  }
  
  render() {
    const {
      item,
      description,
      subitems
    } = this.props.item;
    const subitemList = subitems? subitems.map(this.renderSubItem) : null;
    return (
      <div>
        <div className="dialog-row">
          <p className="dialog-text">{item}.</p>
          <p className="dialog-text"> {description}</p>
        </div>
        {subitemList}
      </div>
    );
  }
}

class TermsAndConditionsSection extends React.Component {
  renderClause(item, index) {
    return (
      <Clause key={index} 
              item={item} />
    );
  }
  
  render() {
    const {
      section,
      title,
      description,
      clauses
    } = this.props.item;
    const clausesList = clauses? clauses.map(this.renderClause) : null;
    return (
      <div className="dialog-section">
        <p className="dialog-text">{section}. {title}</p>
        <p className="dialog-text">{description}</p>
        {clausesList}
      </div>
    );
  }
}

TermsAndConditionsSection.defaultProps = {
  item: {
    section: '',
    title: '',
    description: '',
    clauses: []
  }
};

export default TermsAndConditionsSection;
