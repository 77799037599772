import Types from '../actions/types';
import UtilsHelper from '../helper/UtilsHelper';

export const initialState = {
  user: null,
  alertRef: null,
  wallet: null,
  walletAddresses: [],
  games: [],
  inboxes: {
    totalCount: 0,
    unreadCount: 0,
    list: []
  },
  enableSound: true,
  enableMusic: true
};

const setUser = (state, action) => ({
  ...state,
  user: action.user
});

const setAlertRef = (state, action) => ({
  ...state,
  alertRef: action.ref
});

const setWallet = (state, action) => ({
  ...state,
  wallet: action.wallet,
  walletAddresses: UtilsHelper.getWalletAddresses(action.wallet)
});

const setWalletAddresses = (state, action) => ({
  ...state,
  walletAddresses: action.setWalletAddresses
});

const setGames = (state, action) => ({
  ...state,
  games: action.games
});

const setInboxes = (state, action) => ({
  ...state,
  inboxes: UtilsHelper.getInboxes(action.inboxes)
});

const setEnableSound = (state, action) => ({
  ...state,
  enableSound: action.enableSound
});

const setEnableMusic = (state, action) => ({
  ...state,
  enableMusic: action.enableMusic
});

const resetRedux = (state, action) => ({
  ...state,
  user: null,
  alertRef: null,
  wallet: null,
  walletAddresses: [],
  games: [],
  inboxes: {
    totalCount: 0,
    unreadCount: 0,
    list: []
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_USER:
      return setUser(state, action);
    case Types.SET_ALERT_REF:
      return setAlertRef(state, action);
    case Types.SET_WALLET:
      return setWallet(state, action);
    case Types.SET_GAMES:
      return setGames(state, action);
    case Types.SET_WALLET_ADDRESSES:
      return setWalletAddresses(state, action);
    case Types.SET_INBOXES:
      return setInboxes(state, action);
    case Types.SET_ENABLE_SOUND:
      return setEnableSound(state, action);
    case Types.SET_ENABLE_MUSIC:
      return setEnableMusic(state, action);
    case Types.RESET_REDUX:
      return resetRedux(state, action)
    default:
      return state;
  }
};
