import AsyncStorage from '@callstack/async-storage';
import { FIELD } from '../config/enum';
import { 
  DEFAULT_LANGUAGE,
  DefaultEnableSoundEffect, DefaultEnableSoundMusic} from '../config/constant';

const SaveData = {
  /**
   * Get whether the user have agreed to Pincode usage
   **/
  getAgreedToPinCode : async (userId) => {
    let agreed = 'false';
    try {
      agreed = await AsyncStorage.getItem(FIELD.PINCODE_AGREED + '_' + userId);
    } catch (e) {
      //
    }
    return agreed === 'true';
  },
  /**
   * Set whether the user have agreed to Pincode usage
   **/
  setAgreedToPinCode : async (userId) => {
    try {
      await AsyncStorage.setItem(FIELD.PINCODE_AGREED + '_' + userId, 'true');
    } catch (e) {
      //
    }
  },  
  /**
   * Get the language settings of the app
   **/
  getLanguageSettings : async () => {
    try {
      let security = await AsyncStorage.getItem(FIELD.LANGUAGE_SETTING);
      return security === null? DEFAULT_LANGUAGE : security;
    } catch (e) {
      return DEFAULT_LANGUAGE;
    }
  },
  /**
   * Set the language settings of the app
   **/
  setLanguageSettings : async (languageCode) => {
    try {
      await AsyncStorage.setItem(FIELD.LANGUAGE_SETTING, languageCode);
    } catch (e) {
      //
    }
  },  
  /**
   * Get whether the user enable sound
   **/
  getEnableSound : async () => {
    try {
      let enable = await AsyncStorage.getItem(FIELD.SOUND_ENABLE);
      return enable? enable === 'true' : DefaultEnableSoundEffect;
    } catch (e) {
      return DefaultEnableSoundEffect;
    }
  },
  /**
   * Set whether the user enable sound
   **/
  setEnableSound : async (value) => {
    try {
      await AsyncStorage.setItem(FIELD.SOUND_ENABLE, value.toString());
    } catch (e) {
      //
    }
  },  
  /**
   * Get whether the user enable music
   **/
  getEnableMusic : async () => {
    try {
      let enable = await AsyncStorage.getItem(FIELD.MUSIC_ENABLE);
      return enable? enable === 'true' : DefaultEnableSoundMusic;
    } catch (e) {
      return DefaultEnableSoundMusic;
    }
  },
  /**
   * Set whether the user enable music
   **/
  setEnableMusic : async (value) => {
    try {
      await AsyncStorage.setItem(FIELD.MUSIC_ENABLE, value.toString());
    } catch (e) {
      //
    }
  }
}

export default SaveData;