import anim237Sparkle from "../sound/effect_anim_237_sparkle.wav";
import anim645Rotate from "../sound/effect_anim_645_rotate.wav";
import buttonDisable from "../sound/effect_button_disbale.mp3";
import buttonNumber from "../sound/effect_button_number.wav";
import buttonPlay from "../sound/effect_button_play.mp3";
import buttonPurchase from "../sound/effect_button_purchase.mp3";
import buttonRandom from "../sound/effect_button_random.mp3";
import enterMyTicketWin from "../sound/effect_enter_myticket_win.mp3";
import musicBackground from "../sound/music_background.mp3";

const SoundAsset = {
  anim237Sparkle: anim237Sparkle,
  anim645Rotate: anim645Rotate,
  buttonDisable: buttonDisable,
  buttonNumber: buttonNumber,
  buttonPlay: buttonPlay,
  buttonPurchase: buttonPurchase,
  buttonRandom: buttonRandom,
  enterMyTicketWin: enterMyTicketWin,
  musicBackground: musicBackground,
}

export default SoundAsset;