import React from 'react';
import SoundManager from '../../helper/soundManager';
import LanguageData from '../../helper/languageData';

const classname = {
  item: "filter-item filter-current",
  itemSelected: "filter-item"
};
const styles = {
  cursor: {
    cursor: 'pointer'
  }
};

class MainPageHeader extends React.Component {
  onClickCarousel=()=>{
    this.props.onClickCarousel();
  }
  
  onClickList=()=>{
    this.props.onClickList();
  }
  
  render() {
    const {
      current
    } = this.props;
    const carouselStyle = current === 0? classname.itemSelected : classname.item;
    const listStyle = current === 1? classname.itemSelected : classname.item;
    return (
      <section id="page-header">
          <div className="container clearfix header-container">
              <div className="row clearfix">
                  <div className="col-md-6">
                      <div className="title-block noborder pbc-title">
                          <h3>{LanguageData.getText('webapp', 'webapp_018')}</h3>
                      </div>
                  </div>

                  <div className="col-md-6">
                      <div className="header-filter-wrap">
                          <ul className="list-unstyled pbc-filter">
                              <li><a onClick={this.onClickCarousel}
                                     style={styles.cursor}
                                     className={carouselStyle}><i className="filter-item-01"></i></a></li>
                              <li><i className="icon-line-minus filter-ratate"></i></li>
                              <li><a onClick={this.onClickList}
                                     style={styles.cursor}
                                     className={listStyle}><i className="filter-item-02"></i></a></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    );
  }
}

MainPageHeader.defaultProps = {
  onClickList: ()=>{},
  onClickCarousel: ()=>{},
  current: 0,
};

export default MainPageHeader;