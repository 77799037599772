import React from 'react';

import LanguageData from '../../helper/languageData';
import WalletTransactionRow from '../listItem/walletTransactionRow';

class WalletTransactionSection extends React.Component {
  renderTransaction(item, index) {
    return (
      <WalletTransactionRow key={index}
                            index={index}
                            data={item} />
    );
  }
  
  render() {
    const {
      date,
      transactionList,
      selectDate
    } = this.props;
    const transactions = transactionList.map(this.renderTransaction);
    return (
      <React.Fragment>
        <div className="result-detail-item clearfix result-detail-th nobgcolor">
            <div className="container clearfix">
                <div className="wallet-table-title">
                    <h3 className="text-white">{LanguageData.getText('AccountMyWalletTransaction', 'amwt_001')}</h3>
                </div>
            </div>
        </div>
        <div className="wallet-date-header clearfix">
            <div className="container clearfix">
                <div className="row justify-content-between">
                    <div className="col-6 col-md-2">
                        <span className="wallet-date">{date}</span>
                    </div>
                    <div className="col-6 col-md-2">
                        <div className="text-center">
                        <a className="button nomargin pbc-btn pbc-btn-pink" 
                           onClick={selectDate}>
                            <span>{LanguageData.getText('AccountMyTicket', 'amt_003')}</span>
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="result-detail-item clearfix result-detail-th d-none d-sm-none d-md-block">
            <div className="container">
                <div className="row clearfix">
                    <div className="col-md-2">
                        <span>{LanguageData.getText('ListItemsWalletTransactionListItem', 'liwtli_001')}</span>
                    </div>
                    <div className="col-md-6">
                        <span>{LanguageData.getText('ListItemsWalletTransactionListItem', 'liwtli_002')}</span>
                    </div>
                    <div className="col-md-2">
                        <span>{LanguageData.getText('webapp', 'webapp_023')}</span>
                    </div>
                    <div className="col-md-2">
                        <span>{LanguageData.getText('webapp', 'webapp_024')}</span>
                    </div>
                </div>
            </div>
        </div>
        {transactions}
      </React.Fragment>
    );
  }
}

WalletTransactionSection.defaultProps = {
  date: '',
  selectDate: ()=>{},
  transactionList: []
};

export default WalletTransactionSection;