import React from 'react';
import SoundManager from '../../helper/soundManager';

class NavigationBar extends React.Component {  
  playSound() {
    SoundManager.playNumber();
  }
  
  render() {
    return (
      <header id="header" className="pbc-header noborder" data-sticky-class="not-dark">
        <div id="header-wrap">
          <div className="container clearfix">
            <div id="logo" className="noborder">
              <a href="main" onClick={this.playSound} className="standard-logo" data-dark-logo="images/logo-dark.png">
                <img src="images/logo-dark.png" alt="Play Blockchain Logo" />
              </a>
              <a href="main" onClick={this.playSound} className="retina-logo" data-dark-logo="images/logo-dark@2x.png">
                <img src="images/logo-dark@2x.png" alt="Play Blockchain Logo" />
              </a>
            </div>
            <div id="side-panel-trigger" className="side-panel-trigger"><a onClick={this.playSound} href="#"><i className="icon-reorder"></i></a></div>
          </div>
        </div>
      </header>
    );
  }
}

export default NavigationBar;