import React from 'react';
import SoundManager from '../../helper/soundManager';

class DialogConfirmation extends React.Component {
  closeDialog=()=>{
    const {
      onClose
    } = this.props;
    SoundManager.playNumber();
    onClose();
  }
  
  buttonFunction=()=>{
    const {
      buttonFunction
    } = this.props;
    SoundManager.playPlay();
    buttonFunction();
  }
  
  render() {
    const {
      title,
      text,
      buttonText,
      isVisible
    } = this.props;
    if (!isVisible) {
      return false;
    }
    
    return (
      <div id="myLogoutModal" 
           className="dialog-modal"
           style={{display: isVisible? 'block' : 'none'}}>
        
        <div className="container-fluid vertical-middle divcenter clearfix">
          <div className="row justify-content-center shadow-sm">
            <div className="col-lg-3">
              <div className="card nobg divcenter noradius noborder">
                <div className="pbc-card-header center position-relative">
                  <div className="btn-close" onClick={this.closeDialog}>
                    <a><img src="images/pbc/button-close.png" /></a>
                  </div>
                  <h3>{title}</h3>
                </div>
                <div className="card-body pbc-card-body">
                  <div className="center mb-5">
                    <span className="text-white">{text}</span>
                  </div>
                  <button className="button nomargin btn-block pbc-btn" id="form-submit" name="form-submit" value="login"
                          onClick={this.buttonFunction}>
                    <span>{buttonText}</span>
                  </button>
                </div>									
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

DialogConfirmation.defaultProps = {
  title: '',
  text: '',
  buttonText: '',
  onClose: () => {},
  buttonFunction: () => {},
  isVisible: false
};

export default DialogConfirmation;