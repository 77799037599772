import React from 'react';
import { connect } from 'react-redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import Utils from '../../helper/utils';
import DateTime from '../../helper/datetime';
import LanguageImage from '../../helper/languageImage';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';

class ReferralRewards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referralCode: '',
      freeTicket: 0,
      expiryDate: '',
      disableButton: true
    };
  }
  
  async componentDidMount() {
    const {
      user
    } = this.props;
    if (user) {
      const id = user? user.username : '';
      const response = await PlayAPI.getReferralCode(id);
      if (response.status === 200) {
        const body = await response.json();
        if (!body.Error) {
          const data = body.Data;
          this.setState({
            referralCode: data.length > 0? data[0].ReferralCode : ''
          });
        }
      }    
    
      const ticketRes = await PlayAPI.getFreeTicketById(id);
      if (ticketRes.status === 200) {
        const resBody = await ticketRes.json();
        if (!resBody.Error) {
          const data = resBody.Data;
          const ticketData = data.TicketsData;
          const current = new Date().getTime();
          let ticket = 0;
          let expiryDate = 0;
          let index = -1;
          for (let i=0; i<ticketData.length; i++) {
            if (ticketData[i].Tickets > 0 && ticketData[i].ExpiredDate > current) {
              if (ticketData[i].ExpiredDate > expiryDate) {
                expiryDate = ticketData[i].ExpiredDate;
                ticket += ticketData[i].Tickets;
                index = i;
              }
            }
          }
          const dateSplit = index === -1? [''] : ticketData[index].ExpiredAt.split('T');
          this.setState({
            expiryDate: index === -1? '' : dateSplit[0],
            freeTicket: ticket
          });
        }
      }
    }
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  goToHelp=()=>{
    SoundManager.playNumber();
    this.navigateTo('help');
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  shareCode=()=>{    
    SoundManager.playPlay();
    Utils.copyToClipboard(this.state.referralCode);
    ToastsStore.warning(LanguageData.getText('AccountReferralRewards', 'arr_014'));
  }
  
  getGameDataByCode=(code)=>{
    const {
      games
    } = this.props;
    const index = games.findIndex((item)=>{ return item.objectId === code });
    if (index < 0) {
       return {game: null, disabled: true};
    }
    const havePassed = DateTime.havePassed(games[index].DrawAt);
    const disabled = havePassed === true || games[index].Status !== "1"? true : false;
    return {game: games[index], disabled: disabled};
  }
  
  onSelectGame=(evt)=>{
    const gameData = this.getGameDataByCode(evt.target.value);
    this.setState({
      disableButton: gameData.disabled
    });
  }
  
  openGame=()=>{
    const code = document.getElementById("wallet-select").value;
    const {
      freeTicket
    } = this.state;    
    const gameData = this.getGameDataByCode(code);
    if (gameData.game === null) {
      return;
    }
    
    SoundManager.playNumber();
    if (freeTicket > 0) {
      if (!gameData.disabled) {
        this.navigateTo('playnew', {
          gameData: gameData.game,
          useFreeTicket: true
        });
      }else {
        ToastsStore.warning(LanguageData.getText('AccountReferralRewards', 'arr_015'));
      }
    }else {
      ToastsStore.warning(LanguageData.getText('AccountReferralRewards', 'arr_016'));
    }
  }
  
  renderTitle() {
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('AccountReferralRewards', 'arr_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list d-flex align-items-center justify-content-end">
                <li><a className="justify-content-center" style={Instyle.WhiteText}
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
                {/*<li><a className="justify-content-center" style={Instyle.WhiteText}
                       onClick={this.goToHelp}><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>*/}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderReferSection() {
    const {
      referralCode
    } = this.state;
    const padding = referralCode === ''? {paddingTop: '19px', paddingBottom: '19px'} : null;
    return (
      <div className="col-md-6 referral-line mb-4">
        <div className="referral-wrap">
          <div className="row justify-content-between clearfix">
            <div className="col-md-8">
              <h3>{LanguageData.getText('AccountReferralRewards', 'arr_004')}</h3>
              <div className="referral-item-wrap">
                <figure>
                  <img src={LanguageImage.getImage('referral')} />
                </figure>
                <header className="referral-header">
                  <h4>{LanguageData.getText('AccountReferralRewards', 'arr_005')}</h4>
                </header>
                <section className="referral-body">
                  <span>{LanguageData.getText('AccountReferralRewards', 'arr_006')}</span>
                  <span className="mb-4">{LanguageData.getText('AccountReferralRewards', 'arr_007')}</span>
                </section>
                <footer className="referral-footer">
                  <div className="wallet-con">
                    <div className="wallet-address" style={padding}>
                      <span>{referralCode}</span>
                      <a className="button nomargin pbc-btn" onClick={this.shareCode}>
                        <span>{LanguageData.getText('AccountReferralRewards', 'arr_008')}</span>
                      </a>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  renderGameSection() {
    const {
      games
    } = this.props;
    const {
      freeTicket,
      expiryDate,
      disableButton
    } = this.state;
    let gameOptions = [];
    for (let i=0; i<games.length; i++) {
      gameOptions.push(<option key={i} value={games[i].objectId}>{games[i].GameName}</option>);
    }
    const buttonStyle = disableButton? "pbc-btn-disable" : "pbc-btn-pink";
    const valid = LanguageData.getText('AccountReferralRewards', 'arr_011').replace("<date>", expiryDate);
    return (
      <div className="col-md-6 mb-4">
        <div className="referral-wrap">
          <div className="row justify-content-end clearfix">
            <div className="col-md-8">
              <h3 className="mb-1">{LanguageData.getText('AccountReferralRewards', 'arr_009')}</h3>
              <span className="d-block text-white mb-4">{LanguageData.getText('AccountReferralRewards', 'arr_013')}</span>
              <div className="referral-container">
                <div className="ref-header">
                  <div className="ref-header-corner">
                    <span>x{freeTicket}</span>
                  </div>
                  <figure className="freeticket">
                    <img src="images/pbc/free-tickets.png" />
                  </figure>
                </div>
                <div className="ref-body">
                  <div className="ref-confirm-wrap mb-2">
                    <span className="ref-con-title mt-3">{LanguageData.getText('AccountReferralRewards', 'arr_010')}</span>
                    <span className="ref-con-body mb-3">{valid}</span>
                    <select id="wallet-select" name="wallet-select" className="form-control pbc-select-field mb-3" 
                            defaultValue="" onChange={this.onSelectGame}>
                      <option value="" disabled>{LanguageData.getText('AccountReferralRewards', 'arr_017')}</option>
                      {gameOptions}
                    </select>
                    <a className={"button nomargin btn-block pbc-btn " + buttonStyle}
                       onClick={this.openGame}>
                      <span>{LanguageData.getText('AccountReferralRewards', 'arr_012')}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container">
                  <div className="row">
                    {this.renderReferSection()}
                    {this.renderGameSection()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'bsSwitches', src:"js/components/bs-switches.js"},
          {id: 'functions', src:"./js/functions.js"},
          {id: 'bootstrapSwitch', src:"./js/html-script-ticketSwitch.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user,
  games: state.common.games
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralRewards);