import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ScriptLoader from '../../component/scriptLoader';
import SwitchRow from '../../component/listItem/switchRow';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import SaveData from '../../helper/saveData';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import { setEnableSound, setEnableMusic } from '../../actions/common';

const styles = {
  wrapper: {
    marginTop: '-10px'
  },
  topRow: {
    paddingTop: 0,
    marginBottom: '25px'
  },
  title: {
    fontSize: 20,
    color: '#FFF',
  },
  image: {
    width: '15%',
    height: 'auto'
  },
  text: {
    marginLeft: '15px',
    fontSize: 15,
    color: '#FFF'
  },
};

class SoundSettings extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.enableSound)
    this.state = {
      enableMusic: props.enableMusic,
      enableSound: props.enableSound
    };
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    history.goBack();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  flipMusic = async() => {
    await this.setState({
      enableMusic: !this.state.enableMusic
    });
    SoundManager.setEnableMusic(this.state.enableMusic, true);
    SaveData.setEnableMusic(this.state.enableMusic);
    this.props.setEnableMusic(this.state.enableMusic);
  }
  
  flipSound = async() => {
    await this.setState({
      enableSound: !this.state.enableSound
    });
    SoundManager.setEnableEffect(this.state.enableSound);
    SaveData.setEnableSound(this.state.enableSound);
    this.props.setEnableSound(this.state.enableSound);
  }
  
  handleConfirm=()=>{
    /*const {
      enableMusic,
      enableSound
    } = this.state;
    SoundManager.setEnableMusic(enableMusic, true);
    SoundManager.setEnableEffect(enableSound);
    SaveData.setEnableMusic(enableMusic);
    SaveData.setEnableSound(enableSound);
    this.props.setEnableMusic(enableMusic);
    this.props.setEnableSound(enableSound);*/
    
    this.goBack();
  }
  
  renderTitle() {
    return (
      <section id="page-header" className="pbc-border-bottom mb-3">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('AccountMusicSound', 'ams_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center"style={Instyle.WhiteText} 
                       onClick={this.handleConfirm}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  render() {
    const {
      enableMusic,
      enableSound
    } = this.state;
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">
                    
                    <div className="col-md-6 col-lg-6">
                      <div className="mb-5 pt-4 setting-left">
                        <ul className="list-unstyled setting-list">
                          <SwitchRow id={'music'} text={LanguageData.getText('AccountMusicSound', 'ams_002')} 
                                     enable={enableMusic} func={this.flipMusic} />
                          <SwitchRow id={'sound'} text={LanguageData.getText('AccountMusicSound', 'ams_003')} 
                                     enable={enableSound} func={this.flipSound} />
                        </ul>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  enableSound: state.common.enableSound,
  enableMusic: state.common.enableMusic,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setEnableSound: bindActionCreators(setEnableSound, dispatch),
  setEnableMusic: bindActionCreators(setEnableMusic, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SoundSettings);