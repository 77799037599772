import React from 'react';
import shortid from 'shortid';
import Auth from '@aws-amplify/auth';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import CardLogin from '../../component/card/login';
import CardRegister from '../../component/card/register';
import SelectLanguageDialog from '../../component/dialog/selectLanguageDialog';
import ScriptLoader from '../../component/scriptLoader';
import SaveData from '../../helper/saveData';
import UserData from '../../helper/userData';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import { BETA_REGISTRATION_LINK, 
         DEFAULT_LANGUAGE, LINK_LANGUAGE_JSON, LANGUAGE_LIST } from '../../config/constant';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      agreedToPIN: false,
      languageCode: DEFAULT_LANGUAGE,
      showLanguageDialog: false,
      languageSet: false
    };
  }
  
  async componentDidMount() {
    await this.setLanguage();
    this.checkUser();    
  }
  
  async setLanguage() {
    const savedLanguage = await SaveData.getLanguageSettings();
    this.setState({ languageCode: savedLanguage });
    LanguageData.setLanguageCode(savedLanguage);
    const response = await fetch(LINK_LANGUAGE_JSON[savedLanguage]);
    if (response.status === 200) {
      const body = await response.json();
      LanguageData.setJson(body);
    }
    this.setState({ languageSet: true });
  }
  
  async checkUser() {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();
      if (user) {
        UserData.setUser(user);
        const agreed = await SaveData.getAgreedToPinCode(user.username);
        this.setState({
          user: user,
          agreedToPIN: agreed
        });
      }
    }catch(err) {      
    }
  }
  
  handleLogin = async(email, password) => {
    if (email === '') {
      ToastsStore.error(LanguageData.getText('InitialLogin', 'il_004'));
      return;      
    }
    if (password === '') {
      ToastsStore.error(LanguageData.getText('InitialLogin', 'il_005'));
      return;      
    }
    try {
      const user = await Auth.signIn(email, password);
      this.navigateTo('main');
    }catch(err) {
      ToastsStore.error(err.message);
    }
  }
  
  handleRegister = async(firstname, lastname, email, password, referBy) => {
    if (String.isEmailFormat(email) === false) {
      ToastsStore.warning(LanguageData.getText('webapp', 'webapp_001'));
      return;
    }
    
    const referralCode = shortid.generate();
    try {
      const signup = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          given_name: firstname,
          family_name: lastname,
          'custom:referral_code': referralCode
        }
      });
      
      const userId = signup.userSub;
      const response = await PlayAPI.addReferral(userId, referBy, referralCode);
      if (response.status !== 200) {
        const body = await response.json();
        throw {message: body.ErrorDesc};
      }else {
        this.navigateTo('main');
      }
    }catch(err) {
      ToastsStore.warning('Error: ' + err.message);
    }
    
    
    this.navigateTo('main');
  }
  
  navigateTo=(page, params)=>{
    const { history } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  openWebRegister() {
    window.open(BETA_REGISTRATION_LINK, "_blank"); 
  }
  
  openPinCode=()=>{
    const {
      user,
      agreedToPIN
    } = this.state;
    if (agreedToPIN) {
      const data = user.attributes;
      this.navigateTo('loginwithpin', {
        userId: data.sub,
        email: data.email,
        name: data.given_name + ' ' + data.family_name
      });
    }else {
      this.navigateTo('setuppincode', {
        userId: user.username 
      });
    }
  }
  
  openLanguageDialog=()=>{
    SoundManager.playNumber();
    this.setState({
      showLanguageDialog: true
    });
  }
  
  onSelectLanguage = async(languageCode) => {
    if (this.state.languageCode !== languageCode) {
      await SaveData.setLanguageSettings(languageCode);
      this.setLanguage();
    }
    this.closeLanguageDialog();
  }
  
  closeLanguageDialog=()=>{
    this.setState({
      showLanguageDialog: false
    });
  }
  
  render() {
    const {
      user,
      agreedToPIN,
      languageCode,
      showLanguageDialog
    } = this.state;
    const languageIndex = LANGUAGE_LIST.findIndex((item)=>{ return languageCode === item.code });
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
            <div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                  <div style={{flexDirection:'row', paddingRight:'10px', color: '#F2F941', cursor:'pointer'}}
                       onClick={this.openLanguageDialog}>
                    <span style={{fontSize:'large', marginRight:'10px'}}>{LANGUAGE_LIST[languageIndex].name}</span>
                    <i className="toggle-icon">
                      <img style={{width:'15px'}} src="images/pbc/help-arrow.png" />
                    </i>
                  </div>
                </div>
                
                <div className="container-fluid vertical-middle divcenter clearfix">
                  <div className="row justify-content-center shadow-sm">
                    <div className="col-lg-4 login-mt">
                      <div className="tabs clearfix" id="tab-1">
                        <ul className="tab-nav clearfix login-tab d-flex align-items-end">
                          <li className="align-bottom">
                            <a className="align-bottom" href="#tabs-2">{LanguageData.getText('InitialAuth', 'ia_001')}</a>
                          </li>
                          <li className="align-bottom">
                            <a className="align-bottom" href="#" onClick={this.openWebRegister}>{LanguageData.getText('InitialAuth', 'ia_002')}</a>
                          </li>
                        </ul>
                        
                        <div className="tab-container pbc-login-cont-wrap">
                          <CardLogin user={user}
                                     navigateTo={this.navigateTo}
                                     loginUser={this.handleLogin}
                                     openPinCode={this.openPinCode} />
                          {/*<CardRegister navigateTo={this.navigateTo}
                                        registerUser={this.handleRegister} />*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="pbc-wrap-footer"></div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <SelectLanguageDialog isVisible={showLanguageDialog}
                              closeDialog={this.closeLanguageDialog}
                              onSelect={this.onSelectLanguage} />
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'wslot', src:"./js/wslot.js"},
          {id: 'wslotlanguage', src:"./js/html-selectLanguage-wslot.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

export default Login;