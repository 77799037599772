import React from 'react';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ScriptLoader from '../../component/scriptLoader';
import TutorialCarousel from '../../component/carousel/tutorialCarousel';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import DateTime from '../../helper/datetime';
import Instyle from '../../theme/instyle';

class ScreenshotTutorial extends React.Component {  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  navigateTo=()=>{
    
  }
  
  renderTitle() {
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
            <div className="row clearfix tutorial-header-row">
              <div className="col-md-6 mb-3">
                <span className="award-title">See How It Work</span>
              </div>

              <div className="col-md-6 mb-3">
                <div className="header-filter-wrap">
                  <ul className="top-nav-list">
                    <li><a className="justify-content-center"style={Instyle.WhiteText}
                           onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
                  </ul>
                </div>
              </div>
              <div className="col-12">
                <div className="line m-0 tutorial-header-line"></div>
              </div>
            </div>
        </div>
      </section>
    );
  }
  
  render() {
    const {
      state
    } = this.props.location;
    
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
			<div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobottommargin notopmargin nobg notoppadding">
                <div className="container clear-bottommargin clearfix">
                  <div className="col-12">
                    <TutorialCarousel
                      slideList={state.slideList}
                      onEndSlide={this.goBack} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
            {id: 'jquery', src:"./js/jquery.js"},
            {id: 'plugins', src:"./js/plugins.js"},
            {id: 'functions', src:"./js/functions.js"},
            {id: 'tutorialtab', src:"./js/html-script-tutorialTab.js"},
          ]} />
      </React.Fragment>
    );
  }
}

export default ScreenshotTutorial;