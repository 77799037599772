import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import SendFeedbackInput from '../../component/card/sendFeedbackInput';
import SendFeedbackSuccess from '../../component/card/sendFeedbackSuccess';
import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import PlayAPI from '../../api/playAPI';

class SendFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenState: 0, // 0 => Input, 1 => Success
      isProcessing: false
    };
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  submitFeedback = async(feedback) => {
    const { user } = this.props;
    SoundManager.playPlay();
    if (user === null) {
      ToastsStore.warning(LanguageData.getText('webapp', 'webapp_026'));
      return;
    }
    
    if (feedback === '') {
      ToastsStore.warning(LanguageData.getText('AccountSendFeedback', 'asfb_003'));
      return;
    }
    
    this.setState({ isProcessing: true });
    const response = await PlayAPI.sendFeedback(user.username, feedback);
    const body = await response.json();
    this.setState({ isProcessing: false });
    if (response.status === 200 && !body.Error) {
      this.setState({
        screenState: 1
      });
    }else {
      ToastsStore.warning('Error: ' + body.ErrorDesc);
    }
  }
  
  render() {
    const {
      screenState,
      isProcessing
    } = this.state;
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
			<div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix" style={{top:'40%'}}>
                  <div className="row justify-content-center shadow-sm">
                    {screenState === 0? 
                      <SendFeedbackInput isProcessing={isProcessing}
                                         goBack={this.goBack}
                                         submit={this.submitFeedback} /> : 
                      <SendFeedbackSuccess onClose={this.goBack} />}
                  </div>
                </div>
              </div>
			</div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functionsWallet', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(SendFeedback);