import React from 'react';

import SoundManager from '../../helper/soundManager';
import LanguageData from '../../helper/languageData';
import Instyle from '../../theme/instyle';

class TutorialCarousel extends React.Component {
  handleDone=()=>{
    this.props.onEndSlide();
  }
  
  renderTabList() {
    const { slideList } = this.props;
    let list = [];
    for (let i=0; i<slideList.length; i++) {
      const index = i + 1;
      list.push(<li key={i}><a href={"#ptab" + index.toString()}>{index}</a></li>)
    }
    return (
      <ul className="process-steps bottommargin clearfix tutorial-step">
        {list}
      </ul>
    );
  }
  
  renderSlide=(slide, index, list)=>{
    const number = index + 1;
    const id = "ptab" + number.toString();
    let pagination = [];
    for (let i=0; i<list.length; i++) {
      pagination.push(i === index? <li key={i} className="current"></li> : <li key={i}></li>);
    }
    return (
      <div key={index} id={id}>
        <div className="tutorial-wrap">
          <h4>{slide.title}</h4>
          <p>{slide.text}</p>
        </div>

        <figure>
          <img src={slide.image} />
        </figure>
        
        <div className="tutorial-pagination">
          <ul>
            {pagination}
          </ul>
        </div>
        
        <div className="psersonal-update-btn-con center tutorial-step-btn-wrap">
          {index < list.length - 1?
            <a href="#" className="button nomargin pbc-btn tab-linker" rel={number + 1}>
              <span>{LanguageData.getText('TutorialQuick', 'tq_008')}</span>
            </a> :
            <a href="#" className="button nomargin pbc-btn tab-linker">
              <span>{LanguageData.getText('TutorialQuick', 'tq_007')}</span>
            </a>
          }
        </div>
      </div>
    );
  }
  
  render() {
    const { slideList } = this.props;
    const slides = slideList.map(this.renderSlide);
    return (
      <div id="processTabs" className="tutorial-container">                      
        {this.renderTabList()}
        <div>
          {slides}
        </div>

      </div>
    );
  }
}

TutorialCarousel.defaultProps = {
  slideList: [],
  onEndSlide: ()=>{}
};

export default TutorialCarousel;