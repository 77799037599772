import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ScriptLoader from '../../component/scriptLoader';
import GameCountdown from '../../component/module/gameCountdown';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import DateTime from '../../helper/datetime';
import Utils from '../../helper/utils';
import {DecimalPointsSettings} from '../../config/constant';

class QuickTutorial extends React.Component {
  constructor(props) {
    super(props);
    this.enjoyhintSteps = [
      {"next .owl-prev": LanguageData.getText('TutorialQuick', 'tq_002')},
      {"next .owl-next": LanguageData.getText('TutorialQuick', 'tq_002')},
      {"next #insight-game-02": LanguageData.getText('TutorialQuick', 'tq_003')},
      {"next #result-game-02": LanguageData.getText('TutorialQuick', 'tq_004')},
      {"next #play-game-02": LanguageData.getText('TutorialQuick', 'tq_005')}
    ];
  }
  
  componentDidMount() {
    const {games} = this.props;
    if (games.length > 0) {
      setTimeout(()=>{
        const script = document.createElement("script");
        const scriptString = Utils.createEnjoyhintScript(
          this.enjoyhintSteps, 
          LanguageData.getText('TutorialQuick', 'tq_008'), 
          LanguageData.getText('TutorialQuick', 'tq_006'), 
          LanguageData.getText('TutorialQuick', 'tq_007'));
        const child = document.createTextNode(scriptString);
        script.appendChild(child);
        script.async = true;
        script.onload = () => this.onScriptLoad();
        document.body.appendChild(script);
      }, 500);
    }
  }
  
  onScriptLoad() {
    
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  navigateTo() {
    
  }
  
  renderTitle() {
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
            <div className="row clearfix">
                <div className="col-md-6">
                  <div className="title-block noborder pbc-title">
                    <h3>{LanguageData.getText('webapp', 'webapp_018')}</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="header-filter-wrap">
                    <ul className="list-unstyled pbc-filter">
                      <li><a href="#" className="filter-item"><i className="filter-item-01"></i></a></li>
                      <li><i className="icon-line-minus filter-ratate"></i></li>
                      <li><a href="#" className="filter-item filter-current"><i className="filter-item-02"></i></a></li>
                    </ul>
                  </div>
                </div>
            </div>
        </div>
      </section>
    );
  }
  
  renderGame(game, index) {
    const style = index === 1? "display-item-wrap" : "display-item-wrap side d-none d-sm-block";
    const insightId = "insight-game-0" + (index + 1);
    const resultId = "result-game-0" + (index + 1);
    const playId = "play-game-0" + (index + 1);
    const backgroundImage = {backgroundImage: "url(" + game.ImagePath + ")", backgroundPosition: "center"};
    const countdown = DateTime.getCountBreakdown(game.Status === '1'? game.DrawAt : 0);
    const havePassed = DateTime.havePassed(game.Status === '1'? game.DrawAt : 0);
    const statusText = havePassed? 'gm_002' : 'gm_001';
    return (
      <div key={index} className="col-md-4">
        <div className={style}>
            <figure className="game-item" id="item-01-fig" style={backgroundImage}>
                <img src={game.GameIcon} alt="" />
                <figcaption className="game-item-des">
                    <h4>{LanguageData.getText('GameMain', 'gm_006')} ({LanguageData.getText('webapp', 'webapp_010')}: {game.DrawNumber})</h4>
                    <span className="item text-yellow item-award">{parseFloat(game.JackpotValue).toFixed(DecimalPointsSettings)}ETH</span>
                    <span className="item end-time">{LanguageData.getText('GameMain', statusText)}</span>
                      <GameCountdown
                        index={index}
                        countdown={countdown} />
                    <div className="item-footer clearfix">
                      <div className="footer-thr">
                        <button id={insightId} name={insightId} className="button nomargin btn-block pbc-btn pbc-btn-pink pl-0 pr-0">
                          <span>Insights</span>
                        </button>
                      </div>
                      <div className="footer-thr">
                        <button className="button nomargin btn-block pbc-btn pbc-btn-blue" id={resultId} name={resultId} value="">
                          <span>{LanguageData.getText('GameMain', 'gm_004')}</span>
                        </button>
                      </div>
                      <div className="footer-thr">
                        <button className="button nomargin btn-block pbc-btn" id={playId} name={playId} value="">
                          <span>{LanguageData.getText('GameMain', 'gm_005')}</span>
                        </button>
                      </div>
                    </div>
                </figcaption>
            </figure>
        </div>
      </div>
    );
  }
  
  render() {
    const {
      games
    } = this.props;
    let gameList = games.map(this.renderGame);
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobottommargin notopmargin nobg notoppadding">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix align-items-center pt-5">
                    {games.length > 0?
                      <div className="owl-nav disabled step-nav-container">
                        <a type="button" role="presentation" className="owl-prev"><i className="icon-angle-left"></i></a>
                        <a type="button" role="presentation" className="owl-next"><i className="icon-angle-right"></i></a>
                      </div> :
                      null
                    }
                    {gameList}
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
            {id: 'jquery', src:"./js/jquery.js"},
            {id: 'plugins', src:"./js/plugins.js"},
            {id: 'mislider', src:"./css/carousel/js/mislider.js"},
            {id: 'slider', src:"./js/html-script-slider.js"},
            {id: 'functions', src:"./js/functions.js"},  
            {id: 'enjoyhint', src:"./js/enjoyhint.min.js"},
          ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  games: state.common.games,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickTutorial);