import React from 'react';
import moment from 'moment';

const className = {
  redBall: 'r-ball r-rball',
  greenBall: 'r-ball r-gball',
};

class InsightItemRow extends React.Component {
  handleChangeSelected=(event)=>{
    const {
      onChecked,
      number
    } = this.props;
    onChecked(number);
  }
  
  render() {
    const {
      isChecked,
      number,
      frequency,
      drawAt,
    } = this.props;
    const lastDraw = moment(drawAt);
    const now = moment(new Date());
    const day = now.diff(lastDraw, 'days');
    let dayText = '<DAY> days ago';
    dayText = dayText.replace('<DAY>', day);    
    const colour = isChecked? className.greenBall : className.redBall;
    return (
      <div className="id-body-row">
        <div className="insights-check">
          <input type="checkbox"
                 checked={isChecked}
                 onChange={this.handleChangeSelected} />
          <span className="checkmark"></span>
        </div>
        <div>
          <span className={colour}>{number}</span>
        </div>
        <div>
          <span className="r-frequency">{frequency}</span>
        </div>
        <div>
          <span className="r-last-draw">{dayText}</span>
          <span className="r-last-date">({moment(drawAt).format('D MMM YYYY')})</span>
        </div>
      </div>
    );
  }
}

InsightItemRow.defaultProps = {
  isChecked: false,
  number: 0,
  frequency: 0,
  drawAt: '',
  onChecked: ()=>{}
};

export default InsightItemRow;