import { 
  DefaultEnableSoundEffect, 
  DefaultEnableSoundMusic 
} from '../config/constant';
import SoundAsset from '../theme/sound';

class SoundManager {
  static anim237Sparkle;
  static anim645Rotate;
  static buttonDisable;
  static buttonNumber;
  static buttonPlay;
  static buttonPurchase;
  static buttonRandom;
  static enterMyTicketWin;
  static musicBackground;
  
  static enableEffect = DefaultEnableSoundEffect;
  static enableMusic = DefaultEnableSoundMusic;
  static haveInitialize = false;
  
  static init() {
    if (!this.haveInitialize) {
      this.anim237Sparkle = new Audio(SoundAsset.anim237Sparkle);
      this.anim645Rotate = new Audio(SoundAsset.anim645Rotate);
      this.buttonDisable = new Audio(SoundAsset.buttonDisable);
      this.buttonNumber = new Audio(SoundAsset.buttonNumber);
      this.buttonPlay = new Audio(SoundAsset.buttonPlay);
      this.buttonPurchase = new Audio(SoundAsset.buttonPurchase);
      this.buttonRandom = new Audio(SoundAsset.buttonRandom);
      this.enterMyTicketWin = new Audio(SoundAsset.enterMyTicketWin);
      this.musicBackground = new Audio(SoundAsset.musicBackground);
      this.haveInitialize = true;
    }
  }
  
  static setEnableEffect(enable) {
    this.enableEffect = enable;
  }
  
  static setEnableMusic(enable, startMusic = false) {
    this.enableMusic = enable;
    if (enable === false) {
      this.stopMusicBackground();
    }else {
      if (startMusic) {
        this.playMusicBackground();
      }
    }
  }
  
  static playDisable() {
    if (this.enableEffect) {
      this.buttonDisable.pause();
      this.buttonDisable.currentTime = 0;
      this.buttonDisable.play();
    }
  }
  
  static playNumber() {
    if (this.enableEffect) {
      this.buttonNumber.pause();
      this.buttonNumber.currentTime = 0;
      this.buttonNumber.play();
    }
  }
  
  static playPlay() {
    if (this.enableEffect) {
      this.buttonPlay.pause();
      this.buttonPlay.currentTime = 0;
      this.buttonPlay.play();
    }
  }
  
  static playPurchase() {
    if (this.enableEffect) {
      this.buttonPurchase.pause();
      this.buttonPurchase.currentTime = 0;
      this.buttonPurchase.play();
    }
  }
  
  static playRandom() {
    if (this.enableEffect) {
      this.buttonRandom.pause();
      this.buttonRandom.currentTime = 0;
      this.buttonRandom.play();
    }
  }
  
  static playEnterMyTicketWin() {
    if (this.enableEffect) {
      this.enterMyTicketWin.play();
    }
  }
  
  static stopEnterMyTicketWin() {    
    this.enterMyTicketWin.pause();
    this.enterMyTicketWin.currentTime = 0;
  }
  
  static playMusicBackground() {
    if (this.enableMusic) {
      this.musicBackground.play();
    }
  }
  
  static stopMusicBackground() {
    this.musicBackground.pause();
    this.musicBackground.currentTime = 0;
  }
}

export default SoundManager;