import React from 'react';

import GameCountdown from '../module/gameCountdown';
import DateTime from '../../helper/datetime';
import String from '../../helper/string';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import {DecimalPointsSettings} from '../../config/constant';

class Game extends React.Component {
  openInsight=()=>{
    const {
      gameData,
      openInsight
    } = this.props;
    SoundManager.playNumber();
    openInsight(gameData);
  }
  
  openGame=()=>{
    const {
      gameData,
      openGame
    } = this.props;
    const {
      Status,
      DrawAt
    } = gameData;
    
    const havePassed = DateTime.havePassed(DrawAt);
    const disabled = havePassed === true || Status !== "1"? true : false;
    if (!disabled) {
      SoundManager.playPlay();
      openGame(gameData);
    }else {
      SoundManager.playDisable();
    }
  }
  
  openResult=()=>{
    const {
      gameData,
      openResult
    } = this.props;
    SoundManager.playNumber();
    openResult(gameData);
  }
  
  renderCountdown() {
    const {
      index,
      gameData
    } = this.props;
    const {
      DrawAt
    } = gameData;
    const countdown = DateTime.getCountBreakdown(gameData.Status === '1'? DrawAt : 0);
    const havePassed = DateTime.havePassed(gameData.Status === '1'? DrawAt : 0);
    const statusText = havePassed? 'gm_002' : 'gm_001';
    return (
      <React.Fragment>
        <span className="item end-time">{LanguageData.getText('GameMain', statusText)}</span>
        <GameCountdown
          index={index}
          countdown={countdown} />
      </React.Fragment>
    );
  }
  
  render() {
    const {
      index,
      gameData
    } = this.props;
    const {
      GameName,
      GameType,
      GameIcon,
      JackpotValue,
      DrawNumber,
      ImagePath,
      Status,
      DrawAt
    } = gameData;    
    const insightId = "insight-game-0" + (index + 1);
    const resultId = "result-game-0" + (index + 1);
    const playId = "play-game-0" + (index + 1);    
    const havePassed = DateTime.havePassed(DrawAt);
    const disabled = havePassed === true || Status !== "1"? true : false;
    const buttonStyle = disabled? "pbc-btn-disable" : "pbc-btn";
    const backgroundImage = {backgroundImage: "url(" + ImagePath + ")", backgroundPosition: "center"};
    return (
      <div className="oc-item">
          <figure className="game-item" style={backgroundImage}>
              <img src={GameIcon} alt={GameName} />
              <figcaption className="game-item-des">
                  <h4>{LanguageData.getText('GameMain', 'gm_006')} ({LanguageData.getText('webapp', 'webapp_010')}: {DrawNumber})</h4>
                  <span className="item text-yellow item-award">{parseFloat(JackpotValue).toFixed(DecimalPointsSettings)}ETH</span>
                  {this.renderCountdown()}
                
                  <div className="item-footer clearfix">
                    <div className="footer-thr">
                      <button className="button nomargin btn-block pbc-btn pbc-btn-pink pl-0 pr-0"
                              id={insightId} name={insightId} onClick={this.openInsight}>
                        <span>{LanguageData.getText('GameMain', 'gm_007')}</span>
                      </button>
                    </div>
                    <div className="footer-thr">
                      <button className="button nomargin btn-block pbc-btn pbc-btn-blue" value="" 
                              id={resultId} name={resultId} onClick={this.openResult}>
                        <span>{LanguageData.getText('GameMain', 'gm_004')}</span>
                      </button>
                    </div>
                    <div className="footer-thr">
                      <button className={"button nomargin btn-block " + buttonStyle} value=""
                              id={playId} name={playId} onClick={this.openGame}>
                        <span>{LanguageData.getText('GameMain', 'gm_005')}</span>
                      </button>
                    </div>
                  </div>
              </figcaption>
          </figure>
      </div>
    );
  }
}

Game.defaultProps = {
  index: 0,
  gameData: {},
  openResult: ()=>{},
  openGame: ()=>{},
  openInsight: ()=>{}
};

export default Game;