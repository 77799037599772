import moment from 'moment';
import DateTime from './datetime';
export default class Utils {
  static addTicketRandomNumber(current, maxNumber, totalBallCount) {
    const amountNeeded = totalBallCount - current.length;
    for (let i=0; i<amountNeeded; i++) {
      current.push(this.getRandomNumber(current, maxNumber));
    }
    current.sort(function(a, b){return a-b});
    return current;
  }
  
  static getRandomNumber(array, maxNumber) {
    var number = 0;
    do {
      number = Math.floor(Math.random() * maxNumber) + 1;
    }while(array.includes(number));
    return number;
  }
  
  static copyToClipboard (str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
  
  static checkWinInfo(tickets, result) {
    if (tickets === null || result == null || result.Win === false) {
      return {win: false, numbers: [], amountETH: 0, amountUSD: 0};
    }
    
    let winNumber = [];
    const resultNumbers = result.WinningNumbers;
    for (let i=0; i<tickets.length; i++) {
      let ticket = tickets[i];
      for (let j=0; j<ticket.length; j++) {       
        if (resultNumbers.includes(ticket[j]) && !winNumber.includes(ticket[j])) {
          winNumber.push(ticket[j]);
        } 
      }
    }
    return {win: result.Win, numbers: winNumber, amountETH: result.Payout, amountUSD: result.USD};
  }
  
  static getGameDataByCode(games, code) {
    const index = games.findIndex((item)=>{ return item.objectId === code });
    if (index < 0) {
       return {game: null, disabled: true};
    }
    const havePassed = DateTime.havePassed(games[index].DrawAt);
    const disabled = havePassed === true || games[index].Status !== "1"? true : false;
    return {game: games[index], disabled: disabled};
  }
  
  /**
   * Create a javascript script in text form for EnjoyHint
   * params {array} stepsList - The list of steps for EnjoyHint
   * params {string} nextText - Text for the next button
   * params {string} skipText - Text for the skip button
   * params {string} doneText - Text for the done button
   * return {string} - The script in text form to be append to the page
   **/
  static createEnjoyhintScript(stepsList, nextText = 'Next', skipText = 'Skip', doneText = 'Done') {
    const init = 'var enjoyhint_instance = new EnjoyHint({onEnd: function() {window.history.back();},onSkip: function() {window.history.back();}});';
    const setRun = 'enjoyhint_instance.set(enjoyhint_script_steps);enjoyhint_instance.run();';
    let steps = 'var enjoyhint_script_steps = [';
    for (let i=0; i<stepsList.length; i++) {
      const key = Object.keys(stepsList[i])[0];
      const value = stepsList[i][key];
      const nextTxt = i < stepsList.length - 1? nextText : doneText;
      const next = ', "nextButton": {text: "' + nextTxt + '"}'; 
      const skip = ', "skipButton": {text: "' + skipText + '"}';
      const end = i < stepsList.length - 1? '},' : '}';
      steps += '{' + '"' + key + '":"' + value + '"' + next + skip + end;
    }
    steps += '];';
    return init + steps + setRun;
  }
  
  /**
   * Returns whether the game is currently running or not
   * @param {string} gameId - The ID of the game 
   * @param {array} gameList - The list of games from the server.
   * @return {boolean} - Is the game running
   **/
  static isGameRunning(gameId, gameList) {
    const game = gameList.find((item)=>{ return item.objectId === gameId; });
    var seconds = 0;
    if (game && game.Status === '1') {
      const drawAt = moment(game.DrawAt);
      const now = moment(new Date());
      seconds = drawAt.diff(now, 'seconds');
    }
    console.log(game.Status, seconds)
    return game.Status === '1' && seconds > 0;
  }
}