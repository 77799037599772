import * as  firebase from 'firebase/app';
import 'firebase/messaging';
import {
  CONFIG as firebaseConfig,
  WPC_KEY_PAIR
} from '../config/firebase';

class FirebaseHelper {
  static initalizedFirebase = null;
  
  static init() {
    this.initalizedFirebase = firebase.initializeApp(firebaseConfig);
    const messaging = this.initalizedFirebase.messaging();
    messaging.usePublicVapidKey(WPC_KEY_PAIR);
  }
  
  static async getNotificationPermission() {
    try {
      const messaging = this.initalizedFirebase.messaging();
      await messaging.requestPermission();
    }catch(err) {
      console.log(err);
    }
  }
  
  static firebaseMessaging() {
    const messaging = this.initalizedFirebase.messaging();
    return messaging;
  }
  
  static async getMessagingToken() {
    const messaging = this.initalizedFirebase.messaging();
    const refreshToken = await messaging.getToken();
    return refreshToken;
  }
}

export default FirebaseHelper;