import React from 'react';

import DateTime from '../../helper/datetime';
import String from '../../helper/string';
import LanguageData from '../../helper/languageData';

class GameCountdown extends React.Component {
  render() {
    const {
      index,
      countdown
    } = this.props;
    const countdownId = "countdown-ex" + (index + 1);
    return (
      <div id={countdownId} className="countdown mb-4">            
        <span className="countdown-row countdown-show3">
          <span className="countdown-section">
            <span className="countdown-amount">{String.addNumberPrefix(countdown.day, 2)}</span>
            <span className="countdown-period">{LanguageData.getText('ModulesCountdown', 'mc_001')}</span>
          </span>
          <span className="countdown-section">
            <span className="countdown-amount">{String.addNumberPrefix(countdown.hour, 2)}</span>
            <span className="countdown-period">{LanguageData.getText('ModulesCountdown', 'mc_002')}</span>
          </span>
          <span className="countdown-section">
            <span className="countdown-amount">{String.addNumberPrefix(countdown.min, 2)}</span>
            <span className="countdown-period">{LanguageData.getText('ModulesCountdown', 'mc_003')}</span>
          </span>
          <span className="countdown-section">
            <span className="countdown-amount">{String.addNumberPrefix(countdown.sec, 2)}</span>
            <span className="countdown-period">{LanguageData.getText('ModulesCountdown', 'mc_004')}</span>
          </span>
        </span>
      </div>
    );
  }
}

GameCountdown.defaultProps = {
  index: 0,
  countdown: {
    day: 0, hour: 0, min: 0, sec: 0
  }
};

export default GameCountdown;