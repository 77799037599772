import React from 'react';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Agreement from '../../component/card/agreement';
import PINInput from '../../component/card/pinInput';
import SuccessAction from '../../component/card/successAction';
import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import SaveData from '../../helper/saveData';
import PlayAPI from '../../api/playAPI';

class SetupPincode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 0, //0 => agreement, 1 => setup PIN, 2 => reenter PIN, 3 => completed
      pincode: '',
    };
    this.userId = '';
  }
  
  componentDidMount() {
    const {
      userId
    } = this.props.location.state;
    this.userId = userId;
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  onAgreementNext=()=>{
    this.setState({
      state: 1
    });
  }
  
  submitPIN=(pincode)=>{
    const {
      state,
    } = this.state;
    if (state === 1) {
      this.setState({
        state: 2,
        pincode: pincode
      });
    }else {
      if (this.state.pincode === pincode) {
        this.registerPINCodeToBackend(pincode);
      }else {
        ToastsStore.warning(LanguageData.getText('InitialSetupPincode', 'ispc_001'));
        this.setState({
          state: 1,
          pincode: ''
        });
      }
    }
  }
  
  async registerPINCodeToBackend(pincode) {
    const response = await PlayAPI.setUserPINCode(this.userId, pincode);
    if (response.status === 200) {
      const body = await response.json();
      await SaveData.setAgreedToPinCode(this.userId);
      this.setState({
        state: 3
      });
    }
  }
  
  onFinishedSetup=()=>{
    const { history } = this.props;
    history.push('/main');
  }
  
  renderPopupByState() {
    switch(this.state.state) {
      case 1:
        return (
          <PINInput title={LanguageData.getText('AccountSetupPINCode', 'aspc_001')}
                    text={LanguageData.getText('AccountSetupPINCode', 'aspc_004')}
                    buttonText={LanguageData.getText('InitialSetupPinCodeFingerprintAgreement', 'ispcfa_004')}
                    onSubmit={this.submitPIN} onClose={this.goBack} />
        );
      case 2:
        return (
          <PINInput title={LanguageData.getText('AccountSetupPINCode', 'aspc_001')}
                    text={LanguageData.getText('AccountSetupPINCode', 'aspc_005')}
                    buttonText={LanguageData.getText('InitialSetupPinCodeFingerprintAgreement', 'ispcfa_004')}
                    onSubmit={this.submitPIN} onClose={this.goBack} />
        );
      default:
        return (
          <Agreement title1={LanguageData.getText('AccountSetupPINCode', 'aspc_001')}
                     title2={LanguageData.getText('AccountDefaultSecurity', 'ads_006')}
                     text={LanguageData.getText('AccountDefaultSecurity', 'ads_007')}
                     image={'images/pbc/lock-pin.svg'}
                     onPressNext={this.onAgreementNext}
                     goBack={this.goBack} />
        );
    }
  }
  
  render() {
    const {
      state
    } = this.state;
    
    if (state === 3) {
      return (
        <SuccessAction title={LanguageData.getText('InitialSetupFingerprintIDCompleted', 'isfic_002')}
                       text={LanguageData.getText('AccountSetupPINCode', 'aspc_003')}
                       buttonText={LanguageData.getText('InitialSetupFingerprintIDCompleted', 'isfic_001')}
                       buttonFunction={this.onFinishedSetup} />
      );
    }else {
      return (
        <React.Fragment>
          <div id="wrapper" className="clearfix bg-transparent">
            <section id="content" className="bg-transparent">
              <div className="content-wrap pbc-wrapper nopadding nomargin">
                <div className="section nobg full-screen nopadding nomargin">
                  <div className="container-fluid vertical-middle divcenter clearfix">
                    <div className="row justify-content-center shadow-sm">
                      {this.renderPopupByState()}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div id="gotoTop" className="icon-angle-up"></div>
          <ToastsContainer store={ToastsStore} 
                           position={ToastsContainerPosition.TOP_RIGHT} />
          <ScriptLoader scriptArray={[
            {id: 'jquery', src:"./js/jquery.js"},
            {id: 'plugins', src:"./js/plugins.js"},
            {id: 'functions', src:"./js/functions.js"}
          ]} />
        </React.Fragment>
      );
    }
  }
}

export default SetupPincode;