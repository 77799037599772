import React from 'react';
import LanguageData from '../../helper/languageData';

class SmartPickDetails extends React.Component {
  render() {
    return (
      <div className="col-md-8 mb-4">
        <div className="notification-body message-body">
          <header className="clearfix d-flex align-items-center pb-2 pt-3 mb-2">
            <div className="message-top-header">
              <ul className="top-nav-list d-flex align-items-center justify-content-end">
                <li className="text-right"><a href="#" className="justify-content-center"><span><img src="images/pbc/previous-36.png" /> </span></a></li>
                <li className="text-right"><a href="#" className="justify-content-center"><span><img src="images/pbc/next-36.png" /> </span></a></li>
              </ul>
            </div>
          </header>
          <section className="msg-body-wrap">
            <div className="msg-body-header mb-3">
              <div className="row">
                <div className="col-9">
                  <h3 className="msg-body-title">{LanguageData.getText('AccountSmartPick', 'asp_002')} <a className="text-yellow" href="#"><i className="icon-edit"></i></a></h3>
                  <span className="msg-body-date">12 Aug 2018 01:35p.m.</span>
                </div>
                <div className="col-3">
                  <div className="msg-del-wrap">
                    <a href="main.html" className="justify-content-center"><span><img src="images/pbc/del-icon.png" /> <span className="d-none d-sm-none d-lg-inline">Delete</span></span></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="sp-ball-body">
              <div className="sp-body-header pbc-border-bottom">
                <h4 className="text-white mb-2">{LanguageData.getText('AccountSmartPick', 'asp_003')}</h4>
                <div className="row">
                    <div className="col-8">
                      <ul className="list-unstyled sp-header-ball-list">
                        <li>7</li>
                        <li>18</li>
                        <li>24</li>
                        <li>26</li>
                        <li>38</li>
                        <li>41</li>
                      </ul>
                    </div>
                    <div className="col-4">
                      <ul className="header-option clearfix sp-header-option">
                        <li>
                          <a href="#"><i><img src="images/pbc/eraser.png" /></i></a>
                        </li>
                        <li>
                          <a href="#"><i><img src="images/pbc/smart-pick.png" /></i></a>
                        </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div className="sp-ball-container">
                  <ul className="list-unstyled sp-header-ball-list">
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                      <li>4</li>
                      <li>5</li>
                      <li>6</li>
                      <li>7</li>
                      <li>8</li>
                      <li>9</li>
                      <li>10</li>
                      <li>11</li>
                      <li>12</li>
                      <li>13</li>
                      <li>14</li>
                      <li>15</li>
                      <li>16</li>
                      <li>17</li>
                      <li>18</li>
                      <li>19</li>
                      <li>20</li>
                      <li>21</li>
                      <li>22</li>
                      <li>23</li>
                      <li>24</li>
                      <li>25</li>
                      <li>26</li>
                      <li>27</li>
                      <li>28</li>
                      <li>29</li>
                      <li>30</li>
                      <li>31</li>
                      <li>32</li>
                      <li>33</li>
                      <li>34</li>
                      <li>35</li>
                      <li>36</li>
                      <li>37</li>
                      <li>38</li>
                      <li>39</li>
                      <li>40</li>
                      <li>41</li>
                      <li>42</li>
                      <li>43</li>
                      <li>44</li>
                      <li>45</li>
                  </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default SmartPickDetails;