class UserData {
  static user = null;
  
  static setUser(user) {
    this.user = user;
  }
  
  static resetUser() {
    this.user = null;
  }
  
  static getJWTToken() {
    return this.user? this.user.signInUserSession.accessToken.jwtToken : '';
  }
}

export default UserData;