// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    // identityPoolId: 'ap-southeast-1:f63210dc-6f59-4d24-a37d-a99caaf74b9a',
    // REQUIRED - Amazon Cognito Region
    // region: 'ap-southeast-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'ap-southeast-1_xFVS5v4yE',
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: '4q9mb2k6okgj2lntder38loelc'
  }
};

export default awsmobile;
