import React from 'react';
import Auth from '@aws-amplify/auth';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Instyle from '../../theme/instyle';
import ScriptLoader from '../../component/scriptLoader';
import String from '../../helper/string';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    };
    this.email = this.props.location.state? this.props.location.state.email : '';
    this.password = '';
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  onPressReset=()=>{   
    SoundManager.playPlay(); 
    const code = document.getElementById('reset-form-code').value;
    const newPassword = document.getElementById('reset-form-password').value;
    const conPassword = document.getElementById('reset-form-con-password').value;    
    if (code === '') {
      ToastsStore.warning(LanguageData.getText('InitialResetPassword', 'irp_004'));
      return;
    }
    
    const containsLowercaseAlphabet = String.containsLowercaseAlphabet(newPassword);
    const containsUppercaseAlphabet = String.containsUppercaseAlphabet(newPassword);
    const containsNumber = String.containsNumber(newPassword);
    const containsSpecialCharacter = String.containsSpecialCharacter(newPassword);
    const haveEnoughCharacters = String.haveEnoughCharacters(newPassword, 6);
    if (containsLowercaseAlphabet === false || containsUppercaseAlphabet === false ||
        containsNumber === false || containsSpecialCharacter === false) {
      ToastsStore.warning(LanguageData.getText('webapp', 'webapp_002'));
      return;
    }
    if (haveEnoughCharacters === false) {
      ToastsStore.warning(LanguageData.getText('InitialResetPassword', 'irp_006'));
      return;
    }
    if (conPassword !== newPassword) {
      ToastsStore.warning(LanguageData.getText('InitialResetPassword', 'irp_007'));
      return;
    }
    this.resetPassword(code, newPassword);
  }
  
  resetPassword = async(code, password) => {
    try {
      await Auth.forgotPasswordSubmit(this.email, code, password);
      this.password = password;
      this.setState({ submitted: true });
    }catch(err) {
      ToastsStore.warning('Error: ' + err.message);
    }
  }
  
  onPressPlay = async() => {
    SoundManager.playPlay(); 
    try {
      await Auth.signIn(this.email, this.password);
      this.props.history.push('main');
    }catch(err) {
      ToastsStore.warning('Error: ' + err.message);
    }
  }
  
  renderForm() {
    return (
      <div className="card nobg divcenter noradius noborder">
        <div className="pbc-card-header center position-relative">
          <div className="btn-close">
            <a style={Instyle.CursorPointer}
               onClick={this.goBack}><img src="images/pbc/button-close.png" /></a>
          </div>
          <h3>{LanguageData.getText('InitialForgotPassword', 'ifp_005')}</h3>
        </div>
        <div className="card-body pbc-card-body">
          <figure className="center verify-img mb-0">
            <img src="images/pbc/treasure-box.png" />
          </figure>
          <div className="center mb-3">
            <span className="text-white">{LanguageData.getText('InitialResetPasswordMail', 'irpm_001')} {this.email}</span>
          </div>
          <div className="login-form mb-3">
            <div className="col_full col_mb">
              <input type="text" name="reset-form-code" className="form-control not-dark" 
                     id="reset-form-code" placeholder={LanguageData.getText('InitialResetPassword', 'irp_008')} />
            </div>
            <div className="col_full col_mb">
              <input type="password" name="reset-form-password" className="form-control not-dark" 
                     id="reset-form-password" placeholder={LanguageData.getText('InitialResetPassword', 'irp_009')} />
            </div>
            <div className="col_full col_mb">
              <input type="password" name="reset-form-con-password" className="form-control not-dark" 
                     id="reset-form-con-password" placeholder={LanguageData.getText('InitialResetPassword', 'irp_010')} />
            </div>											
          </div>
          <button className="button nomargin btn-block pbc-btn" name="login-form-submit" value="login"
                  id="login-form-submit" onClick={this.onPressReset}>
            <span>{LanguageData.getText('InitialResetPassword', 'irp_011')}</span>
          </button>
        </div>									
      </div>
    );
  }
  
  renderSubmitted() {
    return (
      <div className="card nobg divcenter noradius noborder">
        <div className="pbc-card-header center">
          <h3>{LanguageData.getText('InitialResetPassword', 'irp_001')}</h3>
        </div>
        <div className="card-body pbc-card-body">
          <div className="ver-des center mb-5">
            <span>{LanguageData.getText('InitialResetPassword', 'irp_002')}</span>
          </div>
          <button className="button nomargin btn-block pbc-btn" id="login-form-submit" name="login-form-submit" value="login"
                  onClick={this.onPressPlay}>
            <span>{LanguageData.getText('InitialSetupFingerprintIDCompleted', 'isfic_001')}</span>
          </button>
        </div>
      </div>
    );
  }
  
  render() {
    const {
      submitted
    } = this.state;
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
			<div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix">

                  <div className="row justify-content-center shadow-sm">
                    <div className="col-lg-3">
                      {submitted? this.renderSubmitted() : this.renderForm()}
                    </div>						
                  </div>
                              
                </div>
              </div>
              {submitted? <div className="pbc-wrap-footer-v2"></div> : null}
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>       
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

export default ResetPassword;