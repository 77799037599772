import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import WalletDetailsInfo from '../../component/card/walletDetailsInfo';
import WalletTransactionSection from '../../component/card/walletTransactionSection';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import DateTime from '../../helper/datetime';
import Utils from '../../helper/utils';
import Instyle from '../../theme/instyle';
import { setWallet } from '../../actions/common';

class MyWalletDetails extends React.Component {
  constructor(props) {
    super(props);
    
    const {
      data,
      index
    } = this.props.location.state;
    this.state = {
      index: index,
      data: data,
      transactionList: [],
      date: DateTime.getCurrentMonthYear(),
    }
  }
  
  componentDidMount() {
    this.search();
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  async search() {
    const {
      data,
      date,
    } = this.state;
    const response = await PlayAPI.getWalletTransaction(data.address);
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
        this.setState({
          transactionList: body.Data
        });
      }
    }
  }
  
  setAsDefault = async(evt) => {
    SoundManager.playNumber();
    const enable = evt.target.value === "on";
    if (enable) {
      const {
        index,
      } = this.state;
      const {
        wallet,
      } = this.props;
      if (index !== wallet.DefaultIndex) {
        const { wallet } = this.props;
        wallet.DefaultIndex = index;
        await this.props.setWallet(wallet);
        await PlayAPI.switchDefaultWallet(wallet.UserIds[0], wallet.objectId, wallet.DefaultIndex);
        let newData = this.state.data;
        newData.enabled = true;
        this.setState({data: newData});
      }
    }
  }
  
  openEdit=(data)=>{
    SoundManager.playNumber();
  }
  
  openSendETH=(data)=>{
    SoundManager.playPlay();
    this.navigateTo('sendeth', {data: data});
  }
  
  copyAddressToClipboard(address) {
    SoundManager.playPlay();
    Utils.copyToClipboard(address);
    ToastsStore.warning(LanguageData.getText('AccountMyWalletDetails', 'amwd_008'));
  }
  
  openRevealSeedWord=(data)=>{
    const {
      wallet,
    } = this.props;
    SoundManager.playNumber();
    this.navigateTo('revealseedwords', {
      userId: wallet.UserIds[0],
      mnemonic: wallet.Mnemonic
    });
  }
  
  openDateSelect=()=>{
    SoundManager.playNumber();
  }
  
  renderTitle() {
    const {
      data
    } = this.state;
    const enabled = data? data.enabled : false;
    return (
      <section id="page-header">
          <div className="container clearfix header-container">
            <div className="row clearfix">
              <div className="col-md-9">
                <span className="award-title">{LanguageData.getText('AccountMyWallet', 'amw_001')}</span>
              </div>
              <div className="col-md-3">
                <ul className="top-nav-list d-flex align-items-center justify-content-end">
                  <li><a className="justify-content-center" style={Instyle.WhiteText} 
                         onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
                  <li className="ml-4">
                    <div className="switch">
                      <input id="switch-toggle-1" className="switch-toggle switch-rounded-mini switch-toggle-round" type="checkbox"
                             checked={enabled} onChange={this.setAsDefault}/>
                      <label htmlFor="switch-toggle-1" className="mb-1"></label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </section>
    );
  }
  
  render() {
    const {
      date,
      data,
      transactionList
    } = this.state;
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
			<div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <WalletDetailsInfo data={data} 
                                   openSendETH={this.openSendETH}
                                   copyAddress={this.copyAddressToClipboard} 
                                   openRevealSeedWords={this.openRevealSeedWord}
                                   openEdit={this.openEdit} />
                <WalletTransactionSection date={date}
                                          transactionList={transactionList}
                                          selectDate={this.openDateSelect} />
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functionsWallet', src:"./js/functions.js"},
          {id: 'switch', src:"./js/html-script-ticketSwitch.js"}
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wallet: state.common.wallet
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setWallet: bindActionCreators(setWallet, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyWalletDetails);