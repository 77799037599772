import React from 'react';

import LanguageData from '../../helper/languageData';
import Instyle from '../../theme/instyle';

class RevealSeedWordsReveal extends React.Component {
  render() {
    const {
      seedWords,
      onClose,
      copyText
    } = this.props;
    return (
      <div className="col-lg-3">								
        <div className="card nobg divcenter noradius noborder">
            <div className="pbc-card-header center position-relative">
              <div className="btn-close">
                <a style={Instyle.CursorPointer}
                   onClick={onClose}><img src="images/pbc/button-close.png" /></a>
              </div>
              <h3>{LanguageData.getText('AccountRevealSeedWords', 'arsw_001')}</h3>
            </div>
            <div className="wallet-send-title">
              <div className="wallet-send-title-wrap">
                <div className="d-flex align-items-center warning-wrap border-bottom-light">
                  <div className="icon-warring">
                    <img src="images/pbc/icon-warning.png" />
                  </div>
                  <div className="warning-text p-3">
                    {LanguageData.getText('AccountRevealSeedWords', 'arsw_002')}
                  </div>
                </div>
                <div className="pt-3 pb-3">
                  <span className="text-white">{LanguageData.getText('AccountRevealSeedWords', 'arsw_007')}</span>
                </div>
              </div>
            </div>

            <div className="card-body pbc-card-body border-top-0">
              <div className="mb-2">
                <div className="col_full">
                  <span className="text-white mb-2 d-block">{LanguageData.getText('AccountRevealSeedWords', 'arsw_008')}</span>
                  <textarea className="form-control seed-words-textarea" id="exampleFormControlTextarea1" rows="3" 
                            value={seedWords} readOnly={true}></textarea>
                </div>
                <div className="col_full nobottommargin">
                  <button className="button nomargin btn-block pbc-btn" id="change-password" name="change-password" value=""
                          onClick={copyText}>
                    <span>{LanguageData.getText('AccountRevealSeedWords', 'arsw_010')}</span>
                  </button>
                </div>
              </div>
            </div>

        </div>
      </div>
    );
  }
}

RevealSeedWordsReveal.defaultProps = {
  seedWords: '',
  copyText: ()=>{},
  onClose: ()=>{}
};

export default RevealSeedWordsReveal;