import React from 'react';
import LanguageData from '../../helper/languageData';
import Instyle from '../../theme/instyle';

class SendFeedbackSuccess extends React.Component {
  render() {
    const {
      onClose
    } = this.props;
    return (
      <div className="col-lg-3" style={{maxWidth:'100%', flexBasis:'32%'}}>
        <div className="card nobg divcenter noradius noborder">
          <div className="pbc-card-header center position-relative">
            <div className="btn-close">
              <a style={Instyle.CursorPointer} 
                 onClick={onClose}><img src="images/pbc/button-close.png" /></a>
            </div>
            <h3>{LanguageData.getText('AccountSendFeedback', 'asfb_010')}</h3>
          </div>
          <div className="card-body pbc-card-body">
            <figure className="center verify-img mb-0">
              <img src="images/pbc/transaction-success.png" />
            </figure>
            <div className="ver-des center mb-4">
              <span className="text-white">{LanguageData.getText('AccountSendFeedback', 'asfb_011')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SendFeedbackSuccess.defaultProps = {
  onClose: ()=>{}
};

export default SendFeedbackSuccess;