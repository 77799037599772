import React from 'react';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { connect } from 'react-redux';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import MyTicketSection from '../../component/listItem/myTicketSection';
import SelectMonthYearDialog from '../../component/dialog/selectMonthYearDialog';
import IconLoader from '../../component/card/iconLoader';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import Utils from '../../helper/utils';
import LanguageImage from '../../helper/languageImage';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import {DecimalPointsSettings} from '../../config/constant';

class MyTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: {},
      sectionList: [],
      showPicker: false,
      isProcessing: false
    };
    this.resultList = [];
    this.failedList = [];
  }
  
  componentDidMount() {
    if (this.props.location.state) {
      const {
        resultList,
        failedList
      } = this.props.location.state;
      if (resultList) {
        this.resultList = resultList;
        this.failedList = failedList;
      }
    }
    
    this.search({});
  }
  
  showPicker=()=>{
    SoundManager.playNumber();
    this.setState({
      showPicker: true
    });
  }
  
  hidePicker=()=>{
    this.setState({
      showPicker: false
    });
  }
  
  onSelectDate=(date)=>{
    this.setState({date: date});
    this.hidePicker();
    this.search(date)
  }
  
  async search(date) {
    const {
      user,
      games
    } = this.props;
    this.setState({ isProcessing: true });
    const userId = user? user.username : '';
    const startDate = date.start_at? date.start_at : '';
    const endDate = date.end_at? date.end_at : '';
    const response = await PlayAPI.getPurchase(userId, startDate, endDate);
    await this.setState({
      isProcessing: false
    });
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
        const data = body.Data;
        
        let list = data;
        for (let h=0; h<data.length; h++) {
          //Stop processing result if new list is retrieved
          if (this.state.isProcessing) {
            break;
          }
          list[h].DrawAt = await this.getDrawDate(list[h].GameId, list[h].DrawNumber);
          if (list[h].Status === "1") {
            list[h].DrawResult = await this.getDrawResultFromAPI(list[h].GameId, list[h].DrawNumber, list[h].Items);
          }else {
            list[h].DrawResult = {GameId: list[h].GameId, DrawNumber: list[h].DrawNumber, Win: false};
          }
          
          const sections = await this.getSections(list, games);
          await this.setState({
            sectionList: sections
          });
        }
      }
    }
  }
  
  async getSections(list, games) {
    if (!list) return [];
    await list.forEach(async(item) => {
      const selected = games.find(game => { return item.GameId === game.objectId; });
      item.Game = selected;
      if (item.DrawResult)
        item.WinInfo = Utils.checkWinInfo(item.Items, item.DrawResult);
    });

    const sections = [];
    let currentMonth = '';
    let section;
    for (let i = 0, ni = list.length; i < ni; i++) {
      const item = list[i];
      if (item.Game != undefined) {
        const openAt = moment(item.createdAt);
        const month = openAt.format('MMMM YYYY');
        if (month !== currentMonth) {
          section = {
            title: month,
            data: [
              item
            ]
          };
          currentMonth = month;
          sections.push(section);
        } else {
          section.data.push(item);
        }
      }
    }
    return sections;
  }
  
  async getDrawResultFromAPI(gameId, drawNumber, boughtTicket) {    
    const index = this.resultList.findIndex((item) => { return item.GameId === gameId && item.DrawNumber === drawNumber; });
    const failedIndex = this.failedList.findIndex((item) => { return item.GameId === gameId && item.DrawNumber === drawNumber; });
    if (index > -1) { 
      return this.getDrawResult(gameId, drawNumber, boughtTicket);
    }
    if (failedIndex > -1) {
      return {GameId: gameId, DrawNumber: drawNumber, Win: false};
    }
    
    const {
      user,
      games
    } = this.props;
    const game = games.find((item) => { return item.objectId === gameId; });
    if (game && drawNumber < game.DrawNumber) {
      const response = await PlayAPI.getWinningNumber(gameId, drawNumber,  user.username);
      if (response.status === 200) {
        const body = await response.json();
        if (body.Error === false && body.Data.length > 0) {
          const data = body.Data;
          this.resultList.push({GameId: data[0].GameId, DrawNumber: data[0].DrawNumber, Data: data});
          return this.getDrawResult(gameId, drawNumber, boughtTicket);
        }
      }else {
        this.failedList.push({GameId: gameId, DrawNumber: drawNumber});
        return {GameId: gameId, DrawNumber: drawNumber, Win: false};
      }
    }else {
      return {GameId: gameId, DrawNumber: drawNumber, Win: false};
    }
  }
  
  getDrawResult(gameId, drawNumber, boughtTicket) {
    /*const index = this.resultList.findIndex((item) => { return item.GameId === gameId && item.DrawNumber === drawNumber; });
    if (index > -1) {
      const result = this.resultList[index];
      const resIndex = result.Data.findIndex((item) => { return JSON.stringify(item.Items) === JSON.stringify(boughtTicket); });
      return result.Data[resIndex];
    }else {
      return null;
    }*/
    
    
    const index = this.resultList.findIndex((item) => { return item.GameId === gameId && item.DrawNumber === drawNumber; });
    if (index > -1) {
      const result = this.resultList[index];
      let win = false;
      let breakdown = [];
      let total = new BigNumber(0);
      for (let i=0; i<boughtTicket.length; i++) {
        const resIndex = result.Data.findIndex((item) => { 
          return JSON.stringify(item.Item) === JSON.stringify(boughtTicket[i]); 
        });
        const resObj = result.Data[resIndex];
        if (resObj.Win === true) {
          win = true;
        }
        breakdown.push(resObj.Payout);
        total = total.plus(new BigNumber(resObj.Payout));
      }
      
      let drawResult = {
        Win: win,
        Payout: total.toFixed(),
        PayoutBreakdown: breakdown,
        Items: boughtTicket,
        WinningNumbers: result.Data[0].WinningNumbers
      }
      return drawResult;
    }else {
      return {Win: false};
    }
  }
  
  async getDrawDate(gameId, drawNumber) {
    const response = await PlayAPI.getGameResultByDrawNumber(gameId, drawNumber);
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
       if (body.Data.length > 0) {
         return body.Data[0].DrawAt;
       } 
      }
    }
    return null;
  }
  
  openDetails=(ticketData)=>{
    this.navigateTo('myticketdetails', {
      ticketData: ticketData,
      resultList: this.resultList,
      failedList: this.failedList
    });
  }
  
  openAddNew=()=>{
    SoundManager.playNumber();
    this.navigateTo('selectticket');
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  renderTitle() {
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('AccountMyTicket', 'amt_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list d-flex align-items-center justify-content-end">
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.openAddNew}><span><img src="images/pbc/add-ticket-2.png" /> {LanguageData.getText('AccountMyTicket', 'amt_004')}</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderTop() {
    const {date} = this.state;
    const curDate = date.start_at? date.month + '/' + date.year : 'All';
    return (
      <div className="section pbc-section pt-4 pb-4 notopmargin mb-3 title-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="main-content clearfix">
                <header className="new-ticket-header noborder nopadding nomargin">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-6 col-md-4">
                      <div className="fav-title-wrap">
                        <h4 className="nomargin">{curDate}</h4>
                      </div>
                    </div>
                    <div className="col-6 col-md-2 center">
                      <a className="button nomargin pbc-btn pbc-btn-pink select-btn"
                         onClick={this.showPicker}>
                        <span>{LanguageData.getText('AccountMyTicket', 'amt_003')}</span>
                      </a>
                    </div>
                  </div>
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  renderSection=(item, index)=>{
    return (
      <MyTicketSection key={index} 
                       index={index} 
                       title={item.title} 
                       ticketList={item.data} 
                       openDetails={this.openDetails} />
    );
  }
  
  render() {
    const {
      showPicker,
      sectionList,
      isProcessing
    } = this.state;
    const sections = sectionList.map(this.renderSection);
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              {this.renderTop()}
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      {isProcessing? 
                        <IconLoader isProcessing={isProcessing} /> : 
                        sectionList.length > 0? 
                          sections : 
                          <div style={{display:'flex', flexDirection:'column', alignItems:'center', padding: 40}}>
                            <img style={{width: 350}} src={LanguageImage.getImage('emptyTicket')} />
                            <span style={{marginTop: 15, fontSize:'18px', color:'white', textAlign:'center'}}>{LanguageData.getText('AccountMyTicket', 'amt_002')}</span>
                          </div>
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <SelectMonthYearDialog isVisible={showPicker}
                               closeDialog={this.hidePicker}
                               onSelect={this.onSelectDate} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'bsSwitches', src:"js/components/bs-switches.js"},
          {id: 'wslot', src:"./js/wslot.js"},
          {id: 'dateSelect', src:"./js/html-selectDate-wslot.js"},
          {id: 'functions', src:"./js/functions.js"},
          {id: 'bootstrapSwitch', src:"./js/html-script-ticketSwitch.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user,
  games: state.common.games
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(MyTicket);
