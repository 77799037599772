import React from 'react';
import { connect } from 'react-redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import LanguageImage from '../../helper/languageImage';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import {USER_NUMBER_TYPE} from '../../config/enum';

class FavouriteCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ''
    };
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  onEditName=(evt)=>{
    this.setState({
      name: evt.target.value
    });
  }
  
  addToFavourite = async() => {
    const {
      name
    } = this.state;
    const {
      user,
      history
    } = this.props;
    const {
      tickets,
      gameData
    } = this.props.location.state;
    SoundManager.playPlay();
    const response = await PlayAPI.addFavourite(user.username, gameData.objectId, USER_NUMBER_TYPE.FAVORITE, name, tickets);
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
      history.push({
        pathname: '/myfavourite',
        state: {gameData: gameData}
      });
      } else {
        ToastsStore.warning(body.ErrorDesc);
      }
    }else {
      ToastsStore.warning(LanguageData.getText('webapp', 'webapp_003'));
    }
  }
  
  render() {
    const {
      name
    } = this.state;
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
			<div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix">
                  <div className="row justify-content-center shadow-sm">
                    
                      <div className="col-lg-3">
                        <div className="card nobg divcenter noradius noborder">
                            <div className="pbc-card-header center position-relative">
                              <div className="btn-close">
                                <a style={Instyle.CursorPointer}
                                   onClick={this.goBack}><img src="images/pbc/button-close.png" /></a>
                              </div>
                              <h3>{LanguageData.getText('GamePlayFavouriteMake', 'gpfm_002')}</h3>
                            </div>
                            <div className="card-body pbc-card-body">
                              <figure className="center verify-img mb-0">
                                <img src={LanguageImage.getImage('favouriteCreate')} />
                              </figure>
                              <div className="center mb-3">
                                <span className="text-white">{LanguageData.getText('GamePlayFavouriteMake', 'gpfm_004')}</span>
                              </div>
                              <form className="login-form mb-3">
                                <div className="col_full col_mb">
                                  <input type="text" id="favourite-field" name="favourite-field" className="form-control not-dark" value={name}
                                         placeholder={LanguageData.getText('GamePlayFavouriteMake', 'gpfm_002')} onChange={this.onEditName} />
                                </div>
                              </form>
                              <button className="button nomargin btn-block pbc-btn" id="login-form-submit" name="login-form-submit" 
                                      value="login" onClick={this.addToFavourite}>
                                <span>{LanguageData.getText('GamePlayFavouriteMake', 'gpfm_003')}</span>
                              </button>
                            </div>
                        </div>
                      </div>
                    
                  </div>
                      
                </div>
              </div>
			</div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteCreate);