import React from 'react';
import { connect } from 'react-redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import Utils from '../../helper/utils';
import Instyle from '../../theme/instyle';
import { SELECT_TICKET_INFO } from '../../config/constant';

class SelectTicket extends React.Component {  
  goBack=()=>{
    this.props.history.goBack();
  }
  
  openGame=()=>{
    const picker = document.getElementById('game-picker');
    const gameIndex = picker.value? parseInt(picker.value) : 0;    
    const gameData = Utils.getGameDataByCode(this.props.games, SELECT_TICKET_INFO[gameIndex].objectId);
    if (!gameData.disabled) {
      this.props.history.push({
        pathname: '/playnew',
        state: {gameData: gameData.game, useFreeTicket: false}
      });
    }else {
      ToastsStore.warning(LanguageData.getText('AccountSelectTicket', 'ast_001'));
    }
  }
  
  renderGame=(game)=>{
    const { games } = this.props;
    const data = Utils.getGameDataByCode(games, game.objectId);
    if (data.disabled) {
      return null;
    }else {
      return (
        <li key={game.objectId}>
          <a style={Instyle.CursorPointer} 
             onClick={()=>{ this.openGame(game); }}>{game.GameName} {game.GameType}</a>
        </li>
      );
    }
  }
  
  render() {
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
			<div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix">
                    <div className="row justify-content-center shadow-sm">
                      <div className="col-lg-3 col-md-6">
                        <div className="card nobg divcenter noradius noborder">
                            <div className="pbc-card-header center position-relative">
                              <div className="btn-close">
                                <a style={Instyle.CursorPointer}
                                   onClick={this.goBack}><img src="images/pbc/button-close.png" /></a>
                              </div>
                              <h3>{LanguageData.getText('AccountSelectTicket', 'ast_002')}</h3>
                            </div>
                            <div className="card-body pbc-card-body p-3">
                              <div id="game-picker" className="picker-container mb-4"></div>
                              <button className="button nomargin btn-block pbc-btn" id="select-ticket" name="select-ticket" value=""
                                      onClick={this.openGame}>
                                <span>{LanguageData.getText('AccountSendETHSuccess', 'ases_002')}</span>
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
			</div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'wslot', src:"./js/wslot.js"},
          {id: 'selectTicketWslot', src:"./js/html-selectTicket-wslot.js"},
          {id: 'functionsWallet', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  games: state.common.games
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectTicket);