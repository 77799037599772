import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import InsightSelected from '../../component/card/insightSelected';
import InsightsCarousel from '../../component/carousel/insightsCarousel';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Utils from '../../helper/utils';
import Instyle from '../../theme/instyle';
import { USER_NUMBER_TYPE } from '../../config/enum';
import { InsightFavouriteName } from '../../config/constant';

class InsightCreate extends React.Component {
  constructor(props) {
    super(props);
    const {
      gameData,
      saveChanges
    } = props.location.state;
    const defaultSelection = [];
    this.state = {
      gameData,
      numberOption: [],
      selection: defaultSelection,
      numberSlot: gameData.BallsCount
    };
    this.gameId = gameData.objectId;
    this.haveSubmitted = false;
    this.saveChanges = saveChanges;
    this.sections = [
      {title: 'Most Drawn Number', apiName: 'getMostDraw'},
      {title: 'Least Drawn Number', apiName: 'getLeastDraw'},
      {title: 'Most Purchased Number', apiName: 'getMostPurchase'},
      {title: 'Least Purchased Number', apiName: 'getLeastPurchase'},
    ];
  }
  
  async componentDidMount() {  
    let list = [];
    for (let i=0; i<this.sections.length; i++) {
      const options = await this.getInsightNumbers(this.sections[i].apiName, this.gameId);
      list.push(options);
    }
    await this.setState({
      numberOption: list
    });
  }
  
  /**
   * Get the list of Insight options from the API
   * @params {string} apiName - The name of the API that will be call
   * @params {string} gameId - The selected game ID
   * @return {array} - The list of insight options
   **/
  async getInsightNumbers(apiName, gameId) {
    const size = 10;
    const getFunction = PlayAPI[apiName];
    const response = await getFunction(gameId, size);
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
        for (let i=0; i<body.Data.length; i++) {
          body.Data[i].selected = false;
        }
        return body.Data;
      }else {
        return [];
      }
    }else {
      return [];
    }
  }
  
  async getCurrentFavourite(gameId) {
    const { user } = this.props;
    const response = await PlayAPI.getFavourite(user.username, gameId, USER_NUMBER_TYPE.SMART_PICK);
    if (response.status === 200) {
      const body = await response.json();
      return body.Data;
    }else {
      return [];
    }
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  goToHelp=()=>{
    const {
      gameData
    } = this.state;
    SoundManager.playNumber();
    this.navigateTo('help', { gameData: gameData });
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  onSelectNumber=(number)=>{
    let { numberOption, selection, numberSlot } = this.state;
    const selectionIndex = selection.indexOf(number);
    
    if (selectionIndex === -1 && selection.length === numberSlot) {
      let message = 'You can only select <NUMBER> numbers';
      message = message.replace('<NUMBER>', numberSlot.toString());
      ToastsStore.error(message);
      return;
    }
    
    for (let i=0; i<numberOption.length; i++) {
      for (let j=0; j<numberOption[i].length; j++) {
        if (number === numberOption[i][j].Number) {
          numberOption[i][j].selected = !numberOption[i][j].selected;
        }
      }
    }
    
    if (selectionIndex > -1) {
      selection.splice(selectionIndex, 1);
    }else {
      selection.push(number);
    }
    selection.sort((a,b)=>{ return a - b; });
    
    this.setState({
      selection,
      numberOption
    });
  }
  
  saveSelection = async() => {
    const {
      selection,
      numberSlot
    } = this.state;
    if (selection.length < numberSlot) {
      let message = 'You must select <NUMBER> numbers';
      message = message.replace('<NUMBER>', numberSlot.toString());
      ToastsStore.error(message);
      return;
    }
    
    const currentTicketData = await this.getCurrentFavourite(this.gameId);
    if (currentTicketData.length === 0) {
      this.createNewFavourite(selection);
    }else {
      if (!this.haveSubmitted) {
        if (this.saveChanges) {
          this.updateFavourite(currentTicketData, selection);
        }else {
          this.openFavouriteDetailUnsaved(currentTicketData, selection);
        }
      }else {
        this.onUpdateFavourite();
      }
    }
  }
  
  /**
   * Create a new Insight Favourite
   * @params {array} ticket - The selection of numbers the user picked 
   **/
  async createNewFavourite(ticket) {
    const { 
      user 
    } = this.props;
    const response = await PlayAPI.createInsightFavourite(user.username, this.gameId, USER_NUMBER_TYPE.SMART_PICK, InsightFavouriteName, ticket);
    if (response.status === 200) {
      this.onUpdateFavourite();
    }else {
      const body = await response.json();
      ToastsStore.error(body.ErrorDesc);
    }
  }
  
  /**
   * Update the existing Insight Favourite
   * @params {array} currentTicketData - Data of the existing Insight Favourite
   * @params {array} ticket - The selection of numbers the user picked 
   **/
  async updateFavourite(currentTicketData, ticket) {
    const { 
      user 
    } = this.props;
    const ticketId = currentTicketData[0].objectId;
    const combinedTicket = [...currentTicketData[0].Numbers, ticket];
    const response =  await PlayAPI.updateFavourite(user.username, this.gameId, USER_NUMBER_TYPE.SMART_PICK, ticketId, combinedTicket);
    if (response.status === 200) {
      this.onUpdateFavourite();
    }else {
      const body = await response.json();
      ToastsStore.error(body.ErrorDesc);
    }
  }
  
  /**
   * Sends the user to Play screen
   * with the updated Insight Favourite data
   **/
  async onUpdateFavourite() {
    this.haveSubmitted = true;    
    if (!Utils.isGameRunning(this.gameId, this.props.games)) {
      ToastsStore.error('You Insight Favourite have been updated');
      setTimeout(()=>{
        this.goBack();
      }, 1500);
    }else {    
      const {
        gameData
      } = this.state;
      const favouriteData = await this.getCurrentFavourite(this.gameId);
      this.navigateTo('favouritedetails', {
        gameData: gameData,
        favouriteData: favouriteData[0],
        useFreeTicket: false,
        editOnEnter: false 
      });
    }
  }
  
  /**
   * Sends the user to Play screen
   * without updating the user's insight favourite data on server.
   * This is used when user comes from Play Favorite Detail screen.
   * @params {object} currentTicketData - The current Insight Favourite
   * @params {array} newTicket - The newly selected ticket numbers
   **/
  openFavouriteDetailUnsaved(currentTicketData, newTicket) {
    this.haveSubmitted = true;
    const {
      history
    } = this.props;
    const {
      gameData,
      selection
    } = this.state;
    let newData = currentTicketData[0];
    newData.Numbers = [...newData.Numbers, newTicket];
    history.replace('favouritedetails', {
      gameData,
      favouriteData: newData,
      useFreeTicket: false,
      editOnEnter: true 
    });
  }
  
  /**
   * Handles when user press Delete in "gamePlayFavoriteDetail"
   * @params {object} - The data of the deleted ticket
   **/
  onDeleteFavourite=(data)=>{
    this.goBack();
  }
  
  renderTitle() {
    const {
      GameName
    } = this.state.gameData;
    return (
      <section id="page-header" className="account-title-sec">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{GameName}: Insights</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goToHelp}><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  render() {
    const {
      selection,
      numberOption,
      numberSlot
    } = this.state;
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <InsightsCarousel 
            sections={this.sections}
            selection={selection}
            numberOption={numberOption}
            onSelectNumber={this.onSelectNumber} />
        </div>
        <InsightSelected 
          selectedNumber={selection}
          numberSlot={numberSlot}
          handleSave={this.saveSelection} />
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        {numberOption.length > 0?
          <ScriptLoader scriptArray={[
            {id: 'plugins', src:"./js/plugins.js"},
            {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
            {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
            {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
            {id: 'functions', src:"./js/functions.js"},
          ]} /> :
          null
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user,
  games: state.common.games
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightCreate);