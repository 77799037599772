import React from 'react';

import LanguageData from '../../helper/languageData';
import String from '../../helper/string';
import Instyle from '../../theme/instyle';
import { PINCODE_LENGTH } from '../../config/constant';

class RevealSeedWordsInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    }
  }
  
  onEditPIN=(evt)=>{
    const value = evt.target.value;
    if (value.length > 0) {
      if (value.length > PINCODE_LENGTH || String.onlyContainsNumber(value) === false) {
        return;
      }
    }
    this.setState({
      value: value
    });
    if (value.length === PINCODE_LENGTH) {
      this.props.checkPIN(value);
    }
  }
  
  render() {
    const { onClose } = this.props;
    
    return (
      <div className="col-lg-3">
        <div className="card nobg divcenter noradius noborder">
          <div className="pbc-card-header center position-relative">
            <div className="btn-close">
              <a style={Instyle.CursorPointer}
                 onClick={onClose}><img src="images/pbc/button-close.png" /></a>
            </div>
            <h3>{LanguageData.getText('AccountRevealSeedWords', 'arsw_001')}</h3>
          </div>
          <div className="card-body pbc-card-body">
            <div className="d-flex align-items-center warning-wrap">
              <div className="icon-warring">
                <img src="images/pbc/icon-warning.png" />
              </div>
              <div className="warning-text p-3">
                  {LanguageData.getText('AccountRevealSeedWords', 'arsw_002')}
              </div>
            </div>
            <div className="pin-code-wrap mb-3">
              <h5 className="text-center text-white">{LanguageData.getText('AccountRevealSeedWords', 'arsw_006')}</h5>
              <input type="password" id="create-pin" name="create-pin" className="form-control not-dark" placeholder=""
                     value={this.state.value} onChange={this.onEditPIN} />
            </div>
          </div>									
        </div>
      </div>
    );
  }
}

RevealSeedWordsInput.defaultProps = {
  onClose: ()=>{},
  checkPIN: ()=>{}
};

export default RevealSeedWordsInput;