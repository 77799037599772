import React from 'react';
import { connect } from 'react-redux';

import Utils from '../../helper/utils';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import MusicAndSound from '../dialog/musicAndSound';

class NewNavBar extends React.Component { 
  constructor(props) {
    super(props);
    const {
      user,
      inboxes
    } = props;
    const name = user === null? '' : user.attributes.given_name;
    const notification = inboxes.unreadCount;
    this.state = {
      showAccount: false,
      showGame: false,
      showDialog: false,
      accountButtons: [
        {name: LanguageData.getText('settings', 'profile'), subtext: name, onClick: this.changeScreen.bind(this, 'profile')},
        {name: LanguageData.getText('settings', 'wallet'), notificationValue: 0, onClick: this.changeScreen.bind(this, 'mywallet')},
        {name: LanguageData.getText('settings', 'tickets'), notificationValue: 0, onClick: this.changeScreen.bind(this, 'myticket')},
        {name: LanguageData.getText('settings', 'notification'), notificationValue: notification, onClick: this.changeScreen.bind(this, 'notifications')},
        {name: LanguageData.getText('settings', 'referral'), notificationValue: 0, onClick: this.changeScreen.bind(this, 'referralrewards')},
        {name: LanguageData.getText('TutorialMain', 'tm_001'), notificationValue: 0, onClick: this.changeScreen.bind(this, 'tutorial')},
        {name: LanguageData.getText('AccountSettings', 'as_008'), notificationValue: 0, onClick: this.changeScreen.bind(this, 'faq')},
        {name: LanguageData.getText('settings', 'settings'), notificationValue: 0, onClick: this.changeScreen.bind(this, 'settings')},
      ]
    };
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.inboxes !== prevProps.inboxes) {
      const {
        accountButtons
      } = this.state;
      accountButtons[3].notificationValue = this.props.inboxes.unreadCount;
      this.setState({
        accountButtons: [...accountButtons]
      });
    }
  }
  
  playSound() {
    SoundManager.playNumber();
  }
  
  flipAccount=()=>{
    SoundManager.playNumber();
    this.setState({
      showGame: false,
      showAccount: !this.state.showAccount
    });
  }
  
  flipGame=()=>{
    SoundManager.playNumber();
    this.setState({
      showGame: !this.state.showGame,
      showAccount: false
    });
  }
  
  openGame=(gameData)=>{
    SoundManager.playNumber();
    this.props.navigateTo('playnew', {gameData: gameData, useFreeTicket: false});
  }
  
  changeScreen(screen) {
    SoundManager.playNumber();
    this.props.navigateTo(screen);
  }
  
  showSoundDialog=()=>{
    SoundManager.playNumber();
    this.setState({
      showDialog: true
    });
  }
  
  closeSoundDialog=()=>{
    this.setState({
      showDialog: false
    });
  }
  
  renderGame=(game)=>{
    const { games } = this.props;
    const data = Utils.getGameDataByCode(games, game.objectId);
    if (data.disabled) {
      return null;
    }else {
      return (
        <li key={game.objectId}>
          <a style={Instyle.CursorPointer} 
             onClick={()=>{ this.openGame(game); }}>{game.GameName}</a>
        </li>
      );
    }
  }
  
  renderGameSection() {
    const {
      games
    } = this.props;
    const {
      showGame
    } = this.state;
    let gameList = [];
    if (games) {
      for (let i=0; i<games.length; i++) {
        gameList.push(this.renderGame(games[i]));
      }
    }
    const classname1 = showGame? "nav-item dropdown show" : "nav-item dropdown";
    const classname2 = showGame? "dropdown-menu show" : "dropdown-menu";
    return (
      <li className={classname1}>
          <a className="nav-link dropdown-toggle" onClick={this.flipGame} style={Instyle.CursorPointer} 
             aria-expanded={showGame.toString()}>
            <i className="nav-icon nav-ticket"></i><span>{LanguageData.getText('webapp', 'webapp_018')}</span>
          </a>
          <ul className={classname2} style={{marginTop: '50px'}} role="menu">
            {gameList}
          </ul>
      </li>
    );
  }
  
  renderAccountButton=(item, index)=>{
    const {
      name,
      subtext,
      notificationValue,
      onClick
    } = item;
    if (subtext) {
      return (
        <li key={index}>
          <a className="dropdown-item" style={Instyle.CursorPointer} 
             onClick={onClick}>{name} <span>{subtext}</span></a>
        </li>
      );
    }
    const notification = notificationValue > 0? <i className="noti">{notificationValue}</i> : null;
    return (
      <li key={index}>
        <a className="dropdown-item" style={Instyle.CursorPointer} 
           onClick={onClick}>{name} {notification}</a>
      </li>
    );
  }
  
  renderAccountSection() {
    const {
      inboxes
    } = this.props;
    const {
      showAccount,
      accountButtons
    } = this.state;
    const notification = inboxes.unreadCount > 0? <em className="top-noti">{inboxes.unreadCount}</em> : null;
    const buttons = accountButtons.map(this.renderAccountButton);
    const classname1 = showAccount? "nav-item dropdown show" : "nav-item dropdown";
    const classname2 = showAccount? "dropdown-menu show" : "dropdown-menu";
    return (
      <li className={classname1}onClick={this.flipAccount} >
          <a className="nav-link dropdown-toggle" style={Instyle.CursorPointer} 
             aria-expanded={showAccount.toString()}>
            <i className="nav-icon nav-acc">{notification}</i><span>{LanguageData.getText('webapp', 'webapp_019')}</span>
          </a>
          <ul className={classname2} style={{marginTop: '50px'}} role="menu">
            {buttons}
          </ul>
      </li>
    );
  }
  
  render() {
    const {
      showDialog
    } = this.state;
    return (
      <header id="header" className="pbc-header noborder" data-sticky-class="not-dark">
			<div id="header-wrap">

				<div className="container clearfix">
					<div id="logo" className="noborder">
                      <a href="main" onClick={this.playSound} className="standard-logo" 
                         data-dark-logo="images/logo-dark.png"><img src="images/logo-dark.png" alt="Play Blockchain Logo" /></a>
                      <a href="main" onClick={this.playSound} className="retina-logo" 
                         data-dark-logo="images/logo-dark@2x.png"><img src="images/logo-dark@2x.png" alt="Play Blockchain Logo" /></a>
					</div>
					<div id="side-panel-trigger" className="side-panel-trigger d-block d-sm-none"><a href="#"><i className="icon-reorder"></i></a></div>

					<div className="d-sm-none d-none d-md-block clearfix">
						<ul className="nav pbc-nav">
							{this.renderAccountSection()}
							{this.renderGameSection()}
							<li className="nav-item">
                              <a style={Instyle.CursorPointer} className="sound-control"
                                 onClick={this.showSoundDialog}><img src="images/icons/icon-control.png" /></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<MusicAndSound isVisible={showDialog} 
                           closeSoundDialog={this.closeSoundDialog} />
      </header>
    );
  }
}

NewNavBar.defaultProps = {
  history: {},
  navigateTo: null
};

const mapStateToProps = state => ({
  inboxes: state.common.inboxes,
  user: state.common.user,
  games: state.common.games
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(NewNavBar);