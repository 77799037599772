import React from 'react';
import BigNumber from 'bignumber.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import PlayAPI from '../../api/playAPI';
import { setWallet } from '../../actions/common';

class MyWalletAdd extends React.Component {
  constructor(props) {
    super(props);
    this.usdPerEth = 0;
  }
  
  async componentDidMount() {
    const response = await PlayAPI.checkETHValue();
    if (response.status === 200) {
      const body = await response.json();
      if (body.Error === false) {
        const rate = body.Data.rate? body.Data.rate.usd : 0;
        this.usdPerEth = rate;
      }
    }
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  addWallet = async() => {
    const {
      userId, wallet, walletAddresses
    } = this.props.location.state;
    SoundManager.playPlay();
    const response = await PlayAPI.addWallet(userId, wallet.objectId, walletAddresses.length);
    if (response.status === 200) {
      const body = await response.json();
      const newWallet = body.Data;
      
      let balanceList = [];
      let USDBalanceList = [];
      for (let i=0; i<newWallet.WalletAddresses.length; i++) {
        const resBalance = await PlayAPI.getWalletBalance(userId, i);
        if (resBalance.status === 200) {
          const bodyBalance = await resBalance.json();
          if (bodyBalance.Error === false && bodyBalance.TotalRows > 0) {
            const conversionRate = new BigNumber(this.usdPerEth);
            const balance = bodyBalance.Data.balance? bodyBalance.Data.balance : 0;
            balanceList.push(balance);
            USDBalanceList.push(conversionRate.multipliedBy(balance).toFixed());
          }
        }
      }
      newWallet.WalletBalance = balanceList;
      newWallet.USDBalance = USDBalanceList;
      await this.props.setWallet(newWallet);
    }
    
    this.goBack();
  }
  
  render() {
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
			<div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix">

                  <div className="row justify-content-center shadow-sm">
                    <div className="col-lg-4 col-md-6">
                      <div className="card nobg divcenter noradius noborder">
                        <div className="pbc-card-header center position-relative">
                          <div className="btn-close">
                            <a style={Instyle.CursorPointer} onClick={this.goBack}><img src="images/pbc/button-close.png" /></a>
                          </div>
                          <h3>{LanguageData.getText('AccountMyWalletEdit', 'amwe_002')}</h3>
                        </div>
                        <div className="card-body pbc-card-body p-3">
                          <div className="login-form mb-3">
                            {/*<div className="col_full col_mb">
                              <input type="text" id="name-wallet" name="name-wallet" className="form-control not-dark" 
                                     placeholder="Name your wallet" />
                            </div>*/}

                            <div className="col_full col_mb">
                              <select id="wallet-select" name="wallet-select" className="form-control pbc-select-field" defaultValue="ETH">
                                {/*<option value="" disabled>Select Wallet</option>*/}
                                <option value="ETH">ETH</option>
                              </select>
                            </div>
                            <div className="col_full nobottommargin">
                              <button className="button nomargin btn-block pbc-btn" id="change-password" name="change-password" value=""
                                      onClick={this.addWallet}>
                                <span>{LanguageData.getText('AccountMyWalletEdit', 'amwe_010')}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                      
                </div>
              </div>
			</div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functionsWallet', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setWallet: bindActionCreators(setWallet, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyWalletAdd);