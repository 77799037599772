import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import BuyTicketButton from '../../component/button/buyTicketButton';
import ProfilePersonal from '../../component/card/profilePersonal';
import ProfileAddress from '../../component/card/profileAddress';
import ScriptLoader from '../../component/scriptLoader';
import String from '../../helper/string';
import LanguageData  from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import { setUser } from '../../actions/common';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
    };
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  changePassword = async() => {
    SoundManager.playPlay();
    const password = document.getElementById("old-password").value;
    const passwordNew = document.getElementById("new-password").value;
    const passwordCon = document.getElementById("confirm-password").value;
    
    const containsLowercaseAlphabet = String.containsLowercaseAlphabet(passwordNew);
    const containsUppercaseAlphabet = String.containsUppercaseAlphabet(passwordNew);
    const containsNumber = String.containsNumber(passwordNew);
    const containsSpecialCharacter = String.containsSpecialCharacter(passwordNew);
    const haveEnoughCharacters = String.haveEnoughCharacters(passwordNew, 8);
    if (haveEnoughCharacters === false || containsNumber === false || containsLowercaseAlphabet === false || 
        containsUppercaseAlphabet === false || containsSpecialCharacter === false) {
      ToastsStore.warning(LanguageData.getText('webapp', 'webapp_002'));
      return;
    }
    
    if (passwordCon !== passwordNew) {
      ToastsStore.warning(LanguageData.getText('AccountChangePassword', 'acp_007'));
      return;
    }
    
    const { user } = this.props;
    if (user) {
      this.setState({ isProcessing: true });
      try {
        await Auth.changePassword(user, password, passwordNew);
        ToastsStore.warning(LanguageData.getText('webapp', 'webapp_025'));
      }catch(err) {
        ToastsStore.warning(err.message);
      }
      this.setState({ isProcessing: false });
    }else {
      ToastsStore.warning(LanguageData.getText('webapp', 'webapp_026'));
    }
  }
  
  render() {
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
			<div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix">

                  <div className="row justify-content-center shadow-sm">
                    <div className="col-lg-4 col-md-6">
                      <div className="card nobg divcenter noradius noborder">
                        <div className="pbc-card-header center position-relative">
                          <div className="btn-close">
                            <a style={Instyle.CursorPointer}
                               onClick={this.goBack}><img src="images/pbc/button-close.png" /></a>
                          </div>
                          <h3>{LanguageData.getText('AccountChangePassword', 'acp_001')}</h3>
                        </div>
                        <div className="card-body pbc-card-body p-3">
                          <div className="login-form mb-3">
                            <div className="col_full col_mb">
                              <input type="password" id="old-password" name="old-password" className="form-control not-dark"
                                     placeholder={LanguageData.getText('AccountChangePassword', 'acp_009')} />
                            </div>
                            <div className="col_full col_mb">
                              <input type="password" id="new-password" name="new-password" className="form-control not-dark" 
                                     placeholder={LanguageData.getText('AccountChangePassword', 'acp_010')} />
                            </div>

                            <div className="col_full col_mb">
                              <input type="password" id="confirm-password" name="confirm-password" className="form-control not-dark" 
                                     placeholder={LanguageData.getText('AccountChangePassword', 'acp_011')} />
                            </div>
                            <div className="col_full nobottommargin">
                              <BuyTicketButton buttonText={LanguageData.getText('AccountChangePassword', 'acp_012')} 
                                               onPress={this.changePassword} isProcessing={this.state.isProcessing} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>						
                  </div>

                </div>
              </div>
			</div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);