import React from 'react';

import LanguageData from '../../helper/languageData';

const styles = {
  saveButton: {
    minWidth: '285px'
  },
  transparent: {
    color: 'transparent'
  }
};

class InsightSelected extends React.Component {
  handleSave=()=>{
    this.props.handleSave();
  }
  
  renderNumber(item, index) {
    const style = item > 0? {} : styles.transparent;
    return (
      <li key={index} style={style}>{item}</li>
    );
  }
  
  render() {
    const { selectedNumber, numberSlot } = this.props;
    const numbers = []; //selectedNumber.map(this.renderNumber);
    for (let i=0; i < numberSlot; i++) {
      numbers.push(this.renderNumber(selectedNumber[i]? selectedNumber[i] : 0, i));
    }
    return (      
      <div className="psersonal-update-btn-con center">
        <div className="result-bottom-ball">
          <ul>
            {numbers}
          </ul>
        </div>
        <button className="button nomargin pbc-btn" id="btn-update" name="btn-update" value="update" 
                style={styles.saveButton} onClick={this.handleSave}>
          <span>ADD TO FAVOURITE</span>
        </button>
      </div>
    );
  }
}

InsightSelected.defaultProps = {
  numberSlot: 0,
  selectedNumber: [],
  handleSave: ()=>{}
};

export default InsightSelected;