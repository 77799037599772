const UtilsHelper = {
  getUsernameFromEmail(email) {
    const nameMatch = email.match(/^([^@]*)@/);
    const name = nameMatch ? nameMatch[1] : null;
    const timestamp = new Date().getTime();
    return `${name}_${timestamp}`;
  },
  getWalletAddresses(wallet) {
    const walletAddresses = wallet.WalletAddresses.map((address, index) => ({
      usdBalance: wallet.USDBalance[index],
      balance: wallet.WalletBalance[index],
      name: `Wallet ${index + 1}`,
      address,
      enabled: wallet.DefaultIndex === index
    }));
    return walletAddresses;
  },
  getNumberInfo(game) {
    const { GameType } = game;
    const numbersSplit = GameType.split('/');
    try {
      return {
        maxCount: parseInt(numbersSplit[0], 0),
        from: 1,
        to: parseInt(numbersSplit[1], 0)
      };
    } catch (e) {
      return {
        maxCount: 6,
        from: 1,
        to: 45
      };
    }
  },
  getInboxes(inboxes) {
    if (inboxes === null || inboxes.length === 0) {
      return {
        totalCount: 0,
        unreadCount: 0,
        list: inboxes
      };
    }
    const ret = {
      totalCount: inboxes.length,
      unreadCount: 0,
      list: inboxes
    };
    for (let i = 0, ni = inboxes.length; i < ni; i++) {
      const inbox = inboxes[i];
      if (!inbox.IsRead) {
        ret.unreadCount++;
      }
    }
    return ret;
  },
  getSmartPickNumbers(count = 6, to = 45) {
    const numbers = [];
    for (let i = 0; i < count; i++) {
      let value;
      do {
        value = (parseInt(Math.floor(Math.random() * to), 0) % to) + 1;
      } while (numbers.indexOf(value) !== -1);
      numbers.push(value);
    }
    numbers.sort((a, b) => (a - b));
    return numbers;
  },
  getLabelFromYearMonth(date) {
    if (!date) {
      return 'All';
    }
    if (date.month === 0) {
      if (date.year === 0) {
        return 'All';
      }
      return `${date.year}`;
    }
    return `${date.month} / ${date.year}`;
  },
  
  /**
   * Return game from a list based on their id.
   * @param {string} gameId - The id of the game.
   * @param {array} gameList - The list of games from the server.
   * @return {object} The game with the id given.
   */
  getGameById(gameId, gameList) {
    const index = gameList.findIndex((item)=>{return item.objectId === gameId});
    return gameList[index];
  }
};

export default UtilsHelper;
