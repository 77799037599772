import React from 'react';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ResultDetailsRow from '../../component/listItem/resultDetailsRow';
import ScriptLoader from '../../component/scriptLoader';
import DateTime from '../../helper/datetime';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import LanguageData from '../../helper/languageData';
import LanguageImage from '../../helper/languageImage';
import SoundManager from '../../helper/soundManager';
import {DecimalPointsSettings} from '../../config/constant';

const className = {
  undrawn: 'list-unstyled result-list-ball blue-ball',
  drawn: 'list-unstyled result-list-ball red-ball'
};
class ResultDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      data
    } = this.props.location.state;
    this.state = {
      data: data,
      winners: []
    };
  }
  
  async componentDidMount() {
    const {
      data
    } = this.state;
    const response = await PlayAPI.getTopWinner(data.GameId, data.DrawNumber);
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
        if (body.Data.draws) {
          const payout = body.Data.draws[0].payout;
          if (payout.players) {
            this.setState({
              winners: payout.players.winners
            });
          }
        }
      }
    }
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  
  goToHelp=()=>{
    const {
      data
    } = this.props.location.state;
    SoundManager.playNumber();
    this.navigateTo('help', { gameData: data });
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  renderTitle() {
    const {
      data
    } = this.state;
    const draw = LanguageData.getText('ListItemsHistoryListItem', 'lihli_001').replace("<draw number>", data.DrawNumber);
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{data.GameName} : {DateTime.getDateFromMS(data.DrawAt)}, {draw}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center" style={Instyle.WhiteText}
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
                <li><a className="justify-content-center" style={Instyle.WhiteText}
                       onClick={this.goToHelp}><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>	
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderWinningNumber() {
    const {
      data
    } = this.state;
    const isUndrawn = data.WinnerNumbers.includes(0);
    let winNumbers = data.WinnerNumbers.sort(function(a, b){ return a-b; });
    let winningBalls = [];
    for(let i=0; i<winNumbers.length; i++) {
      winningBalls.push(<li key={i}>{isUndrawn? '?' : winNumbers[i]}</li>);
    }
    return (
      <div className="result-content-header">
        <span>{LanguageData.getText('GameResultDetail', 'grd_001')}</span>
        <ul className={isUndrawn? className.undrawn : className.drawn}>
          {winningBalls}
        </ul>
      </div>
    );
  }
  
  renderWinner=(item, index)=>{ 
    const {
      data
    } = this.state;
    return (
      <ResultDetailsRow key={index}
                        data={item}
                        winningNumber={data.WinnerNumbers} />
    );
  }
  
  render() {
    const {
      data,
      winners
    } = this.state;
    const winnerList = winners.map(this.renderWinner);

    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section result-detail-header nopadding nomargin center">
                <h2>{parseFloat(data.JackpotValue).toFixed(DecimalPointsSettings)}ETH</h2>
              </div>
              
              <div className="section pbc-section nobg notoppadding nomargin">
                {this.renderWinningNumber()}
                <div className="result-detail-item clearfix result-detail-th d-none d-sm-none d-md-block">
                  <div className="container">
                    <div className="row clearfix">
                      <div className="col-md-4"><span>{LanguageData.getText('ListItemsWinnerListItem', 'liwli_001')}</span></div>
                      <div className="col-md-6"><span>{LanguageData.getText('ListItemsWinnerListItem', 'liwli_002')}</span></div>
                      <div className="col-md-2"><span>{LanguageData.getText('ListItemsWinnerListItem', 'liwli_003')}</span></div>
                    </div>
                  </div>
                </div>                
                {winners.length > 0? 
                  winnerList : 
                  <div className="row justify-content-center shadow-sm">
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 30}}>
                      <img style={{width: 250}} 
                           src={LanguageImage.getImage("resultCup")} />
                      <span className="text-white"
                            style={{fontSize: 'large'}}>{LanguageData.getText('GameResultDetail', 'grd_002')}</span>
                    </div>
                  </div>
                }
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

export default ResultDetails;