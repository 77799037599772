import React from 'react';
import BigNumber from 'bignumber.js';

import HelpPrize from '../../component/card/helpPrize';
import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import TitleButton from '../../component/button/titleButton';
import ScriptLoader from '../../component/scriptLoader';
import Instyle from '../../theme/instyle';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Prizes from '../../config/prizes';
import {DecimalPointsSettings, LinkPrizeJSON} from '../../config/constant';

class Help extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prizeList: []
    };
  }
  
  componentDidMount() {
    this.getPrizeJSON();
  }
  
  async getPrizeJSON() {
    const date = new Date();
    const extension = '?ver=' + Math.random() + '_' + date.getTime();
    const response = await fetch(LinkPrizeJSON + extension);
    if (response.status === 200) {
      let prizeList = [];
      try {
        const {
          state
        } = this.props.location;
        const game = state? state.gameData : null;
        let gameId = '';
        if (game) {
          gameId = game.GameId? game.GameId : game.objectId;
        }
        const prizeJSON = await response.json();
        const prizeData = prizeJSON[gameId]; 
        if (prizeData) {
          for (let i=0; i<prizeData.matchPrize.length; i++) {
            const jackpot = new BigNumber(game.JackpotValue);
            const prize = jackpot.multipliedBy(prizeData.matchPrize[i].prizePercentage).toFixed(6);
            prizeList.push({match: prizeData.matchPrize[i].match, prize: prize});
          }
        }
      }catch(err) {
        console.log(err);
      }
      
      this.setState({
        prizeList: prizeList
      });
    }
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  renderPrize(item, index) {
    return (
      <HelpPrize key={index}
                 rank={index + 1}
                 match={item.match}
                 prize={item.prize} />
    );
  }
  
  renderPrizeSection() {  
    const { prizeList } = this.state;
    let prizes = prizeList.map(this.renderPrize);
    return (
      <div className="col-md-6 col-lg-6">
        <div className="prizes-wrap mb-5">
          <h3 className="text-white mb-3">{LanguageData.getText('GameHelp', 'gh_002')}</h3>
          {prizes}
        </div>
      </div>
    );
  }
  
  renderHelp() {
    return (
      <div className="col-md-6 col-lg-6">
        <div className="prizes-wrap mb-5">
          <h3 className="text-white mb-3">{LanguageData.getText('GameHelp', 'gh_001')}</h3>
          <div className="help-quest">
            <div id="accordion" role="tablist">
              <div className="card">
                <div className="card-header" role="tab" id="help-quest-01">
                  <h5 className="mb-0">
                    <a className="collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" 
                       href="#quest-01" aria-expanded="true" aria-controls="quest-01">
                      <span>{LanguageData.getText('GameHelp', 'gh_004')}</span>
                      <i className="toggle-icon"><img src="images/pbc/help-arrow.png" /></i>
                    </a>
                  </h5>
                </div>

                <div id="quest-01" className="collapse" role="tabpanel" aria-labelledby="quest-01" data-parent="#accordion">
                  <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" role="tab" id="help-quest-02">
                  <h5 className="mb-0">
                    <a className="collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" 
                       href="#quest-02" aria-expanded="false" aria-controls="quest-02">
                      <span>{LanguageData.getText('GameHelp', 'gh_005')}</span>
                      <i className="toggle-icon"><img src="images/pbc/help-arrow.png" /></i>
                    </a>
                  </h5>
                </div>
                <div id="quest-02" className="collapse" role="tabpanel" aria-labelledby="quest-02" data-parent="#accordion">
                  <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" role="tab" id="help-quest-03">
                  <h5 className="mb-0">
                    <a className="collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" href="#quest-03" 
                       aria-expanded="false" aria-controls="quest-03">
                      <span>{LanguageData.getText('GameHelp', 'gh_006')}</span>
                      <i className="toggle-icon"><img src="images/pbc/help-arrow.png" /></i>
                    </a>
                  </h5>
                </div>
                <div id="quest-03" className="collapse" role="tabpanel" aria-labelledby="quest-03" data-parent="#accordion">
                  <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" role="tab" id="help-quest-04">
                  <h5 className="mb-0">
                    <a className="collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" href="#quest-04" 
                       aria-expanded="false" aria-controls="quest-04">
                      <span>{LanguageData.getText('GameHelp', 'gh_007')}</span>
                      <i className="toggle-icon"><img src="images/pbc/help-arrow.png" /></i>
                    </a>
                  </h5>
                </div>
                <div id="quest-04" className="collapse" role="tabpanel" aria-labelledby="quest-04" data-parent="#accordion">
                  <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  render() {
    const {
      state
    } = this.props.location;
    const game = state? state.gameData : null;
    const name = game? game.GameName : '';
    const type = game? game.GameType : '';
    const jackpot = game? game.JackpotValue : 0;
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          
          <section id="page-header" className="pbc-border-bottom mb-3">
			<div className="container clearfix header-container">
              <div className="row clearfix">
                <div className="col-md-9">
                  {game?
                    <span className="award-title">{name} : <span className="award-amount">{parseFloat(jackpot).toFixed(DecimalPointsSettings)}ETH</span></span>
                    : <span className="award-title">{LanguageData.getText('webapp', "webapp_005")}</span>
                  }
                </div>
                <div className="col-md-3">
                  <ul className="top-nav-list">
                    <TitleButton icon={"images/pbc/back.png"} text={'Back'} onClick={this.goBack} />
                    {/*<TitleButton icon={"images/pbc/question.png"} text={'Help'} />*/}	
                  </ul>
                </div>
              </div>
			</div>
          </section>
          
          <section id="content" className="bg-transparent">
			<div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">                  
                  <div className="row clearfix">
                    {this.renderPrizeSection()}
                    {this.renderHelp()}
                  </div>
                </div>
              </div>
            </div>
          </section>
              
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

export default Help;