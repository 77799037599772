import React from 'react';
import countryjs from 'country-js';
import csc from 'country-state-city';

import LanguageData from '../../helper/languageData';
import { CountryList } from '../../config/countries';

class ProfileAddress extends React.Component {
  constructor(props) {
    super(props);
    
    const countries = csc.getAllCountries();
    this.countryList = [];
    for (let i=0; i<countries.length; i++) {
      this.countryList.push(<option key={'country' + i} value={countries[i].name}>{countries[i].name}</option>);
    }
    
    const defCountry = props.attributes['custom:country']? props.attributes['custom:country'] : "";
    const country = countries.find((item)=>{ return item.name.toLowerCase() === defCountry.toLowerCase(); });
    this.originalCountry = country? country.name : "";
    
    let stateList = [];
    if (country) {
      const list = csc.getStatesOfCountry(country.id);
      for (let i=0; i<list.length; i++) {
        stateList.push(<option key={'state' + i} value={list[i].name}>{list[i].name}</option>);
      }
    }
    
    this.state = {
      stateList: stateList
    };
  }
  
  onSelectCountry=()=>{
    const selectedCountry = document.getElementById("personal-country").value;
    const country = csc.getAllCountries().find((item)=>{ return item.name.toLowerCase() === selectedCountry.toLowerCase(); });
    let stateList = [];
    if (country) {
      const list = csc.getStatesOfCountry(country.id);
      for (let i=0; i<list.length; i++) {
        stateList.push(<option key={'state' + i} value={list[i].name}>{list[i].name}</option>);
      }
    }
    this.setState({
      stateList: stateList
    });
  }
  
  render() {    
    const {
      stateList
    } = this.state;
    const {
      attributes
    } = this.props;
    const defCity = attributes['custom:city']? attributes['custom:city'] : "";
    const defState = attributes['custom:state']? attributes['custom:state'] : "";
    
    return (
      <React.Fragment>
        <h3>{LanguageData.getText('AccountProfile', "ap_014")}</h3>
        <div className="row">
          <div className="col-md-12 mb-3">
            <input type="text" id="personal-address" name="personal-address" className="form-control not-dark"
                   defaultValue={attributes.address} placeholder={LanguageData.getText('AccountProfile', "ap_014")} />
          </div>
          <div className="col-md-6 mb-3">
            <input type="text" id="personal-city" name="personal-city" className="form-control not-dark"
                   defaultValue={defCity} placeholder={LanguageData.getText('AccountProfile', "ap_015")} />
          </div>
          <div className="col-md-6 mb-3">
            {/*<input type="text" id="personal-state" name="personal-postcode" className="form-control not-dark"
                   defaultValue={defState} placeholder={LanguageData.getText('AccountProfile', "ap_016")} />*/}
            <select id="personal-state" name="personal-country" className="form-control pbc-select-field" defaultValue={defState}>
              <option value="" disabled>{LanguageData.getText('DialogStateDialog', "dsd_001")}</option>
              {stateList}
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <input type="text" id="personal-postcode" name="personal-postcode" className="form-control not-dark"
                   defaultValue={attributes.zoneinfo} placeholder={LanguageData.getText('AccountProfile', "ap_017")} />
          </div>
          <div className="col-md-6 mb-3">
            <select id="personal-country" name="personal-country" className="form-control pbc-select-field" defaultValue={this.originalCountry}
                    onChange={this.onSelectCountry}>
              <option value="" disabled>{LanguageData.getText('DialogCountryDialog', "dcd_002")}*</option>
              {this.countryList}
            </select>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProfileAddress.defaultProps = {
  attributes: {},
};

export default ProfileAddress;