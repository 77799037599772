import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import PINInput from '../../component/card/pinInput';
import SuccessAction from '../../component/card/successAction';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import LanguageData from '../../helper/languageData';
import Instyle from '../../theme/instyle';

class ChangePincode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 0, // 0=> Enter new PIN, 1=> Enter confirm PIN, 2=> Sucess
      newPincode: ''
    };
  }
  
  goBack=()=>{
    this.props.history.goBack();
  }
  
  onPressNext=(pincode)=>{
    const {
      state,
    } = this.state;
    if (state === 0) {
      if (pincode === '') {
        ToastsStore.warning(LanguageData.getText('AccountChangePINCode', 'acpc_009'));
        return;
      }
      this.setState({
        state: 1,
        pincode: pincode
      });
    }else {
      if (this.state.pincode === pincode) {
        this.updatePIN(pincode);
      }else {
        ToastsStore.warning(LanguageData.getText('AccountChangePINCode', 'acpc_002'));
        this.setState({
          state: 0,
          pincode: ''
        });
      }
    }
  }
  
  updatePIN = async(pincode) => {
    const user = this.props.user;
    if (user) {
      const response = await PlayAPI.setUserPINCode(user.username, pincode);
      if (response.status === 200) {
        const body = await response.json();
        this.setState({
          state: 2
        });
      }
    }else {
      ToastsStore.warning(LanguageData.getText('webapp', 'webapp_026'));
    }
  }
  
  onFinished=()=>{
    this.props.history.push('/main');
  }
  
  renderPopupByState() {
    switch(this.state.state) {
      case 1: 
        return (
          <PINInput title={LanguageData.getText('AccountChangePINCode', 'acpc_001')}
                    text={LanguageData.getText('AccountChangePINCode', 'acpc_006')}
                    buttonText={LanguageData.getText('InitialIntro', 'ii_009')}
                    onClose={this.goBack}
                    onSubmit={this.onPressNext} />
        );
      default:
        return (
          <PINInput title={LanguageData.getText('AccountChangePINCode', 'acpc_001')}
                    text={LanguageData.getText('AccountChangePINCode', 'acpc_007')}
                    buttonText={LanguageData.getText('InitialIntro', 'ii_009')}
                    onClose={this.goBack}
                    onSubmit={this.onPressNext} />
        );
    }
  }
  
  render() {
    const {
      state
    } = this.state;
    if (state === 2) {
      return (
        <SuccessAction title={LanguageData.getText('AccountSetupFingerprint', 'asfp_002')}
                       text={LanguageData.getText('AccountChangePINCode', 'acpc_008')}
                       buttonText={LanguageData.getText('GamePlaySuccess', 'gps_001')}
                       buttonFunction={this.onFinished} />
      );
    }else {
      return (
        <React.Fragment>
          <div id="wrapper" className="clearfix bg-transparent">
            <section id="content" className="bg-transparent">
              <div className="content-wrap pbc-wrapper nopadding nomargin">
                <div className="section nobg full-screen nopadding nomargin">
                  <div className="container-fluid vertical-middle divcenter clearfix">
                    <div className="row justify-content-center shadow-sm">
                      {this.renderPopupByState()}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div id="gotoTop" className="icon-angle-up"></div>
          <ToastsContainer store={ToastsStore} 
                           position={ToastsContainerPosition.TOP_RIGHT} />
          <ScriptLoader scriptArray={[
            {id: 'jquery', src:"./js/jquery.js"},
            {id: 'plugins', src:"./js/plugins.js"},
            {id: 'functions', src:"./js/functions.js"}
          ]} />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  user: state.common.user
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps) (ChangePincode);