import React from 'react';

import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';

class SettingsButton extends React.Component {
  onPress=()=>{
    const {
      onClick
    } = this.props;
    SoundManager.playNumber();
    onClick();
  }
  
  render() {
    const {
      text,
      onClick,
      isLogout
    } = this.props;
    const classname = isLogout? "logout" : "";
    return (
      <li className={classname}>
		<a className="justify-content-between" style={Instyle.WhiteText}
           onClick={this.onPress}>
          <span>{text}</span>
          {isLogout? 
          <span className="button nomargin pbc-btn" id="login-form-submit" name="login-form-submit" value="login">
			<i className="icon-signout"></i>
          </span>: <i className="toggle-icon"><img src="images/pbc/help-arrow.png" /></i>}
        </a>
      </li>
    );
  }
}

SettingsButton.defaultProps = {
  onClick: ()=>{},
  text: '',
  isLogout: false
};

export default SettingsButton;