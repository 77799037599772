import React from 'react';

import GameCountdown from '../module/gameCountdown';
import DateTime from '../../helper/datetime';
import String from '../../helper/string';
import SoundManager from '../../helper/soundManager';
import LanguageData from '../../helper/languageData';
import {DecimalPointsSettings} from '../../config/constant';

class GameListItem extends React.Component {
  openGame=()=>{
    const {
      gameData,
      openGame
    } = this.props;
    const {
      Status,
      DrawAt
    } = gameData;
    
    const havePassed = DateTime.havePassed(DrawAt);
    const disabled = havePassed === true || Status !== "1"? true : false;
    if (!disabled) {
      SoundManager.playPlay();
      openGame(gameData);
    }else {
      SoundManager.playDisable();
    }
  }
  
  openResult=()=>{
    const {
      gameData,
      openResult
    } = this.props;
    SoundManager.playNumber();
    openResult(gameData);
  }
  
  openInsight=()=>{
    const {
      gameData,
      openInsight
    } = this.props;
    SoundManager.playNumber();
    openInsight(gameData);
  }
  
  renderCountdown() {
    const {
      index,
      gameData
    } = this.props;
    const {
      DrawAt
    } = gameData;
    const countdown = DateTime.getCountBreakdown(gameData.Status === '1'? DrawAt : 0);
    const havePassed = DateTime.havePassed(gameData.Status === '1'? DrawAt : 0);
    const statusText = havePassed? 'gm_002' : 'gm_001';
    return (
      <React.Fragment>
        <span className="item-count-title">{LanguageData.getText('GameMain', statusText)}</span>
        <GameCountdown
          index={index}
          countdown={countdown} />
      </React.Fragment>
    );
  }
  
  render() {
    const {
      index,
      gameData
    } = this.props;
    const {
      GameName,
      GameType,
      GameIcon,
      JackpotValue,
      DrawNumber,
      ImagePath,
      Status,
      DrawAt
    } = gameData;
    const resultId = "result-game-0" + (index + 1);
    const playId = "play-game-0" + (index + 1);    
    const havePassed = DateTime.havePassed(DrawAt);
    const disabled = havePassed === true || Status !== "1"? true : false;
    const buttonStyle = disabled? "pbc-btn-disable" : "pbc-btn";
    const backgroundImage = {backgroundImage: "url(" + ImagePath + ")", backgroundPosition: "center"};
    return (
      <div className="col-md-6 col-lg-12">
          <div className="game-item-container">
              <div className="game-item-header">
                <figure style={backgroundImage}>>
                  <img src={GameIcon} alt={GameName} />
                </figure>
              </div>
              <div className="game-item-body">
                  <div className="game-item-wrap">
                      <div className="game-item-left">
                        <h4 className="item-jackpot">{LanguageData.getText('GameMain', 'gm_006')}</h4>
                        <span className="item-amount">{parseFloat(JackpotValue).toFixed(DecimalPointsSettings)}ETH</span>
                        <span className="item-draw-number">{LanguageData.getText('webapp', 'webapp_010')}: {DrawNumber}</span>
                      </div>
                      <div className="game-item-right">
                        {this.renderCountdown()}
                      </div>
                  </div>
              </div>
              <div className="game-item-footer">
                  <div className="game-footer-wrap">
                      <div className="game-footer-half">
                          <a className="button nomargin btn-block pbc-btn pbc-btn-pink pl-0 pr-0"
                             onClick={this.openInsight}>
                            <span>{LanguageData.getText('GameMain', 'gm_007')}</span>
                          </a>
                      </div>
                      <div className="game-footer-half">
                          <a className="button nomargin btn-block pbc-btn pbc-btn-blue pl-0 pr-0"
                              onClick={this.openResult}>
                            <span>{LanguageData.getText('GameMain', 'gm_004')}</span>
                          </a>
                      </div>
                  </div>
                  <div className="game-footer-wrap">
                  <div className="game-footer-full">
                      <a className={"button nomargin btn-block pbc-btn pl-0 pr-0 " + buttonStyle}
                         onClick={this.openGame}>
                        <span>{LanguageData.getText('GameMain', 'gm_005')}</span>
                      </a>
                  </div>
                  </div>
              </div>
          </div>	
      </div>
    );
  }
}

GameListItem.defaultProps = {
  index: 0,
  gameData: {},
  openResult: null,
  openGame: null,
  openInsight: null,
};

export default GameListItem;