import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';

const styles = {
  wrapper: {
    marginTop: '-10px'
  },
  topRow: {
    marginBottom: '25px'
  },
  title: {
    fontSize: 20,
    color: '#FFF',
  },
  image: {
    width: '15%',
    height: 'auto'
  },
  text: {
    marginLeft: '15px',
    fontSize: 15,
    color: '#FFF'
  },
};

class NotificationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendNotification: true
    };
    this.originalSendNotification = true;
  }
  
  async componentDidMount() {
    const {
      user
    } = this.props;
    if (user) {
      const response = await PlayAPI.getUserDevice(user.username);
      if (response.status === 200) {
        const body = await response.json();
        if (!body.Error) {
          for (let i=0; i<body.Data.length; i++) {
            if (body.Data[i].Type === 'W') {
              const isSwitchOff = body.Data[i].SwitchOff? body.Data[i].SwitchOff : false;
              this.originalSendNotification = !isSwitchOff;
              this.setState({
                sendNotification: !isSwitchOff
              });
            }
          }
        }
      }
    }
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;    
    history.goBack();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  /**
   * Handles event of user changing send notfication state
   **/
  changeSend=(evt)=>{
    const {
      sendNotification
    } = this.state;
    SoundManager.playNumber();
    this.setState({
      sendNotification: !sendNotification
    });
  }
  
  
  /**
   * Submit the user's settings to backend
   **/
  handleConfirm = async() => {
    const { sendNotification } = this.state;
    const { user } = this.props;
    SoundManager.playNumber();
    if (this.originalSendNotification !== sendNotification && user) {
      await PlayAPI.switchNotification(user.username, !sendNotification);
    }
    this.goBack();    
  }
  
  renderTitle() {
    return (
      <section id="page-header" className="pbc-border-bottom mb-3">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('AccountNotificationSettings', 'ans_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center"style={Instyle.WhiteText} 
                       onClick={this.handleConfirm}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  render() {
    const {
      sendNotification
    } = this.state;    
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">                    
                    <div className="col-md-6 col-lg-6">
                      <div className="setting-wrap mb-5 pt-4 setting-left" 
                           style={styles.wrapper}>
                        <div className="list-unstyled setting-list">
                          <div>
                            <div style={styles.topRow}>
                              <span style={styles.title}>{LanguageData.getText('AccountNotificationSettings', 'ans_002')}</span>
                              <div className="wallet-switch">
                                <input id="notificationSwitch" className="switch-toggle switch-rounded-mini switch-toggle-round"
                                       type="checkbox" checked={sendNotification} onChange={this.changeSend} />
                                <label htmlFor="notificationSwitch" className="mb-1"></label>
                              </div>
                            </div>
                            <div>
                              <img src="images/pbc/envelope_new.png" style={styles.image} />
                              <span style={styles.text}>{LanguageData.getText('AccountNotificationSettings', 'ans_003')}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);