import React from 'react';
import BigNumber from 'bignumber.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import ScriptLoader from '../../component/scriptLoader';
import SendETHForm from '../../component/card/sendETHForm';
import SendETHConfirm from '../../component/card/sendETHConfirm'
import SendEHTSuccess from '../../component/card/sendETHSuccess';
import PlayAPI from '../../api/playAPI';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import { setWallet } from '../../actions/common';

class SendETH extends React.Component {
  constructor(props) {
    super(props);
    const {
      data
    } = this.props.location.state;
    this.state = {
      data: data,
      sendState: 0, //0 => Form, 1 => Confirm, 2 => Success
      conversionValue: 0,
      gasValue: 0,
      sendValue: 0,
      sendAddress: '',
      isProcessing: false
    };
  }
  
  componentDidMount() {
    this.getConversionValue();
    this.getGasEstimate();
  }
  
  async getConversionValue() {
    const response = await PlayAPI.checkETHValue();
    if (response.status === 200) {
      const body = await response.json();
      if (body.Error === false) {
        const rate = body.Data.rate? body.Data.rate.usd : 0;
        this.setState({
          conversionValue: rate
        });
      }
    }
  }
  
  async getGasEstimate() {
    const response = await PlayAPI.getGasEstimate();
    if (response.status === 200) {
      const body = await response.json();
      if (body.Error === false) {
        this.setState({
          gasValue: body.Data.gas.eth
        });
      }
    }
  }
  
  isAffordable(amount) {
    const {
      gasValue
    } = this.state;
    const {
      balance,
    } = this.state.data;
    const usableBalance = new BigNumber(balance).minus(gasValue).toFixed();
    const amountBig = new BigNumber(amount);
    return amountBig.isLessThanOrEqualTo(usableBalance);
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  switchToConfirm=()=>{
    SoundManager.playPlay();
    const sendValue = document.getElementById('send-amount').value;
    const sendAddress = document.getElementById('to-wallet').value;
    if (sendAddress === '') {
      ToastsStore.warning(LanguageData.getText('AccountSendETH', 'ase_002'));
      return;
    }
    
    if (isNaN(sendValue) || sendValue === '') {
      ToastsStore.warning(LanguageData.getText('AccountSendETH', 'ase_012'));
      return;
    }
    
    if (this.isAffordable(sendValue) === false) {
      ToastsStore.warning(LanguageData.getText('AccountSendETH', 'ase_003'));
      return;
    }
    
    this.setState({
      sendState: 1,
      sendValue: sendValue,
      sendAddress: sendAddress
    });
  }
  
  revertToForm=()=>{
    SoundManager.playDisable();
    this.setState({
      sendState: 0,
    });
  }
  
  sendETH = async() => {
    const { wallet } = this.props;
    SoundManager.playPlay();
    if (wallet === null) {
      ToastsStore.warning(LanguageData.getText('AccountSendETH', 'ase_013'));
      return;
    }
    
    this.setState({
      isProcessing: true
    });
    
    const { data, sendValue, sendAddress } = this.state;
    const addressList = wallet.WalletAddresses;
    const index = addressList.indexOf(data.address);
    const response = await PlayAPI.sendETH(wallet.UserIds[0], index, sendAddress, sendValue);
    const body = await response.json();
    if (response.status === 200 && !body.Error) {
      const data = body.Data;
      if (data.error) {
        ToastsStore.warning('Error: ' + body.error.message);
      }else {
        this.setState({
          sendState: 2,
          isProcessing: false
        });
      }
    }else {
      ToastsStore.warning('Error: ' + body.ErrorDesc);
    }
    
    this.setState({
      isProcessing: false
    });
  }
  
  refreshWallet = async() => {
    let { wallet } = this.props;
    const userId = wallet.UserIds[0];
    
    if (wallet !== null) {
       this.setState({
        isProcessing: true
      });
      
      let balanceList = [];
      let USDBalanceList = [];
      for (let i=0; i<wallet.WalletAddresses.length; i++) {
        const resBalance = await PlayAPI.getWalletBalance(userId, i);
        if (resBalance.status === 200) {
          const bodyBalance = await resBalance.json();
          if (bodyBalance.Error === false && bodyBalance.TotalRows > 0) {
            const conversionRate = new BigNumber(this.state.conversionValue);
            const balance = bodyBalance.Data.balance? bodyBalance.Data.balance : 0;
            balanceList.push(balance);
            USDBalanceList.push(conversionRate.multipliedBy(balance).toFixed());
          }
        }
      }
      wallet.WalletBalance = balanceList;
      wallet.USDBalance = USDBalanceList;
      await this.props.setWallet(wallet);
      
      this.props.history.push('mywallet');
    }
  }
  
  renderByState() {
    const {
      data,
      sendState, 
      conversionValue,
      gasValue,
      sendValue,
      sendAddress,
      isProcessing
    } = this.state;
    const {
      name,
      address,
      balance
    } = data;
    switch(sendState) {
      case 1:
        return (
          <SendETHConfirm fromName={name}
                          fromAddress={address}
                          toAddress={sendAddress}
                          amountETH={sendValue}
                          feeETH={gasValue}
                          conversionValue={conversionValue}
                          isProcessing={isProcessing}
                          onCancel={this.revertToForm}
                          onConfirm={this.sendETH}
                          onClose={this.goBack} />
        );
      case 2:
        return (
          <SendEHTSuccess isProcessing={isProcessing}
                          onClose={this.refreshWallet} />
        );
      default:
        const usableBalance = new BigNumber(balance).minus(gasValue).toFixed();
        return (
          <SendETHForm walletName={name}
                       walletAddress={address}
                       walletBalance={usableBalance}
                       conversionValue={conversionValue}
                       onClose={this.goBack}
                       onConfirm={this.switchToConfirm}/>
        );
    }
  }
  
  render() {
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
			<div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix">
                  <div className="row justify-content-center shadow-sm">                          
                    {this.renderByState()}
                  </div>
                </div>
              </div>
			</div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functionsWallet', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wallet: state.common.wallet
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setWallet: bindActionCreators(setWallet, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendETH);