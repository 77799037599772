import React from 'react';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import RevealSeedWordsInput from '../../component/card/revealSeedWordsInput';
import RevealSeedWordsReveal from '../../component/card/revealSeedWordsReveal';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import Utils from '../../helper/utils';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';

class RevealSeedWords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seedWords: '',
      screenState: 0 // 0 => Input , 1 => Reveal
    };
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  checkPIN = async(pincode) => {
    const {
      userId
    } = this.props.location.state;
    const response = await PlayAPI.checkPINCode(userId, pincode);
    if (response.status === 200) {
      const body = await response.json();
      if (body.Data === true) {
        this.getSeedWords();
      }else {
        ToastsStore.warning(LanguageData.getText('AccountRevealSeedWords', 'arsw_011'));
      }
    }
  }
  
  async getSeedWords() {
    const {
      userId,
      mnemonic
    } = this.props.location.state;
    const response = await PlayAPI.exportMnemonic(userId, mnemonic);
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
        this.setState({
          seedWords: body.Data.plainText,
          screenState: 1
        });
      }else {
        ToastsStore.warning('Error: ' + body.ErrorDesc);
      }
    }
  }
  
  copySeedWords=()=>{
    const {
      seedWords
    } = this.state;
    SoundManager.playPlay();
    Utils.copyToClipboard(seedWords);
    ToastsStore.warning(LanguageData.getText('AccountRevealSeedWords', 'arsw_009'));
  }
  
  render() {
    const {
      seedWords,
      screenState
    } = this.state;
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
			<div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix">
                  <div className="row justify-content-center shadow-sm">
                    {screenState === 0?
                      <RevealSeedWordsInput onClose={this.goBack} 
                                            checkPIN={this.checkPIN} /> :
                      <RevealSeedWordsReveal seedWords={seedWords}
                                             copyText={this.copySeedWords}
                                             onClose={this.goBack} />
                    }
                  </div>
                </div>
              </div>
			</div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functionsWallet', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

export default RevealSeedWords;