import React from 'react';
import { connect } from 'react-redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import SmartPickListSection from '../../component/card/smartPickListSection';
import SmartPickDetails from '../../component/card/smartPickDetails';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import Utils from '../../helper/utils';
import LanguageData from '../../helper/languageData';
import Instyle from '../../theme/instyle';

class SmartPick extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditName: false,
      selectAll: false,
      selectList: [],
      openIndex: 0,
      list: []
    };
  }
  
  async componentDidMount() {
    const {
      user,
      games,
    } = this.props;
    if (user && games) {
      const response = await PlayAPI.getUserSmartPick(user.username);
      if (response.status === 200) {
        const body = await response.json();
        if (!body.Error) {
          let data = body.Data;
          for (let i=0; i<data.length; i++) {
            const gameData = Utils.getGameDataByCode(games, data[i].GameId);
            data[i].TotalBalls = gameData.TotalBalls;
          }
          this.setState({
            list: data
          });
        }
      }
    }
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    history.goBack();
  }
  
  goToHelp=()=>{
    this.navigateTo('help');
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  renderTitle() {
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('AccountSmartPick', 'asp_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list d-flex align-items-center justify-content-end">
                <li><a className="justify-content-center" style={Instyle.WhiteText}
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
                {/*<li><a className="justify-content-center" style={Instyle.WhiteText}
                       onClick={this.goToHelp}><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>*/}	
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
			<div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container">
                  <div className="row">
                    <SmartPickListSection itemList={this.state.list} />
                    <SmartPickDetails />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functionsWallet', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user,
  games: state.common.games
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps) (SmartPick);