import React from 'react';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import SettingsButton from '../../component/button/settingsButton';
import ScriptLoader from '../../component/scriptLoader';
import SaveData from '../../helper/saveData';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import { LINK_LANGUAGE_JSON } from '../../config/constant';

class LanguageSettings extends React.Component {
  goBack=()=>{
    const {
      history
    } = this.props;    
    SoundManager.playNumber();
    history.goBack();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  changeToEnglish=()=>{
    this.changeLanguage('en');
  }
  
  changeToChinese=()=>{
    this.changeLanguage('zh');
  }
  
  async changeLanguage(languageCode) {
    SaveData.setLanguageSettings(languageCode);
    const savedLanguage = await SaveData.setLanguageSettings(languageCode);
    const response = await fetch(LINK_LANGUAGE_JSON[languageCode]);
    if (response.status === 200) {
      const body = await response.json();
      LanguageData.setJson(body);
      this.navigateTo('main');
    }
  }
  
  renderTitle() {
    return (
      <section id="page-header" className="pbc-border-bottom mb-3">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('AccountLanguageSettings', 'als_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center"style={Instyle.WhiteText} 
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">                    
                    <div className="col-md-6 col-lg-6">
                      <div className="mb-5 pt-4 setting-left">
                        <ul className="list-unstyled setting-list">
                    
                          <SettingsButton text={LanguageData.getText('AccountLanguageSettings', 'als_002')} onClick={this.changeToEnglish} />
                          <SettingsButton text={LanguageData.getText('AccountLanguageSettings', 'als_003')} onClick={this.changeToChinese} />
                          
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

export default LanguageSettings;