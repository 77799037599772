import React from 'react';
import Auth from '@aws-amplify/auth';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Instyle from '../../theme/instyle';
import ScriptLoader from '../../component/scriptLoader';
import String from '../../helper/string';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  onChangeEmail=(evt)=>{
    this.setState({
      email: evt.target.value
    });
  }
  
  goToResetPassword(email) {
    this.props.history.push('resetpassword', {
      email: email
    });
  }
  
  handleSubmit = async() => {
    const { email } = this.state;
    SoundManager.playPlay();
    if (String.isEmailFormat(email)) {
      try {
        await Auth.forgotPassword(email);
        this.goToResetPassword(email);
      }catch(err) {
        ToastsStore.warning('Error: ' + err.message);
      }
    }else {
      ToastsStore.warning(LanguageData.getText('webapp', 'webapp_001'));
    }
  }
  
  render() {
    const {
      email
    } = this.state;
    return (
      <React.Fragment>
        <div id="wrapper" className="clearfix bg-transparent">
          <section id="content" className="bg-transparent">
			<div className="content-wrap pbc-wrapper nopadding nomargin">
              <div className="section nobg full-screen nopadding nomargin">
                <div className="container-fluid vertical-middle divcenter clearfix">
                  <div className="row justify-content-center shadow-sm">
                    <div className="col-lg-3">
                        <div className="card nobg divcenter noradius noborder">
                          <div className="pbc-card-header center position-relative">
                            <div className="btn-close" style={Instyle.CursorPointer}
                                 onClick={this.goBack}>
                              <img src="images/pbc/button-close.png" />
                            </div>
                            <h3>{LanguageData.getText('InitialForgotPassword', 'ifp_005')}</h3>
                          </div>
                          <div className="card-body pbc-card-body">
                            <figure className="center verify-img mb-0">
                              <img src="images/pbc/treasure-box.png" />
                            </figure>
                            <div className="center mb-3">
                              <span className="text-white">{LanguageData.getText('InitialForgotPassword', 'ifp_002')}</span>
                            </div>
                            <form className="login-form mb-3">
                              <div className="col_full col_mb">
                                <input type="email" id="login-form-email" name="login-form-email" className="form-control not-dark" value={email} 
                                       placeholder={LanguageData.getText('InitialForgotPassword', 'ifp_003')} onChange={this.onChangeEmail} />
                              </div>
                            </form>
                            <button className="button nomargin btn-block pbc-btn" id="login-form-submit" name="login-form-submit"
                                    value="login" onClick={this.handleSubmit}>
                              <span>{LanguageData.getText('InitialForgotPassword', 'ifp_004')}</span>
                            </button>
                          </div>				
                        </div>
                    </div>						
                  </div>
                </div>
              </div>
			</div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>        
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

export default ForgotPassword;