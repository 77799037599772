import React from 'react';
import countryjs from 'country-js';

import LanguageData from '../../helper/languageData';
import {CountryList} from '../../config/countries';

class ProfilePersonal extends React.Component {
  render() {
    const {
      attributes,
      changePassword
    } = this.props;
    
    let prefixList = [];
    for (let i=0; i<CountryList.length; i++) {
      const country = countryjs.search(CountryList[i]);
      prefixList.push(<option key={'country' + i} value={'+' + country[0].phone}>{CountryList[i]} ({'+' + country[0].phone})</option>);
    }
    const defPrefix = attributes['custom:prefix']? attributes['custom:prefix'] : '';
    const defPhone = attributes.phone_number? attributes.phone_number.replace(attributes['custom:prefix'], '') : '';
    return (
      <React.Fragment>
        <h3>Personal Detail</h3>
        <div className="row">
          <div className="col-md-6 mb-3">
            <input type="text" id="personal-first-name" name="personal-first-name" className="form-control not-dark" 
                   defaultValue={attributes.given_name} placeholder={LanguageData.getText('AccountProfile', "ap_008") + '*'} />
          </div>
          <div className="col-md-6 mb-3">
            <input type="text" id="personal-last-name" name="personal-last-name" className="form-control not-dark" 
                   defaultValue={attributes.family_name} placeholder={LanguageData.getText('AccountProfile', "ap_009") + '*'} />
          </div>
          <div className="col-md-12 mb-3">
            <input type="email" id="personal-email" name="personal-email" className="form-control not-dark" 
                   defaultValue={attributes.email} placeholder={LanguageData.getText('AccountProfile', "ap_010") + '*'} />
          </div>
          <div className="col-md-6 mb-3">
            <select id="personal-mobile-prefix" name="personal-country" className="form-control pbc-select-field" defaultValue={defPrefix}>
              <option value="" disabled>Select Prefix</option>
              {prefixList}
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <input type="tel" id="personal-mobile" name="personal-mobile" className="form-control not-dark" 
                   defaultValue={defPhone} placeholder={LanguageData.getText('AccountProfile', "ap_011")} />
          </div>
          
          <div className="col-md-12 mb-3">
            <div className="personal-pass-wrap">
              <button className="button nomargin pbc-btn pbc-btn-pink" id="btn-change" name="btn-change" value="login"
                      onClick={changePassword}>
                <span>{LanguageData.getText('AccountProfile', "ap_013")}</span>
              </button>
              <div style={{background:'rgba(0,0,0,.3)',border: 0,color:'#FFF',borderRadius:'10px', padding: '10px', color: '#F9F241'}}>{LanguageData.getText('AccountProfile', "ap_012")}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProfilePersonal.defaultProps = {
  attributes: {},
  changePassword: () => {}
};

export default ProfilePersonal;