import React from 'react';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import TermsAndConditionsSection from '../../component/dialog/termsAndConditionsSection';
import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import { TC_JSON_FILE } from '../../config/constant';

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      sections: []
    }
  }
  
  async componentDidMount() {
    const {
      jsonAddress
    } = this.props;
    const response = await fetch(TC_JSON_FILE);
    if (response.status === 200) {
      const body = await response.json();
      this.setState({
        title: body.title,
        description: body.description,
        sections: body.sections
      });
    }
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  renderSections(item, index) {
    return (
      <TermsAndConditionsSection key={index}
                                 item={item} />
    );
  }
  
  renderTitle() {
    return (
      <section id="page-header" className="pbc-border-bottom mb-3">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('AccountTermsAndConditions', 'atac_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center"style={Instyle.WhiteText} 
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  render() {
     const {
      title,
      description,
      sections
    } = this.state;
    const sectionList = sections.map(this.renderSections);
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
         <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="clearfix">
                    <h4 className="dialog-description">{description}</h4>
                    {sectionList}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"}
        ]} />
      </React.Fragment>
    );
  }
}

export default TermsAndConditions;