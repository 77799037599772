import React from 'react';
import moment from 'moment';

import DateTime from '../../helper/datetime';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import IconLoader from '../../component/card/iconLoader';
import {DecimalPointsSettings} from '../../config/constant';

class ResultSection extends React.Component {
  render() {
    const {
      Items,
      WinInfo,
      DrawResult
    } = this.props.data;
    if (WinInfo === null || WinInfo === undefined) {
      return (
        <IconLoader isProcessing={true} />
      );
    }
    if (WinInfo.win === false || DrawResult.Win === false) {
      return null;
    }
    const balls = [];
    for (let i=0; i<WinInfo.numbers.length; i++) {
      balls.push(<li key={i}>{WinInfo.numbers[i]}</li>);
    }
    return (
      <div>
        <span className="myticket-won">{LanguageData.getText('ListItemsMyTicketListItem', 'limtli_006')}: {parseFloat(WinInfo.amountETH).toFixed(DecimalPointsSettings)}ETH</span>
        <ul className="list-unstyled result-list-ball green-ball">
          {balls}
        </ul>
      </div>
    );
  }
}

class Ticket extends React.Component {
  viewMore=()=>{
    const {
      data,
      openDetails
    } = this.props;
    SoundManager.playNumber();
    if (data.DrawResult) {
      openDetails(data);
    }
  }
  
  render() {
    const {
      data
    } = this.props;
    const drawAt = data.DrawAt;
    const draw = LanguageData.getText('ListItemsMyTicketListItem', 'limtli_003').replace("<draw number>", data.DrawNumber);
    const purchased = LanguageData.getText('ListItemsMyTicketListItem', 'limtli_004').replace("<date>", moment(data.CreateDate).format('MMMM Do YYYY, h:mm:ss a'));
    const disabled = data.DrawResult === undefined;
    const buttonStyle = disabled? "pbc-btn-disable" : "pbc-btn-blue";
    return (
      <div className="myticket-item-con">
        <div className="row clearfix align-items-center">
            <div className="col-md-2">
              <span className="myticket-ticket">{data.Game.GameName}</span>
              <span className="myticket-datetime">{drawAt? moment(drawAt).format('D MMM YYYY') : LanguageData.getText('ListItemsMyTicketListItem', 'limtli_007')}</span>
              <span className="myticket-amount">{data.Items.length} {LanguageData.getText('AccountMyTicketDetails', 'amtd_002')}</span>
            </div>
            <div className="col-md-3">
              <span className="myticket-draw">{draw}</span>
              <span className="myticket-purchase-date">{purchased}</span>
            </div>
            <div className="col-md-4">
              <ResultSection data={data} />
            </div>
            <div className="col-md-3">
              <a className={"button nomargin btn-block pbc-btn " + buttonStyle}
                 onClick={this.viewMore}>
                <span>{LanguageData.getText('ListItemsMyTicketListItem', 'limtli_005')}</span>
              </a>
            </div>
        </div>
      </div>
    );
  }
}

class MyTicketSection extends React.Component {
  renderTicket=(item, index)=>{
    const {
      openDetails
    } = this.props;
    return (
      <Ticket key={index} 
              data={item} 
              openDetails={openDetails} />
    );
  }
  
  render() {
    const {
      title,
      ticketList
    } = this.props;
    const tickets = ticketList.map(this.renderTicket);
    return (
      <div className="myticket-wrap">
        <span className="myticket-date">{title}</span>
        {tickets}          
      </div>
    );
  }
}

MyTicketSection.defaultProps = {
  title: '',
  ticketList: [],
  openDetails: null
};

export default MyTicketSection;