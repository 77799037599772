import Language from '../json/language-en.json';

class LanguageData {
  static languageCode = '';
  static languageJSON = {};
  
  static setJson(json) {
    this.languageJSON = json;
  }
  
  static getText(screen, key) {
    const screenObj = this.languageJSON[screen];
    if (screenObj) {
      return screenObj[key] === undefined? Language[screen][key] : screenObj[key];
    }else {
      const backupObj = Language[screen];
      return backupObj[key] === undefined? '' : backupObj[key];
    }
  }
  
  static setLanguageCode(code) {
    this.languageCode = code;
  }
  
  static getLanguageCode() {
    return this.languageCode;
  }
};

export default LanguageData;
