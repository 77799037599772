import React from 'react';
import { connect } from 'react-redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import FavouriteItem from '../../component/listItem/favouriteItem';
import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Utils from '../../helper/utils';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import {DecimalPointsSettings} from '../../config/constant';
import {USER_NUMBER_TYPE} from '../../config/enum';

const styles = {
  sectionTitle: {
    fontSize: '18px',
    color: '#FFF',
    padding: '5px'
  }
}

class MyFavourite extends React.Component {
  constructor(props) {
    super(props);
    this.fullInsightList = [];
    this.fullList = [];
    this.state = {
      insightList: [],
      favouriteList: []
    };
  }
  
  componentDidMount() {
    this.search();
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }

  goToHelp=()=>{
    const {
      gameData
    } = this.props.location.state;
    SoundManager.playNumber();
    this.navigateTo('help', { gameData: gameData });
  }
  
  async search() {
    const {
      user
    } = this.props;
    const {
      gameData
    } = this.props.location.state;
    if (user) {
      const userId = user? user.username : '';
      const response = await PlayAPI.getAllFavourite(userId, gameData.objectId);
      if (response.status === 200) {
        const body = await response.json();
        if (!body.Error) {
          let normal = [];
          let insight = [];
          for (let i=0; i<body.Data.length; i++) {
            if (body.Data[i].Type === USER_NUMBER_TYPE.SMART_PICK) {
              insight.push(body.Data[i]);
            }else {
              normal.push(body.Data[i]);
            }
          }
          this.fullList = normal;
          this.fullInsightList = insight;
          this.setState({
            insightList: insight,
            favouriteList: normal
          });
        }
      }
    }
  }
  
  onTypeSearch=(evt)=>{
    const text = evt.target.value;
    let list = [];
    let insight = [];
    for (let i=0; i<this.fullList.length; i++) {
      if (this.fullList[i].Name.includes(text)) {
        list.push(this.fullList[i]);
      }
    }
    for (let i=0; i<this.fullInsightList.length; i++) {
      if (this.fullInsightList[i].Name.includes(text)) {
        insight.push(this.fullInsightList[i]);
      }
    }
    this.setState({
      insightList: insight,
      favouriteList: list
    });
  }
  
  goToNew=()=>{
    const {
      gameData,
      tickets,
      freeStatus
    } = this.props.location.state;
    const {
      history
    } = this.props;
    history.push({
      pathname: '/playnew',
      state: {gameData, tickets, freeStatus}
    });
  }
  
  openDetail=(item)=>{
    const {
      gameData
    } = this.props.location.state;
    const {
      history
    } = this.props;
    history.push({
      pathname: '/favouritedetails',
      state: {
        gameData: gameData,
        favouriteData: item
      }
    });
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  renderTitle() {
    const {
      gameData
    } = this.props.location.state;
    const {
      GameName,
      GameType,
      JackpotValue,
    } = gameData;
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{GameName} : <span className="award-amount">{parseFloat(JackpotValue).toFixed(DecimalPointsSettings)}ETH</span></span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goToHelp}><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>	
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderHeader() {
    return (
      <header className="new-ticket-header">
        <div className="row">
          <div className="col-md-6 col-10">
            <ul className="header-tab">
              <li><a onClick={this.goToNew}>{LanguageData.getText('GamePlay', 'gp_001')}</a></li>
              <li><a className="current">{LanguageData.getText('GamePlay', 'gp_002')}</a></li>
            </ul>	
          </div>
          <div className="col-md-6 col-2">
            <ul className="favourite-search clearfix float-right">
              <li><a className="clearfix" style={{display:'flex', flexDirection:'row'}}>
                <i className="icon-line-search"></i>
                <input type="text" id="payment-form-password" name="payement-form-password" className="form-control not-dark"
                       style={{width:'270px'}} placeholder={LanguageData.getText('GamePlayFavorite', 'gpf_001')} onChange={this.onTypeSearch} />
              </a></li>
            </ul>	
          </div>
        </div>
      </header>
    );
  }
  
  renderFavourite=(item, index)=>{
    return (
      <FavouriteItem key={index}
                     favouriteData={item}
                     openDetail={this.openDetail} />
    );
  }
  
  render() {
    const {
      insightList,
      favouriteList
    } = this.state;
    const favourites = favouriteList.map(this.renderFavourite);
    const insights = insightList.map(this.renderFavourite);
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
			<div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <section className="main-content clearfix">
                        {this.renderHeader()}
                        <div className="favourite-list-body">
                          {insightList.length > 0? <span style={styles.sectionTitle}>{LanguageData.getText('GamePlayFavorite', 'gpf_002')}</span> : null}
                          {insights}
                          {favouriteList.length > 0? <span style={styles.sectionTitle}>{LanguageData.getText('GamePlay', 'gp_002')}</span> : null}
                          {favourites}
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'pluginsResult', src:"./js/plugins.js"},
          {id: 'insightCollapseResult', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbarResult', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignmentResult', src:"./js/html-script-insightAlignment.js"},
          {id: 'functionsHistory', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(MyFavourite);