import React from 'react';
import BigNumber from 'bignumber.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ScriptLoader from '../../component/scriptLoader';
import WalletItem from '../../component/listItem/walletItem'
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import { setWallet } from '../../actions/common';
import { DecimalPointsSettings, MAXIMUM_WALLET_COUNT } from '../../config/constant';

class MyWallet extends React.Component {
  constructor(props) {
    super(props);
  }
  
  async componentDidMount() {
    const { wallet } = this.props;
    if (wallet) {
      const userId = wallet.UserIds[0];
      let usdPerEth = 0;
      const response = await PlayAPI.checkETHValue();
      if (response.status === 200) {
        const body = await response.json();
      console.log(body)
        if (body.Error === false) {
          const rate = body.Data.rate? body.Data.rate.usd : 0;
          usdPerEth = rate;
        }
      }

      let balanceList = [];
      let USDBalanceList = [];
      for (let i=0; i<wallet.WalletAddresses.length; i++) {
        const resBalance = await PlayAPI.getWalletBalance(userId, i);
        if (resBalance.status === 200) {
          const bodyBalance = await resBalance.json();
          if (bodyBalance.Error === false && bodyBalance.TotalRows > 0) {
            const conversionRate = new BigNumber(usdPerEth);
            const balance = bodyBalance.Data.balance? bodyBalance.Data.balance : 0;
            balanceList.push(balance);
            USDBalanceList.push(conversionRate.multipliedBy(balance).toFixed());
          }
        }
      }

      wallet.WalletBalance = balanceList;
      wallet.USDBalance = USDBalanceList;
      await this.props.setWallet(wallet);
    }
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  openWalletDetails=(index, data)=>{
    this.navigateTo('mywalletdetails', {
      index: index,
      data: data
    });
  }
  
  openAddWallet=()=>{
    const {
      wallet,
      walletAddresses
    } = this.props;
    SoundManager.playNumber();
    if (walletAddresses.length < MAXIMUM_WALLET_COUNT) {
      const userId = wallet.UserIds[0];
      this.navigateTo('mywalletadd', {
        userId: userId,
        wallet: wallet,
        walletAddresses: walletAddresses
      });
    }else {
      const message = LanguageData.getText('AccountMyWallet', 'amw_002').replace("<number>", MAXIMUM_WALLET_COUNT);
      ToastsStore.warning(message);
    }
  }
  
  setDefaultWallet = async(index, value) => {
    const {
      wallet,
    } = this.props;
    if (index !== wallet.DefaultIndex) {
      const { wallet } = this.props;
      wallet.DefaultIndex = index;
      await this.props.setWallet(wallet);
      await PlayAPI.switchDefaultWallet(wallet.UserIds[0], wallet.objectId, wallet.DefaultIndex);
    }
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  renderTitle() {
    return (
      <section id="page-header">
          <div className="container clearfix header-container">
            <div className="row clearfix">
              <div className="col-md-9">
                <span className="award-title">{LanguageData.getText('AccountMyWallet', 'amw_001')}</span>
              </div>
              <div className="col-md-3">
                <ul className="top-nav-list">
                  <li><a className="justify-content-center" style={Instyle.WhiteText}
                         onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
                  <li><a className="justify-content-center" style={Instyle.WhiteText}
                         onClick={this.openAddWallet}><span><img src="images/pbc/add-new.png" /> {LanguageData.getText('AccountMyWalletEdit', 'amwe_010')}</span></a></li>	
                </ul>
              </div>
            </div>
          </div>
		</section>
    );
  }
  
  renderWallet=(item, index)=>{
    return (
      <WalletItem key={index}
                  data={item}
                  index={index}
                  openWallet={this.openWalletDetails}
                  setDefault={this.setDefaultWallet} />
    );
  }
  
  render() {
    const {
      walletAddresses
    } = this.props;
    const walletList = walletAddresses.map(this.renderWallet);
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">
                    {walletList.length > 0? walletList[0] : null}
                    {walletList.length > 1? walletList[1] : null}
                  </div>
                  <div className="row clearfix">
                    {walletList.length > 2? walletList[2] : null}
                    {walletList.length > 3? walletList[3] : null}
                  </div>
                  <div className="row clearfix">
                    {walletList.length > 4? walletList[4] : null}
                    {walletList.length > 5? walletList[5] : null}
                  </div>
                  <div className="row clearfix">
                    {walletList.length > 6? walletList[6] : null}
                    {walletList.length > 7? walletList[7] : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functionsWallet', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wallet: state.common.wallet,
  walletAddresses: state.common.walletAddresses,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setWallet: bindActionCreators(setWallet, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyWallet);