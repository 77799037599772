import LanguageData from './languageData';
import { DEFAULT_LANGUAGE } from '../config/constant';
import ImageLanguageJSON from '../json/imageLanguage.json';

const LanguageImage = {
  getImage: (imageKey) => {
    let language = LanguageData.getLanguageCode();
    if(language === '') {
      language = DEFAULT_LANGUAGE;
    }
    return ImageLanguageJSON[imageKey][language];
  }
}

export default LanguageImage;