import React from 'react';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ScriptLoader from '../../component/scriptLoader';

class Insights extends React.Component {
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          
          <section id="page-header">
            <div className="container clearfix header-container">
              <div className="row clearfix">
                <div className="col-md-9">
                  <span className="award-title">Insights</span>
                </div>
                <div className="col-md-3">
                  <ul className="top-nav-list">
                    <li><a href="help" className="justify-content-center"><span><img src="images/pbc/question.png" /> Help</span></a></li>	
                  </ul>
                </div>
              </div>
            </div>
          </section>          
          
          <section id="content" className="bg-transparent">
              <div className="content-wrap nopadding nomargin">
                <div className="section pbc-section nobg notoppadding nomargin">
                  <div className="container clear-bottommargin clearfix">
                    <div className="row clearfix">
                      
                              <div className="col-md-4 col-lg-3">
								<article className="insight-item-wrap">
									<div className="insight-item-con">
										<figure className="ball-icon">
											<img src="images/pbc/ball-45.png" />
											<figcaption>
												Has been the luckiest number this year.
											</figcaption>
										</figure>
										<a className="button nomargin btn-block pbc-btn">
											<span>Add To Favourite</span>
										</a>
									</div>
								</article>
							</div>
							<div className="col-md-4 col-lg-3">
								<article className="insight-item-wrap">
									<div className="insight-item-con">
										<div className="insight-item-word">
											<h2>Tuesday</h2>
										</div>
										<div className="insight-item-des">
											20% more likely to win if you play on a Tuesday.
										</div>
									</div>
								</article>
							</div>
							<div className="col-md-4 col-lg-3">
								<article className="insight-item-wrap">
									<div className="insight-item-con">
										<figure className="ball-icon">
											<img src="images/pbc/ball-08.png" />
											<figcaption>
												Has been the luckiest number this year.
											</figcaption>
										</figure>
										<a className="button nomargin btn-block pbc-btn pbc-btn-pink">
											<span>Add To Favourite</span>
										</a>
									</div>
								</article>
							</div>
							<div className="col-md-4 col-lg-3">
								<article className="insight-item-wrap">
									<div className="insight-item-con">
										<div className="insight-item-word">
											<h2>15</h2>
										</div>
										<div className="insight-item-des">
											36 times number frequency in Lotto 6/45
										</div>
									</div>
								</article>
							</div>
							<div className="col-md-4 col-lg-3">
								<article className="insight-item-wrap">
									<div className="insight-item-con">
										<div className="insight-item-word">
											<h2>27</h2>
										</div>
										<div className="insight-item-des">
											36 times number frequency in Lotto 6/45.
										</div>
									</div>
								</article>
							</div>
							<div className="col-md-4 col-lg-3">
								<article className="insight-item-wrap">
									<div className="insight-item-con">
										<figure className="ball-icon">
											<img src="images/pbc/ball-06.png" />
											<figcaption>
												Has been the luckiest number this year.
											</figcaption>
										</figure>
										<a className="button nomargin btn-block pbc-btn">
											<span>Add To Favourite</span>
										</a>
									</div>
								</article>
							</div>
							<div className="col-md-4 col-lg-3">
								<article className="insight-item-wrap">
									<div className="insight-item-con">
                                      <div className="insight-item-word">
                                        <h2>23 July</h2>
                                      </div>
                                      <div className="insight-item-des">
                                          20% more likely to win if you play on 23 July.
                                      </div>
									</div>
								</article>
							</div>
							<div className="col-md-4 col-lg-3">
								<article className="insight-item-wrap">
									<div className="insight-item-con">
										<figure className="ball-icon">
                                          <img src="images/pbc/ball-01.png" />
                                          <figcaption>
                                              Has been the luckiest number this year.
                                          </figcaption>
										</figure>
										<a className="button nomargin btn-block pbc-btn">
                                          <span>Add To Favourite</span>
										</a>
									</div>
								</article>
							</div>
                      
                    </div>
                  </div>
                </div>
              </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

export default Insights;