import React from 'react';
import Instyle from '../../theme/instyle';

class TitleButton extends React.Component {
  render() {
    const {
      icon,
      text,
      onClick
    } = this.props;
    return (
      <li><a className="justify-content-center" style={Instyle.WhiteText}
             onClick={onClick}><span><img src={icon} /> {text}</span></a></li>
    );
  }
}

TitleButton.defaultProps = {
  icon: "images/pbc/back.png",
  text: '',
  onClick: () => {}
};
export default TitleButton;