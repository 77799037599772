import React from 'react';

import {DecimalPointsSettings} from '../../config/constant';

const styles = {
  greenBallList: "list-unstyled result-list-ball green-ball",
  loseBallList: "list-unstyled result-list-ball non-ball"
};

class MyTicketDetailsRow extends React.Component {
  renderWin() {
    const {
      index,
      ticket,
      result,
      payout
    } = this.props;
    let balls = [];
    for (let i=0; i<ticket.length; i++) {
      const matched = result.includes(ticket[i]);
      if (payout > 0) {
        balls.push(matched? <li key={i}>{ticket[i]}</li> : <li key={i} className="ball-lose">{ticket[i]}</li>);
      }else {
        balls.push(matched? <li key={i}>{ticket[i]}</li> : <li key={i} className="none-border">{ticket[i]}</li>);
      }
    }
    const style = payout > 0? styles.greenBallList : styles.loseBallList;
    return (
      <div className="ticketwon-item">
        <div className="row align-items-center">
          <div className="col-2">
            <span className="ticketwon-num">{index + 1}</span>
          </div>
          <div className="col-8 center">
            <ul className={style}>
              {balls}
            </ul>
          </div>
          <div className="col-2">
            <span className="ticketwon-amount">{payout > 0? parseFloat(payout).toFixed(4) + 'ETH' : ''}</span>
          </div>
        </div>
      </div>
    );
  }
  
  renderDefault() {
    const {
      index,
      ticket,
      style,
    } = this.props;
    let balls = [];
    for (let i=0; i<ticket.length; i++) {
      balls.push(<li key={i} className="ticket-ball">{ticket[i]}</li>);
    }
    let classname = index%2===0? "result-detail-item clearfix result-detail-tr deep-body" : "result-detail-item clearfix result-detail-tr";
    
    return (
      <div className={classname}>
        <div className="container">
            <div className="row clearfix align-items-center">
                <div className="col-12">
                    <ul className="list-unstyled ticket-ball-list" style={style}>
                        <li>{index + 1}</li>
                        {balls}
                    </ul>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  render() {
    if (this.props.win) {
      return this.renderWin();
    }else {
      return this.renderDefault();
    }
  }
}

MyTicketDetailsRow.defaultProps = {
  style: {},
  win: false,
  index: 0,
  ticket: [],
  result: [],
  payout: 0,
  isRightColumn: false
};

export default MyTicketDetailsRow;