import React from 'react';

import SoundManager from '../../helper/soundManager';

const styles = {
  topRow: {
    paddingTop: 0,
    marginBottom: '25px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    color: '#FFF',
  }
};

class SwitchRow extends React.Component {
  flipSwitch=()=>{
    const {
      func
    } = this.props;
    SoundManager.playNumber();
    func();
  }
  
  render() {
    const {
      id, text, enable
    } = this.props;
    const idStr = id + 'Switch';
    return (
      <li style={styles.topRow}>
		<div style={styles.row}>
          <span style={styles.title}>{text}</span>
          <div>
            <input id={idStr} className="switch-toggle switch-rounded-mini switch-toggle-round"
                   type="checkbox" checked={enable} onChange={this.flipSwitch} />
            <label htmlFor={idStr} className="mb-1"></label>
          </div>
        </div>
      </li>
    );
  }
}

SwitchRow.defaultProps = {
  func: ()=>{},
  enable: false,
  text: '',
  id: ''
};

export default SwitchRow;