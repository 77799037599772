import React from 'react';
import BigNumber from 'bignumber.js';
import Instyle from '../../theme/instyle';
import LanguageData from '../../helper/languageData';

class SendETHForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendValue: 0,
    };
  }
  
  onChangeValue=(evt)=>{
    this.setState({
      sendValue: evt.target.value
    });
  }
  
  render() {
    const {
      walletName,
      walletAddress,
      walletBalance,
      conversionValue,
      onClose,
      onConfirm
    } = this.props;
    const { sendValue } = this.state;
    const value = isNaN(sendValue) || sendValue === ''? 0 : sendValue;
    const usdValue = new BigNumber(value).multipliedBy(conversionValue).toFixed();
    return (
      <div className="col-lg-4 col-md-6">
        <div className="card nobg divcenter noradius noborder">
            <div className="pbc-card-header center position-relative">
              <div className="btn-close">
                <a style={Instyle.CursorPointer} 
                   onClick={onClose}><img src="images/pbc/button-close.png" /></a>
              </div>
              <h3>{LanguageData.getText('AccountSendETH', 'ase_001')}</h3>
            </div>
            <div className="wallet-send-title">
              <div className="wallet-send-title-wrap">
                <span>{LanguageData.getText('AccountSendETH', 'ase_004')}</span>
              </div>
            </div>
            <div className="card-body pbc-card-body p-3 border-top-0">
              <div className="login-form mb-3">
                <div className="wallet-send-table">
                  <div className="row mb-3">
                    <div className="col-md-2 mb-2">
                      <span className="wallet-hd pt-0">{LanguageData.getText('AccountSendETH', 'ase_005')}</span>
                    </div>
                    <div className="col-md-10">
                      <span className="wallet-id">{walletName}</span>
                      <span className="wallet-id-detail">{walletAddress}</span>
                      <span className="wallet-id-balance">{LanguageData.getText('AccountSendETH', 'ase_011')} : {walletBalance} ETH</span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-2 mb-2">
                      <span className="wallet-hd">{LanguageData.getText('AccountSendETH', 'ase_006')}</span>
                    </div>
                    <div className="col-md-10">
                      <input type="text" id="to-wallet" name="to-wallet" className="form-control not-dark" placeholder="Enter Recipient Address" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-2 mb-2">
                      <span className="wallet-hd">{LanguageData.getText('AccountSendETH', 'ase_008')} (ETH)</span>
                    </div>
                    <div className="col-md-10">														
                      <input type="text" id="send-amount" name="send-amount" className="form-control not-dark" placeholder="Your Amount" 
                             onChange={this.onChangeValue} />
                      <span className="ml-3">USD {usdValue}</span>
                    </div>
                  </div>
                </div>

                <div className="col_full nobottommargin">
                  <button className="button nomargin btn-block pbc-btn" id="change-password" name="change-password" value=""
                          onClick={onConfirm}>
                    <span>{LanguageData.getText('AccountSendETH', 'ase_010')}</span>
                  </button>
                </div>
              </div>
            </div>									
        </div>
      </div>
    );
  }
}

SendETHForm.defaultProps = {
  walletName: '',
  walletAddress: '',
  walletBalance: 0,
  conversionValue: 0,
  onClose: ()=>{},
  onConfirm: ()=>{}
};

export default SendETHForm;