import React from 'react';
import BigNumber from 'bignumber.js';
import LoadSpinner from '../button/loadSpinner';
import LanguageData from '../../helper/languageData';
import Instyle from '../../theme/instyle';

class SendETHConfirm extends React.Component {
  close=()=>{
    const {
      onClose,
      isProcessing
    } = this.props;
    if (!isProcessing) {
      onClose();
    }
  }
  
  cancel=()=>{
    const {
      onCancel,
      isProcessing
    } = this.props;
    if (!isProcessing) {
      onCancel();
    }
  }
  
  sendETH=()=>{
    const {
      onConfirm,
      isProcessing
    } = this.props;
    if (!isProcessing) {
      onConfirm();
    }
  }
  
  render() {
    const {
      fromName,
      fromAddress,
      toAddress,
      amountETH,
      feeETH,
      conversionValue,
      isProcessing
    } = this.props;
    const sendUSD = new BigNumber(amountETH).multipliedBy(conversionValue).toFixed();
    const feeUSD = new BigNumber(feeETH).multipliedBy(conversionValue).toFixed();
    return (
      <div className="col-lg-4 col-md-6">
        <div className="card nobg divcenter noradius noborder">
            <div className="pbc-card-header center position-relative">
              <div className="btn-close">
                <a style={Instyle.CursorPointer} 
                   onClick={this.close}><img src="images/pbc/button-close.png" /></a>
              </div>
              <h3>{LanguageData.getText('AccountConfirmSendETH', 'acse_007')}</h3>
            </div>
            <div className="card-body pbc-card-body p-3">
              <div className="login-form mb-3">
                  <div className="wallet-send-table">
                      <div className="row mb-3">
                        <div className="col-md-2 mb-2">
                          <span>{LanguageData.getText('AccountConfirmSendETH', 'acse_001')} :</span>
                        </div>
                        <div className="col-md-10">
                          <span className="wallet-id wallet-text-bold">{fromName}</span>
                          <span className="wallet-id-detail wallet-text-bold">{fromAddress}</span>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-2 mb-2">
                          <span>{LanguageData.getText('AccountConfirmSendETH', 'acse_002')} :</span>
                        </div>
                        <div className="col-md-10">
                          <span className="wallet-id-detail wallet-text-bold">{toAddress}</span>
                        </div>
                      </div>
                      <div className="row mb-3 align-items-baseline">
                        <div className="col-md-2 mb-2">
                          <span>{LanguageData.getText('AccountConfirmSendETH', 'acse_003')} :</span>
                        </div>
                        <div className="col-md-10">
                          <span className="wallet-send-amount"><strong>{amountETH} ETH</strong> / USD {sendUSD}</span>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-2 mb-2">
                          <span>{LanguageData.getText('AccountConfirmSendETH', 'acse_004')} :</span>
                        </div>
                        <div className="col-md-10">
                          <span className="wallet-send-fee wallet-text-bold">{feeETH} ETH / USD {feeUSD}</span>
                        </div>
                      </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <button className="button nomargin btn-block pbc-btn pbc-btn-grey" id="wallet-cancel" name="wallet-cancel" value=""
                              onClick={this.cancel}>
                        <div style={{display:'flex', justifyContent:'center'}}>
                          <LoadSpinner isProcessing={isProcessing} />
                          <span>{LanguageData.getText('AccountConfirmSendETH', 'acse_005')}</span>
                        </div>
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button className="button nomargin btn-block pbc-btn" id="wallet-send" name="wallet-send" value=""
                              onClick={this.sendETH}>
                        <div style={{display:'flex', justifyContent:'center'}}>
                          <LoadSpinner isProcessing={isProcessing} />
                          <span>{LanguageData.getText('AccountConfirmSendETH', 'acse_006')}</span>
                        </div>
                      </button>
                    </div>
                  </div>
              </div>
            </div>									
        </div>
      </div>
    );
  }
}

SendETHConfirm.defaultProps = {
  fromName: '',
  fromAddress: '',
  toAddress: '',
  amountETH: 0,
  feeETH: 0,
  conversionValue: 0,
  isProcessing: false,
  onCancel: ()=>{},
  onConfirm: ()=>{},
  onClose: ()=>{}
};

export default SendETHConfirm;