import React from 'react';

import GameListItem from '../listItem/gameListItem';

class GameList extends React.Component {
  render() {
    const {
      gameList,
      openResult,
      openGame,
      openInsight
    } = this.props;
    let games = [];
    for (let i=0; i<gameList.length; i++) {
      games.push(
        <GameListItem
          key={i}
          index={i}
          gameData={gameList[i]}
          openResult={openResult}
          openGame={openGame}
          openInsight={openInsight} />
      );
    }
    return (
      <div className="row clearfix">
        {games}
      </div>
    );
  }
}

GameList.defaultProps = {
  gameList: [],
  openGame: ()=>{},
  openResult: ()=>{},
  openInsight: ()=>{}
};

export default GameList;