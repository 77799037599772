export default class String {
  /**
  * Add prefix "0" in front of the passed string
  * @param {string} value => The original string
  * @param {number} digitCount => The final amount of string length
  * @return {string} => The edited string
  **/
  static addNumberPrefix(value, digitCount) {
    let valStr = value.toString();
    if (valStr.length >= digitCount) {
      return valStr;
    }
    const diff = digitCount - valStr.length;
    let prefix = '';
    for (let i=0; i<diff; i++) {
      prefix += '0';
    }
    return prefix + valStr;
  }
  
  /**
  * Check whether the string contains lowercase alphabet
  * @param {string} str => The string to test
  * @return {boolean} => Whether the string contains lowercase alphabet
  **/
  static containsLowercaseAlphabet(str) {
    const regex = new RegExp("^(?=.*[a-z])");
    return regex.test(str);
  }
  
  /**
  * Check whether the string contains uppercase alphabet
  * @param {string} str => The string to test
  * @return {boolean} => Whether the string contains uppercase alphabet
  **/
  static containsUppercaseAlphabet(str) {
    const regex = new RegExp("^(?=.*[A-Z])");
    return regex.test(str);
  }
  
  /**
  * Check whether the string contains number
  * @param {string} str => The string to test
  * @return {boolean} => Whether the string contains number
  **/
  static containsNumber(str) {
    const regex = new RegExp("^(?=.*[0-9])");
    return regex.test(str);
  }
  
  /**
  * Check whether the string only contains number
  * @param {string} str => The string to test
  * @return {boolean} => Whether the string only contains number
  **/
  static onlyContainsNumber(str) {
    const regex = new RegExp("^[0-9]+$");
    return regex.test(str);
  }  
  
  /**
  * Check whether the string special character
  * @param {string} str => The string to test
  * @return {boolean} => Whether the string contains special character
  **/
  static containsSpecialCharacter(str) {
    str = str.replace(' ', '');
    const regex = new RegExp("^(?=.*[=*?!@#\$%\^&+_|()<>{}~\\[\\]/;:'\"`\\\\-])");
    return regex.test(str);
  }
  
  /**
  * Check whether the string have enough length
  * @param {string} str => The string to test
  * @param {number} neededLength => The amount of characters the string suppose to have
  * @return {boolean} => Whether the string have enough needed length
  **/
  static haveEnoughCharacters(str, neededLength) {
    return str.length >= neededLength;
  }
  
  /**
  * Check whether the string is email format 
  * @param {string} str => The string to test
  * @return {boolean} => Whether the string is in email format
  **/
  static isEmailFormat(str) {
    const splitEmail = str.split('@');
    const nameStartsOrEndsWithDot =  splitEmail.length > 1? this.startsOrEndsWithCharacter(splitEmail[0], '.') : false;
    const nameStartsOrEndsWithDash = splitEmail.length > 1? this.startsOrEndsWithCharacter(splitEmail[0], '-') : false;
    const domainStartsOrEndsWithDot = splitEmail.length > 1? this.startsOrEndsWithCharacter(splitEmail[1], '.') : false;
    const domainStartsOrEndsWithDash = splitEmail.length > 1? this.startsOrEndsWithCharacter(splitEmail[1], '-') : false;
    
    const atSignLastIndex = str.lastIndexOf('@');
    const dotIndex = str.indexOf('.', atSignLastIndex);
    const spaceIndex = str.indexOf(' ');
    const atSignCount = this.getCharacterAppearanceCount(str, '@');
    const haveMultiDot = this.haveContinuousCharacter(str, '.');
    if (
        atSignLastIndex < 0 ||  //doesn't have "@"
        dotIndex < 0 ||  //doesn't have "."
        spaceIndex >= 0 ||  //have space 
        atSignCount > 1 ||  //have more than 1 "@"
        atSignLastIndex === 0 || // starts with "@"
        haveMultiDot === true || // have continuous "."
        nameStartsOrEndsWithDot === true || // username starts or ends with "."
        nameStartsOrEndsWithDash === true || // username starts or ends with "-"
        domainStartsOrEndsWithDot === true || // domain starts or ends with "."
        domainStartsOrEndsWithDash === true // domain starts or ends with "."
       ) {
      return false;
    }    
    return true;
  }
  
  /**
  * Returns the amount of time a character appear in a string
  * @param {string} str => The string to test
  * @param {string} character => The character to look for
  * @return {number} => The amount of times the character appear in the string
  **/
  static getCharacterAppearanceCount(str, character) {
    const strSplit = str.split(character);
    return strSplit.length - 1;
  }
  
  /**
  * Returns whether a character appears in the string continuously
  * @param {string} str => The string to test against
  * @param {string} character => The character to test
  * @return {boolean} => Whether the string have the character continuously
  **/
  static haveContinuousCharacter(str, character) {
    const testChar = character + character;
    return str.indexOf(testChar) >= 0;
  }
  
  /**
  * Returns whether a character appears at the starts or ends of a string
  * @param {string} str => The string to test against
  * @param {string} character => The character to test
  * @return {boolean} => Whether the string starts or ends with the character
  **/
  static startsOrEndsWithCharacter(str, character) {
    return this.startsWithCharacter(str, character) || this.endsWithCharacter(str, character);
  }
  
  /**
  * Returns whether a character appears at the starts of a string
  * @param {string} str => The string to test against
  * @param {string} character => The character to test
  * @return {boolean} => Whether the string starts with the character
  **/
  static startsWithCharacter(str, character) {
    return str.indexOf(character) === 0;
  }
  
  /**
  * Returns whether a character appears at the starts of a string
  * @param {string} str => The string to test against
  * @param {string} character => The character to test
  * @return {boolean} => Whether the string starts with the character
  **/
  static endsWithCharacter(str, character) {
    return str.lastIndexOf(character) === (str.length - 1);
  }
}
