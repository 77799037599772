import React from 'react';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';

class Agreement extends React.Component {
  onPressNext=()=>{
    const {
      onPressNext
    } = this.props;
    SoundManager.playPlay();
    const checkbox = document.getElementById('agree-check');
    if (checkbox.checked) {
      onPressNext();
    }else {
      ToastsStore.warning(LanguageData.getText('InitialSetupPinCodeFingerprintAgreement', 'ispcfa_005'));
    }
  }
  
  render() {
    const {
      title1,
      title2,
      text,
      image,
      goBack
    } = this.props;
    return (
      <React.Fragment>
        <div className="col-lg-3">
          <div className="card nobg divcenter noradius noborder">
            <div className="pbc-card-header center position-relative">
              <div className="btn-close">
                <a style={Instyle.CursorPointer} onClick={goBack}><img src="images/pbc/button-close.png" /></a>
              </div>
              <h3>{title1}</h3>
            </div>
            <div className="card-body pbc-card-body">
              <figure className="center verify-img mb-3">
                <img src={image} />
                <figcaption>
                  <h3 className="text-white mb-0 mt-3">{title2}</h3>
                </figcaption>
              </figure>
              <div className="center mb-3">
                <span className="text-white">{text}</span>
              </div>
              <form className="login-form mb-3">
                <div className="col_full col_mb">
                  <div className="form-check center">
                    <input className="form-check-input" type="checkbox" id="agree-check" name="agree-check" value="" />
                    <label className="form-check-label text-white pbc-text-light text-capitalize" htmlFor="agree-check">{LanguageData.getText('InitialSetupPinCodeFingerprintAgreement', 'ispcfa_002')} <a className="text-yellow" href="#">{LanguageData.getText('InitialSetupPinCodeFingerprintAgreement', 'ispcfa_003')}</a>.</label>
                  </div>
                </div>
              </form>
              <button className="button nomargin btn-block pbc-btn" id="form-submit" name="form-submit" 
                      value="login" onClick={this.onPressNext}>
                <span>{LanguageData.getText('InitialSetupPinCodeFingerprintAgreement', 'ispcfa_004')}</span>
              </button>
            </div>
          </div>
        </div>
        
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
      </React.Fragment>
    );
  }
}

Agreement.defaultProps = {
  title1: '',
  title2: '',
  text: '',
  image: '',
  onPressNext: null,
};

export default Agreement;