import React from 'react';
import { connect } from 'react-redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import PlayPaymentSection from '../../component/card/playPaymentSection';
import PlayTicket from '../../component/listItem/playTicket';
import DialogConfirmation from '../../component/dialog/dialogConfirmation';
import ScriptLoader from '../../component/scriptLoader';
import Utils from '../../helper/utils';
import UtilsHelper from '../../helper/UtilsHelper';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import {DecimalPointsSettings} from '../../config/constant';

class PlayNew extends React.Component {
  constructor(props) {
    super(props);
    const {
      tickets,
      freeStatus
    } = this.props.location.state;
    this.state = {
      tickets: tickets? tickets : [],
      freeStatus: freeStatus? freeStatus : [],
      closeGame: false,
      isProcessing: false
    };
    this.originalFreeTicketAmount = 0;
    
  }
  
  async componentDidMount() {
    const {
      user
    } = this.props;
    const id = user? user.username : '';
    const response = await PlayAPI.getFreeTicketById(id);
    if (response.status === 200) {
      const body = await response.json();
      if (!body.Error) {
        let freeTicket = 0;
        const data = body.Data.TicketsData;
        const current = new Date().getTime();
        for (let i=0; i<data.length; i++) {
          if (data[i].ExpiredDate > current) {
            freeTicket += data[i].Tickets;
          }
        }
        this.originalFreeTicketAmount = freeTicket;
      }
    }
    
    const { tickets } = this.state;
    if (tickets.length === 0) { 
      this.addTicket();
    }
    const {
      useFreeTicket
    } = this.props.location.state;
    if (useFreeTicket) {
      this.setTicketFreeStatus(0);
    }
  }
  
  componentDidUpdate() {
    if (this.state.closeGame === false) {
      const {
        gameData
      } = this.props.location.state;
      const {
        games
      } = this.props;
      if (games.length > 0) {
        const game = UtilsHelper.getGameById(gameData.objectId, games);
        if (game.Status !== '1') {
          this.setState({
            closeGame: true
          });
        }
      }
    }
  }
  
  setInviTicketRef=(item)=>{
    item.click();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  goBack=()=>{
    SoundManager.playNumber();
    this.props.history.goBack();
  }
  
  goToHelp=()=>{
    const {
      gameData
    } = this.props.location.state;
    SoundManager.playNumber();
    this.navigateTo('help', { gameData: gameData });
  }
  
  deleteAllTicket=()=>{
    SoundManager.playNumber();
    this.setState({
      tickets: [],
      freeStatus: []
    });
  }
  
  randomAllTicket=()=>{
    const { tickets } = this.state;
    SoundManager.playRandom();
    for (let i=0; i<tickets.length; i++) {
      this.setTicketRandomNumber(i);
    }
  }
  
  handleAddTicket=()=>{
    SoundManager.playNumber();
    this.addTicket();
  }
  
  addTicket=async()=>{
    let {tickets,  freeStatus} = this.state;
    tickets.push([]);
    freeStatus.push(false);
    await this.setState({
      tickets: tickets,
      freeStatus: freeStatus
    });
    
    var objDiv = document.getElementById("scrollview");
    objDiv.scrollTop = objDiv.scrollHeight;
  }
  
  handleAddFavourite=()=>{
    if (this.checkTicketValid() === false) {
      return;
    }
    
    const {
      tickets
    } = this.state;
    const {
      gameData
    } = this.props.location.state;
    this.navigateTo('favouritecreate', { gameData: gameData, tickets: tickets });
  }
  
  onPressKey=(index, number)=>{
    const {
      gameData
    } = this.props.location.state;
    const {
      BallsCount
    } = gameData;
    let {tickets} = this.state;
    if (tickets[index].includes(number)) {
      //DESELECT THE NUMBER IF ALREADY SELECTED
      const numberIndex = tickets[index].indexOf(number);
      tickets[index].splice(numberIndex, 1);
    }else {
      //SELECT A NEW NUMBER IF THERE'S STILL EMPTY SLOT
      if (tickets[index].length < BallsCount) {
        tickets[index].push(number);
      }
    }
    tickets[index].sort(function(a, b){return a-b});
    this.setState({
      tickets: tickets
    });
  }
  
  setTicketFreeStatus= async(index) =>{
    let {freeStatus} = this.state;
    if (this.getFreeTicketAmount() === this.originalFreeTicketAmount && freeStatus[index] === false) {
      ToastsStore.warning(LanguageData.getText('CardsTicketCard', 'ctc_001'));
      return;
    }
    freeStatus[index] = !freeStatus[index];
    await this.setState({
      freeStatus: freeStatus
    });
  }
  
  setTicketRandomNumber=(index)=>{  
    const {
      gameData
    } = this.props.location.state;
    const {
      BallsCount,
      TotalBalls
    } = gameData;
    let {tickets} = this.state;
    const randomized = Utils.addTicketRandomNumber(tickets[index], TotalBalls, BallsCount);
    tickets[index] = randomized;
    this.setState({
      tickets: tickets
    });
  }
  
  deleteTicket=(index)=>{
    let {tickets, freeStatus} = this.state;
    tickets.splice(index, 1);
    freeStatus.splice(index, 1);
    this.setState({
      tickets: tickets,
      freeStatus: freeStatus
    });
  }
  
  getFreeTicketAmount() {
    const {
      freeStatus
    } = this.state;
    let amount = 0;
    for (let i=0; i<freeStatus.length; i++) {
      if (freeStatus[i] === true) {
        amount++;
      }
    }
    return amount;
  }
  
  checkTicketValid() {
    let {tickets, freeStatus} = this.state;
    if (tickets.length === 0) {
      ToastsStore.warning(LanguageData.getText('GamePlayFavoriteDetail', 'gpfd_001'));
      return false;
    }
    
    const {
      gameData
    } = this.props.location.state;
    const {
      BallsCount
    } = gameData;
    for (let i=0; i<tickets.length; i++) {
      if (tickets[i].length < BallsCount) {        
        const message = LanguageData.getText('GamePlayNew', 'gpn_002').replace("<number>", BallsCount);
        ToastsStore.warning(message);
        return false;
      }
    }
    return true;
  }
  
  tryBuyTicket = async(pincode) => {
    let {tickets, freeStatus} = this.state;    
    const {
      gameData
    } = this.props.location.state;
    
    if (this.checkTicketValid() === false) {
      return;
    }
    
    const response = await PlayAPI.checkPINCode(this.props.user.username, pincode);
    const body = await response.json();
    if (response.status !== 200  || body.Data === false) {
      ToastsStore.error(LanguageData.getText('GamePlayNew', 'gpn_003'));
      return;
    }
    
    this.setState({ isProcessing: true });
    
    let paidTicket = [];
    let freeTicket = [];
    for (let i=0; i<freeStatus.length; i++) {
      if (freeStatus[i] === true) {
        freeTicket.push(tickets[i]);
      }else {
        paidTicket.push(tickets[i]);
      }
    }
    let params = {
      AccountId: gameData.AccountId,
      UserId: this.props.user.username,
      GameId: gameData.objectId,
      Items: paidTicket,
    };
    
    let managedToBuyPaid = false;
    let managedToBuyFree = false;
    if (paidTicket.length > 0) {
      managedToBuyPaid = await this.buyTicket(params);
    }else {
      managedToBuyPaid = true;
    }
    if (freeTicket.length > 0) {
      if (managedToBuyPaid) {
        params.PromoCode = 'FREE';
        params.UseFreeTicket = true;
        params.Items = freeTicket;
        managedToBuyFree = await this.buyTicket(params);
      }
    }else {
      managedToBuyFree = true;
    }
    this.setState({ isProcessing: false });
    if (managedToBuyPaid === true && managedToBuyFree === true) {
      this.props.history.push('/paymentsuccessful');
    }
  }
  
  async buyTicket(params) {
    try {
      if (typeof params.AccountId !== 'string' ||
          typeof params.UserId !== 'string' ||
          typeof params.GameId !== 'string') {
        throw {message: LanguageData.getText('GamePlayNew', 'gpn_006')};
      }
      
      const response = await PlayAPI.buyTicket(params);
      if (response.status !== 200) {
        const body = await response.json();
        if (body.Error) {
          if (body.ErrorDesc.message) {
            ToastsStore.error(body.ErrorDesc.message);
          }else if (body.ErrorDesc.code) {
            ToastsStore.error(body.ErrorDesc.code);
          }
        }
        return false;
      } else {
        return true;
      }
    } catch (e) {
      ToastsStore.error(e.message);
      return false;
    }
  }
  
  goToFavourite=()=>{
    const {      
      tickets,
      freeStatus,
    } = this.state;
    const {
      gameData
    } = this.props.location.state;
    SoundManager.playNumber();
    this.navigateTo('myfavourite', { gameData, tickets, freeStatus });
  }
  
  renderTitle() {
    const {
      gameData
    } = this.props.location.state;
    const {
      GameName,
      GameType,
      JackpotValue,
    } = gameData;
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{GameName} : <span className="award-amount">{parseFloat(JackpotValue).toFixed(DecimalPointsSettings)}ETH</span></span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goToHelp}><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>	
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderHeaderTab() {
    return (
      <header className="new-ticket-header">
        <div className="row">
        <div className="col-md-6">
          <ul className="header-tab">
            <li><a className="current">{LanguageData.getText('GamePlay', 'gp_001')}</a></li>
            <li><a onClick={this.goToFavourite}>{LanguageData.getText('GamePlay', 'gp_002')}</a></li>
          </ul>	
        </div>
        <div className="col-md-6">
          <ul className="header-option clearfix">
            <li><a style={Instyle.YellowText} 
                   onClick={this.deleteAllTicket}><i><img src="images/pbc/eraser.png" /></i><span className="d-none d-sm-block">{LanguageData.getText('webapp', 'webapp_006')}</span></a></li>
            <li><a style={Instyle.YellowText} 
                   onClick={this.randomAllTicket}><i><img src="images/pbc/smart-pick.png" /></i><span className="d-none d-sm-block">{LanguageData.getText('webapp', 'webapp_007')}</span></a></li>
          </ul>	
        </div>
        </div>
      </header>
    );
  }
  
  renderTicket=(item, index)=>{
    const {
      gameData
    } = this.props.location.state;
    const {
      BallsCount,
      TotalBalls
    } = gameData;
    const {
      freeStatus
    } = this.state;
    return (
      <PlayTicket key={index}
                  index={index} 
                  selectedNumbers={item}
                  isFree={freeStatus[index]}
                  onPressKey={this.onPressKey}
                  setFreeStatus={this.setTicketFreeStatus}
                  setRandomNumber={this.setTicketRandomNumber}
                  deleteTicket={this.deleteTicket}
                  ballCount={BallsCount}
                  maxNumber={TotalBalls} />
    );
  }
  
  render() {
    const {
      gameData
    } = this.props.location.state;
    const {
      closeGame,
      tickets,
      isProcessing
    } = this.state;
    const ticketList = tickets.map(this.renderTicket);
    const freeTicketAmount = this.getFreeTicketAmount();
    const freeTicketLeft = this.originalFreeTicketAmount - this.getFreeTicketAmount();
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
			<div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">                    
                    <PlayPaymentSection gameData={gameData}
                                        ticketAmount={tickets.length}
                                        freeTicketAmount={freeTicketAmount}
                                        addToFavourite={this.handleAddFavourite}
                                        buyTicket={this.tryBuyTicket}
                                        isProcessing={isProcessing} />
                    <div className="col-md-8">
                      <section className="main-content clearfix">
                        {this.renderHeaderTab()}                        
                        <div className="new-ticket-body">
                          <div className="content">
                            <div className="demo">
                              <div id="scrollview" styles={{ height: '100px', overflowY: 'scroll' }}>
                                <div id="accordion" role="tablist">
                                  {ticketList}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="new-ticket-footer">
                          <span className="ticket-count"><b>{freeTicketLeft}</b> {LanguageData.getText('CardsTicketCard', 'ctc_006')}</span>
                          <a className="add-ticket" onClick={this.handleAddTicket}><i className="add-ticket-btn"></i>{LanguageData.getText('webapp', 'webapp_009')}</a>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <DialogConfirmation title={LanguageData.getText('DialogCloseGameDialog', 'dcgd_003')}
                            text={LanguageData.getText('DialogCloseGameDialog', 'dcgd_001')}
                            buttonText={LanguageData.getText('DialogCloseGameDialog', 'dcgd_002')}
                            onClose={this.goBack}
                            buttonFunction={this.goBack}
                            isVisible={closeGame} />
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[          
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wallet: state.common.wallet,
  user: state.common.user,
  games: state.common.games
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayNew);