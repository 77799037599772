import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import NotificationItem from '../../component/listItem/notificationItem';
import MyTicketDetailsRow from '../../component/listItem/myTicketDetailsRow';
import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import { setInboxes } from '../../actions/common';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: 0,
      list: props.inboxes.list,
      checkedList: Array(props.inboxes.list.length).fill(false),
      selectAll: false,
    }
  }
  
  componentDidMount() {
    this.changeOpenIndex(0);
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  onChangeSelectAll=(evt)=>{
    SoundManager.playNumber();
    const value = !this.state.selectAll;
    this.setState({
      selectAll: value,
      checkedList: Array(this.state.list.length).fill(value)
    });
  }
  
  onChangeSelect=(index)=>{
    let {
      checkedList
    } = this.state;
    checkedList[index] = !checkedList[index];
    this.setState({
      checkedList: checkedList
    });
  }
  
  changeOpenIndex=(index)=>{
    const list = this.state.list;
    if (list.length === 0) {
      return;
    }
    
    const isRead = list[index].IsRead;
    this.setState(()=>{
      return {openIndex: index};
    }, ()=>{
      if (isRead !== true) {
        list[index].IsRead = true;
        PlayAPI.updateReadInbox(list[index].objectId);
        this.props.setInboxes(list);
        this.setState({
          list: list
        });
      }
    });
  }
  
  openPrevious=()=>{
    SoundManager.playNumber();
    if (this.state.openIndex > 0) {
      this.changeOpenIndex(this.state.openIndex - 1);
    }
  }
  
  openNext=()=>{
    SoundManager.playNumber();
    if (this.state.openIndex < this.state.list.length - 1) {
      this.changeOpenIndex(this.state.openIndex + 1);
    }
  }
  
  deleteSelected = async() => {
    let {
      list,
      checkedList
    } = this.state;
    SoundManager.playNumber();
    for (let i=list.length-1; i >= 0; i--) {
      if (checkedList[i] === true) {
        await PlayAPI.deleteInbox(list[i].objectId);
        list.splice(i, 1);
        checkedList.splice(i, 1);
      }
    }
    
    this.props.setInboxes(list);
    this.setState({
      list: list,
      checkedList: checkedList
    });
  }
  
  deleteCurrent=()=>{
    let {
      list,
      openIndex,
      checkedList
    } = this.state;
    SoundManager.playNumber();
    PlayAPI.deleteInbox(list[openIndex].objectId);
    list.splice(openIndex, 1);
    checkedList.splice(openIndex, 1);
    
    this.props.setInboxes(list);
    this.setState({
      list: list,
      openIndex: 0,
      checkedList: checkedList
    });
  }
  
  renderTitle() {
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
            <div className="row clearfix">
                <div className="col-md-9">
                  <span className="award-title">{LanguageData.getText('AccountNotifications', 'an_001')}</span>
                </div>
                <div className="col-md-3">
                  <ul className="top-nav-list d-flex align-items-center justify-content-end">
                    <li><a className="justify-content-center" style={Instyle.WhiteText}
                           onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
                  </ul>
                </div>
            </div>
        </div>
      </section>
    );
  }
  
  renderSideList() {
    const {
      list
    } = this.state;
    const notification = list.map(this.renderNotification);
    return (
      <div className="col-md-4 mb-4">
        <div className="notification-side">
          <header className="clearfix d-flex align-items-center pbc-border-bottom pb-3 pt-3">
            <div className="header-left">
              <input id="checkbox-7" className="checkbox-style" name="checkbox-7" type="checkbox" checked={false} onChange={this.onChangeSelectAll} />
              <label htmlFor="checkbox-7" className="checkbox-style-2-label">{LanguageData.getText('AccountNotifications', 'an_003')}</label>
            </div>
            <div className="header-right">
              <ul className="top-nav-list d-flex align-items-center justify-content-end">
                <li className="text-right">
                  <a className="justify-content-center" style={Instyle.WhiteText} 
                     onClick={this.deleteSelected}><span><img src="images/pbc/del-icon.png" /> {LanguageData.getText('AccountNotifications', 'an_004')}</span></a>
                </li>
              </ul>
            </div>
          </header>
          <section className="notification-side-body">
			<ul className="list-unstyled note-list">
              {notification}
            </ul>
          </section>
        </div>
      </div>
    );
  }
  
  renderNotification=(item, index)=>{
    const {
      openIndex,
      checkedList
    } = this.state;
    return (
      <NotificationItem key={index}
                        index={index}
                        isSelected={openIndex === index}
                        unread={item.IsRead !== true}
                        checked={checkedList[index]}
                        title={item.Title}
                        text={item.Messages}
                        openMessage={this.changeOpenIndex}
                        onChangeSelect={this.onChangeSelect} />
    );
  }
  
  renderMessage() {
    const {
      openIndex,
      list
    } = this.state;
    if (list.length === 0) {
      return null;
    }
    
    const message = list[openIndex];
    let gameType = '';
    let numberRows = message.Tickets? [] : null;
    if (message.Tickets) {
      const { games } = this.props;
      const game = games.find((item)=>{ return item.objectId === message.GameId; });
      gameType = game.GameType;
      
      for (let i=0; i<message.Tickets.length; i++) {
        numberRows.push(<MyTicketDetailsRow key={i} win={false} index={i} ticket={message.Tickets[i]} />);
      }
    }
    
    return (
      <div className="col-md-8 mb-4">
        <div className="notification-body message-body">
          <header className="clearfix d-flex align-items-center pb-2 pt-3 mb-2">
            <div className="message-top-header">
              <ul className="top-nav-list d-flex align-items-center justify-content-end">
                <li className="text-right"><a className="justify-content-center" style={Instyle.CursorPointer} onClick={this.openPrevious}><span><img src="images/pbc/previous-36.png" /> </span></a></li>
                <li className="text-right"><a className="justify-content-center" style={Instyle.CursorPointer} onClick={this.openNext}><span><img src="images/pbc/next-36.png" /> </span></a></li>
              </ul>
            </div>
          </header>
          <section className="msg-body-wrap" style={{padding: 0}}>
            <div style={{padding: '15px'}}>
              <div className="msg-body-header mb-3">
                  <div className="row">
                    <div className="col-10">
                      <h3 className="msg-body-title">{message.Title}</h3>
                      <span className="msg-body-date">{moment(message.createdAt).format('MMMM Do YYYY, h:mm:ss a')}.</span>
                    </div>
                    <div className="col-2">
                      <div className="msg-del-wrap">
                        <a className="justify-content-center" style={Instyle.WhiteText} 
                           onClick={this.deleteCurrent}><span><img src="images/pbc/del-icon.png" /> {LanguageData.getText('AccountNotifications', 'an_004')}</span></a>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="msg-body">
                <p>{message.Messages}</p>
              </div>
            </div>
            {message.Tickets?
              <div style={{justifyContent:'space-between', display:'flex', paddingLeft:'15px', paddingRight:'15px'}}>
                <span style={{font: '30px', color: 'white'}}>{gameType}</span>
                <span style={{font: '30px', color: 'white'}}>Draw: {message.DrawNumber}</span>
              </div> : null}
            {numberRows}
            {message.Winner? 
              <img src="images/pbc/notification_win_icon.png"
                   style={{width: 230, height: 216, display: 'flex', margin: 'auto'}}/> : null
            }
          </section>
        </div>
      </div>
    );
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
            <div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container">
                  <div className="row">
                    {this.renderSideList()}
                    {this.renderMessage()}                    
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'bsSwitches', src:"js/components/bs-switches.js"},
          {id: 'functions', src:"./js/functions.js"},
          {id: 'bootstrapSwitch', src:"./js/html-script-ticketSwitch.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  inboxes: state.common.inboxes,
  games: state.common.games
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setInboxes: bindActionCreators(setInboxes, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);