import Types from './types';

export const setUser = user => dispatch => dispatch({
  type: Types.SET_USER, user
});

export const setAlertRef = ref => dispatch => (
  dispatch({
    type: Types.SET_ALERT_REF, ref
  })
);

export const setWallet = wallet => dispatch => dispatch({
  type: Types.SET_WALLET,
  wallet
});

export const setWalletAddresses = walletAddresses => dispatch => dispatch({
  type: Types.SET_WALLET_ADDRESSES,
  walletAddresses
});

export const setGames = games => dispatch => dispatch({
  type: Types.SET_GAMES,
  games
});

export const setInboxes = inboxes => dispatch => dispatch({
  type: Types.SET_INBOXES,
  inboxes
});

export const setEnableSound = enableSound => dispatch => dispatch({
  type: Types.SET_ENABLE_SOUND,
  enableSound
});

export const setEnableMusic = enableMusic => dispatch => dispatch({
  type: Types.SET_ENABLE_MUSIC,
  enableMusic
});

export const resetRedux = empty => dispatch => dispatch({
  type: Types.RESET_REDUX,
  empty
});
