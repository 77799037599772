import React from 'react';

import String from '../../helper/string';
import SoundManager from '../../helper/soundManager';
import {DecimalPointsSettings} from '../../config/constant';

class WalletItem extends React.Component {
  openDetails=()=>{
    const {
      index,
      data,
      openWallet
    } = this.props;
    SoundManager.playNumber();
    openWallet(index, data);
  }
  
  setAsDefault=(evt)=>{
    const {
      data,
      index,
      setDefault
    } = this.props;
    SoundManager.playNumber();
    setDefault(index, evt.target.value === "on");
  }
  
  render() {
    const {
      data,
      index
    } = this.props;
    const switchId = "wallet-toggle-" + index.toString();
    return (
      <div className="col-md-6">
        <div className="wallet-item-container">
          <div className="wallet-switch">
            <input id={switchId} className="switch-toggle switch-rounded-mini switch-toggle-round" type="checkbox" 
                   checked={data.enabled} onChange={this.setAsDefault} />
            <label htmlFor={switchId} className="mb-1"></label>
          </div>
          <div className="iten-des" style={{cursor:'pointer'}}
               onClick={this.openDetails}>
            <h4 className="wallet-title">{data.name}</h4>
            <span className="wallet-eth">{parseFloat(data.balance).toFixed(DecimalPointsSettings)}<i>ETH</i></span>
            <span className="wallet-money">${parseFloat(data.usdBalance).toFixed(DecimalPointsSettings)}</span>
          </div>
          <div className="ethereum"></div>
        </div>
      </div>
    );
  }
}

WalletItem.defaultProps = {
  index: 0,
  data: {},
  openWallet: null,
  setDefault: null
}

export default WalletItem;