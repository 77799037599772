import React from 'react';

class Key extends React.Component {
  onClick=()=>{
    const {
      number,
      onClick
    } = this.props;
    onClick(number);
  }
  
  render() {
    const {
      number,
      isSelected
    } = this.props;
    return (
      <li className={isSelected? "select" : ""} 
          onClick={this.onClick}>{number}</li>
    );  
  }
}

class TicketKeyboard extends React.Component {  
  render() {
    const {
      ticketId,
      maxNumber,
      selectedNumbers,
      onPressKey
    } = this.props;    
    let keyboard = [];
    for (let i=0; i<maxNumber; i++) {
      let selected = selectedNumbers.includes(i + 1);
      keyboard.push(<Key key={i} number={i + 1} isSelected={selected} onClick={onPressKey} />);
    }
    
    return (
      <div id={ticketId} className="collapse" role="tabpanel" data-parent="#accordion">
        <div className="card-body nobg">
          <ul className="list-unstyled nomargin number-listing">
            {keyboard}
          </ul>
        </div>
      </div>
    );
  }
}

TicketKeyboard.defaultProps = {
  ticketId: '',
  maxNumber: 45,
  selectedNumbers: [],
  onPressKey: null
};

export default TicketKeyboard;