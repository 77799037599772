import React from 'react';
import moment from 'moment';

import ScriptLoader from '../../component/scriptLoader';
import DateTime from '../../helper/datetime';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import {DecimalPointsSettings} from '../../config/constant';

class ResultTop10Row extends React.Component {
  openDetails=()=>{
    const {
      index,
      openDetails
    } = this.props;    
    SoundManager.playPlay();
    openDetails(index);
  }
  
  render() {
    const {
      data,
      index
    } = this.props;
    const background = index % 2 === 0? " deep-body" : "";
    
    return (
      <div className={"top-listing-item clearfix cust-table-body" + background}>
        <div className="cus-table-tr">
          <div className="cus-table-td d-flex align-items-center">
            <span className="td-title d-block d-sm-block d-md-none">{LanguageData.getText('ListItemsPayoutListItem', 'lipli_001')} :</span>
            <span className="payout-amount">{parseFloat(data.Payout).toFixed(DecimalPointsSettings)}ETH</span>
          </div>
        </div>
        <div className="cus-table-tr">
          <div className="cus-table-td d-flex align-items-center">
            <span className="td-title d-block d-sm-block d-md-none">{LanguageData.getText('ListItemsPayoutListItem', 'lipli_002')} :</span>
            <span>{data.DrawNumber}</span>
          </div>
        </div>
        <div className="cus-table-tr">
          <div className="cus-table-td d-flex align-items-center">
            <span className="td-title d-block d-sm-block d-md-none">{LanguageData.getText('ListItemsPayoutListItem', 'lipli_003')} :</span>
            <span>{moment(data.createdAt).format('D MMM YYYY')}</span>
          </div>
        </div>
        <div className="cus-table-tr">
          <div className="cus-table-td d-flex align-items-center">
            <span className="td-title d-block d-sm-block d-md-none">{LanguageData.getText('ListItemsPayoutListItem', 'lipli_004')} :</span>
            <span style={{marginRight: '10px'}}>{data.Address.substring(0, 20)}</span>
            <button type="button" id={'winnertooltip' + index.toString()}
                    className="wallet-tt i-circled i-small icon-line-ellipsis"
                    style={{background: "#f9f241", color: "#2a1050"}}
                    data-toggle="tooltip" data-placement="left"
                    data-original-title={data.Address}
                    title={data.Address}></button>
          </div>
        </div>
        <div className="cus-table-tr">
          <div className="cus-table-td">
            <a className="button nomargin btn-block pbc-btn lh-32"
               onClick={this.openDetails}>
              {LanguageData.getText('ListItemsHistoryListItem', 'lihli_002')}
            </a>
          </div>
        </div>
        
        <ScriptLoader scriptArray={[
          {id: 'htmlscripttooltip', src:"./js/html-script-tooltip.js"}
        ]} />
      </div>
    );
  }
}

ResultTop10Row.defaultProps = {
  data: {},
  index: 0,
  openDetails: ()=>{}
};

export default ResultTop10Row;