import React from 'react';

class FAQItem extends React.Component {
  render() {
    const {
      item,
      extension
    } = this.props;
    return (
      <div className="card">
        <div className="card-header" role="tab" id={"faqs-tab-" + extension}>
          <h5 className="mb-0">
            <a className="collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" 
               href={"#faqs-" + extension} aria-expanded="true" aria-controls={"faqs-" + extension}>
              <span>{item.Questions}</span>
              <i className="toggle-icon"><img src="images/pbc/help-arrow.png" /></i>
            </a>
          </h5>
        </div>

        <div id={"faqs-" + extension} className="collapse" role="tabpanel" aria-labelledby={"faqs-" + extension} data-parent="#accordion">
          <div className="card-body">{item.Answers}</div>
        </div>
      </div>
    );
  }
}

FAQItem.defaultProps = {
  item: {Questions: '', Answers: ''},
  extension: ''
};

export default FAQItem;