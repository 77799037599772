import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import LoadSpinner from '../../component/button/loadSpinner';
import ProfilePersonal from '../../component/card/profilePersonal';
import ProfileAddress from '../../component/card/profileAddress';
import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import String from '../../helper/string';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import { setUser } from '../../actions/common';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    const {
      user
    } = props;
    this.state = {
      name: user? user.attributes.given_name + ' ' + user.attributes.family_name : '',
      userId: user? user.username : '',
      attributes:  user? user.attributes: {},
      isProcessing: false
    };
  }
  
  goBack=()=>{
    const {
      history
    } = this.props;
    SoundManager.playNumber();
    history.goBack();
  }
  
  goToHelp=()=>{
    SoundManager.playNumber();
    this.navigateTo('help');
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  openChangePassword=()=>{
    SoundManager.playNumber();
    this.navigateTo('changepassword');
  }
  
  onPressUpdate=()=>{
    SoundManager.playPlay();
    const nameFirst = document.getElementById('personal-first-name').value;
    const nameLast = document.getElementById('personal-last-name').value;
    const email = document.getElementById('personal-email').value;
    const mobilePrefix = document.getElementById('personal-mobile-prefix').value;
    const mobile = document.getElementById('personal-mobile').value;
    const address = document.getElementById('personal-address').value;
    const city = document.getElementById('personal-city').value;
    const postcode = document.getElementById('personal-postcode').value;
    const state = document.getElementById('personal-state').value;
    const country = document.getElementById('personal-country').value;
    
    if (nameFirst === '') {
      ToastsStore.warning(LanguageData.getText('AccountProfile', 'ap_003'));
    }    
    if (nameLast === '') {
      ToastsStore.warning(LanguageData.getText('AccountProfile', 'ap_004'));
    }
    if (email === '') {
      ToastsStore.warning(LanguageData.getText('AccountProfile', 'ap_005'));
    }
    if (String.isEmailFormat(email) === false) {
      ToastsStore.warning(LanguageData.getText('webapp', 'webapp_022'));
    }
    if (country === '') {
      ToastsStore.warning(LanguageData.getText('AccountProfile', 'ap_002'));
    }
    
    this.updateUserInfo(nameFirst, nameLast, email, mobilePrefix, mobile, address, city, postcode, state, country);
  }
  
  async updateUserInfo(nameFirst, nameLast, email, prefix, mobile, address, city, postcode, state, country) {
    const {
      user
    } = this.props;
    if (user) {
      this.setState({
        isProcessing: true
      });
      const params = {
        'given_name': nameFirst,
        'family_name': nameLast,
        'email': email,
        'phone_number': prefix + mobile,
        'address': address,
        'zoneinfo': postcode,
        'custom:prefix': prefix,
        'custom:city': city,
        'custom:state': state,
        'custom:country': country
      };
      try {
        await Auth.updateUserAttributes(user, params);
        
        const userUpdated = await Auth.currentAuthenticatedUser({
          bypassCache: true
        });
        await this.props.setUser(userUpdated);
        ToastsStore.warning(LanguageData.getText('AccountProfile', 'ap_020'));
      }catch(err) {
        ToastsStore.warning('Error: ' + err.message);
      }
      this.setState({
        isProcessing: false
      });
    }else {
      ToastsStore.warning(LanguageData.getText('webapp', 'webapp_026'));
    }
  }
  
  renderTitle() {
    return (
      <section id="page-header" className="account-title-sec">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{LanguageData.getText('AccountProfile', 'ap_001')}</span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center" style={Instyle.WhiteText}
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>
                {/*<li><a className="justify-content-center" style={Instyle.WhiteText}
                       onClick={this.goToHelp}><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>*/}	
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderAvatar() {
    const { name, userId } = this.state;
    const id = LanguageData.getText('InitialLoginWithFingerprint', 'ilwfp_003').replace("<id>", userId);
    return (
      <div className="col-md-4">
        <article className="profile-img-con">
          <section>
            <div className="profile-avatar">
              {/*<a href="#" class="avatat-edit-icon"><i class="icon-pen"></i></a>*/}
              <img src="images/icons/avatar.jpg" alt="Avatar" />
            </div>
            <div className="avatar-detail">
              <span className="avatar-name">{name}</span>
              <span className="avatar-id">{id}</span>
            </div>
          </section>
        </article>
      </div>
    );
  }
  
  renderInput() {
    const { attributes } = this.state;
    return (
      <div className="col-md-8">
        <section className="profile-field-con">
          <ProfilePersonal attributes={attributes} changePassword={this.openChangePassword} />
          <ProfileAddress attributes={attributes} />
        </section>
      </div>
    );
  }
  
  render() {
    const {
      isProcessing
    } = this.state;
    return (
      <React.Fragment>
        <div className="stretched side-panel-left pbc-body pbc-acount-bg">
          <div className="body-overlay"></div>
          <Sidebar navigateTo={this.navigateTo} />

          <div id="wrapper" className="clearfix bg-transparent">
            <NewNavBar navigateTo={this.navigateTo} />
            {this.renderTitle()}

            <section id="content" className="bg-transparent">
              <div className="content-wrap nopadding nomargin">
                <div className="section pbc-section nobg nomargin pt-4">
                  <div className="container clearfix">
                    <div className="row clearfix mb-3">
                      {this.renderAvatar()}
                      {this.renderInput()}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="psersonal-update-btn-con center">
            <button className="button nomargin pbc-btn" id="btn-update" name="btn-update" value="update"
                    onClick={this.onPressUpdate}>
              <div style={{display:'flex', justifyContent:'center'}}>
                <LoadSpinner isProcessing={isProcessing}/>
                <span>{LanguageData.getText('AccountProfile', 'ap_018')}</span>
              </div>
            </button>
          </div>

          <div id="gotoTop" className="icon-angle-up"></div>
          <ToastsContainer store={ToastsStore} 
                           position={ToastsContainerPosition.TOP_RIGHT} />
          <ScriptLoader scriptArray={[
            {id: 'jquery', src:"./js/jquery.js"},
            {id: 'plugins', src:"./js/plugins.js"},
            {id: 'insightCollapse', src:"./js/html-script-insightCollapse.js"},
            {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
            {id: 'insightAlignment', src:"./js/html-script-insightAlignment.js"},
            {id: 'functions', src:"./js/functions.js"}
          ]} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setUser: bindActionCreators(setUser, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);