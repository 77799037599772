import React from 'react';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';

class FavouriteItem extends React.Component {
  openDetail=()=>{
    const {
      favouriteData,
      openDetail
    } = this.props;
    SoundManager.playNumber();
    openDetail(favouriteData);
  }
  
  render() {
    const {
      favouriteData
    } = this.props;
    return (
      <div className="fav-item-wrap">
        <div className="row align-items-center">
          <div className="col-md-9">
            <div className="list-title">
              <h4>{favouriteData.Name}</h4>
              <span>{favouriteData.Numbers.length} {LanguageData.getText('webapp', 'webapp_016')}</span>
            </div>
          </div>
          <div className="col-md-3">
            <a className="button nomargin btn-block pbc-btn pbc-btn-pink"
               onClick={this.openDetail}>
              <span>{LanguageData.getText('webapp', 'webapp_017')}</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

FavouriteItem.defaultProps =  {
  favouriteData: {},
  openDetail: null
};

export default FavouriteItem;