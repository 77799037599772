import React from 'react';
import { 
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Amplify from 'aws-amplify';
import aws_export from './config/aws-exports';
import FirebaseHelper from './helper/firebase';
import PlayAPI from './api/playAPI';
import SoundManager from './helper/soundManager';
import SaveData from './helper/saveData';
import { setInboxes } from './actions/common';

import Login from './screen/initial/login';
import LoginWithPIN from './screen/initial/loginWithPIN';
import ForgotPassword from './screen/initial/forgotPassword';
import ResetPassword from './screen/initial/resetPassword';

import SetupPincode from './screen/auth/setupPincode';

import Main from './screen/game/main';
import Insights from './screen/game/insights';
import Help from './screen/game/help';
import ResultHistory from './screen/game/resultHistory';
import ResultDetails from './screen/game/resultDetails';
import ResultTop10 from './screen/game/resultTop10';
import PlayNew from './screen/game/playNew';
import FavouriteCreate from './screen/game/favouriteCreate';
import MyFavourite from './screen/game/myFavourite';
import FavouriteDetails from './screen/game/favouriteDetails';
import PaymentSuccessful from './screen/game/paymentSuccessful';
import InsightCreate from './screen/game/insightsCreate';

import Profile from './screen/account/profile';
import ChangePassword from './screen/account/changePassword';
import MyTicket from './screen/account/myTicket';
import MyTicketDetails from './screen/account/myTicketDetails';
import SelectTicket from './screen/account/selectTicket';
import MyWallet from './screen/account/myWallet';
import MyWalletAdd from './screen/account/myWalletAdd';
import MyWalletDetails from './screen/account/myWalletDetails';
import SendETH from './screen/account/sendETH';
import RevealSeedWords from './screen/account/revealSeedWords';
import Notifications from './screen/account/notifications';
import Settings from './screen/account/settings';
import ReferralRewards from './screen/account/referralRewards';
import ChangePincode from './screen/account/changePincode';
import SmartPick from './screen/account/smartPick';
import SendFeedback from './screen/account/sendFeedback';
import LanguageSettings from './screen/account/languageSettings';
import TermsAndConditions from './screen/account/termsAndConditions';
import FAQ from './screen/account/faq';
import NotificationSettings from './screen/account/notificationSettings';
import SoundSettings from './screen/account/soundSettings';

import TutorialMain from './screen/tutorial/main';
import QuickTutorial from './screen/tutorial/quickTutorial';
import ScreenshotTutorial from './screen/tutorial/screenshotTutorial';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.initSound();
  }
  
  componentDidMount() {
    Amplify.configure(aws_export);
    
    try {
      FirebaseHelper.init();
      FirebaseHelper.getNotificationPermission();

      this.setNotificationListener();
    }catch(err) {
      console.log(err)
    }
  }
  
  async initSound() {
    SoundManager.init();
    const enableSound = await SaveData.getEnableSound();
    const enableMusic = await SaveData.getEnableMusic();
    SoundManager.setEnableEffect(enableSound);
    SoundManager.setEnableMusic(enableMusic);
  }
  
  setNotificationListener() {
    const messaging = FirebaseHelper.firebaseMessaging();
    messaging.onMessage(async (payload) => {
      const notification = JSON.parse(payload.data.notification);
      const notificationOptions = {
        body: notification.body,
        icon: '/logo192.png'
      };
      navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
        registration.showNotification(
          notification.title,
          notificationOptions
        )
      });
      console.log('Message received. ', payload);      
      const { user } = this.props;
      if (user) {
        const response = await PlayAPI.getInbox(user.username);
        if (response.status === 200) {
          const body = await response.json();
          if (body.Error === false) {
            this.props.setInboxes(body.Data, body);
          }
        }
      }
    });
  }
  
  render() {
    const {
      persistor
    } = this.props;
    return (
      <Router>
        <Route exact path='/' component={Login} />
        <Route path='/login' component={Login} />
        <Route path='/loginwithpin' component={LoginWithPIN} />
        <Route path='/forgotPassword' component={ForgotPassword} />
        <Route path='/resetpassword' component={ResetPassword} />

        <Route path='/setuppincode' component={SetupPincode} />
        
        <Route path='/main' component={Main} />
        <Route path='/insights' component={InsightCreate} />
        <Route path='/help' component={Help} />
        <Route path='/playnew' component={PlayNew} />
        <Route path='/favouritecreate' component={FavouriteCreate} />
        <Route path='/myfavourite' component={MyFavourite} />
        <Route path='/favouritedetails' component={FavouriteDetails} />
        <Route path='/paymentsuccessful' component={PaymentSuccessful} />
        <Route path='/resulthistory' component={ResultHistory} />
        <Route path='/resultdetails' component={ResultDetails} />
        <Route path='/resulttop10' component={ResultTop10} />

        <Route path='/profile' component={Profile} />
        <Route path='/changepassword' component={ChangePassword} />
        <Route path='/myticket' component={MyTicket} />
        <Route path='/myticketdetails' component={MyTicketDetails} />
        <Route path='/selectticket' component={SelectTicket} />
        <Route path='/mywallet' component={MyWallet} />
        <Route path='/mywalletadd' component={MyWalletAdd} />
        <Route path='/mywalletdetails' component={MyWalletDetails} />
        <Route path='/sendeth' component={SendETH} />
        <Route path='/revealseedwords' component={RevealSeedWords} />
        <Route path='/notifications' component={Notifications} />
        <Route path='/settings' render={(props) => (<Settings {...props} persistor={persistor} />)} />
        <Route path='/referralrewards' component={ReferralRewards} />
        <Route path='/changepincode' component={ChangePincode} />
        <Route path='/smartpick' component={SmartPick} />
        <Route path='/sendfeedback' component={SendFeedback} />
        <Route path='/languagesettings' component={LanguageSettings} />
        <Route path='/termsandconditions' component={TermsAndConditions} />
        <Route path='/faq' component={FAQ} />
        <Route path='/notificationSettings' component={NotificationSettings} />
        <Route path='/soundsettings' component={SoundSettings} />
        
        <Route path='/tutorial' component={TutorialMain} />
        <Route path='/quicktutorial' component={QuickTutorial} />
        <Route path='/screenshottutorial' component={ScreenshotTutorial} />
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setInboxes: bindActionCreators(setInboxes, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
