export const DecimalPointsSettings = 6;

export const API_URL_STAGE = 'https://stage-api.aethergames.xyz/maethergames'; //FOR STAGE
export const API_URL_PROD = 'https://api.aethergames.xyz/maethergames'; // FOR PRODUCTION
export const API_URL = API_URL_PROD;

export const TC_JSON_FILE = 'https://asset.aethergames.xyz/tc.json';
export const FAQ_JSON_FILE = 'https://asset.aethergames.xyz/faq.json';
export const BETA_AGREEMENT_JSON_FILE = 'https://asset.aethergames.xyz/web/beta-agreement.json';

export const BETA_REGISTRATION_LINK = 'https://play.aethergames.xyz/signup';

export const VERSION_NUMBER = '1.0.15';

export const MAXIMUM_WALLET_COUNT = 3;

export const DEFAULT_LANGUAGE = 'en';
export const LINK_LANGUAGE_JSON = {
  en: 'https://asset.aethergames.xyz/apk/webapp_language_en.json',
  zh: 'https://asset.aethergames.xyz/apk/webapp_language_ch.json',
};
export const LANGUAGE_LIST = [
  {code: 'en', name: 'English'},
  {code: 'zh', name: '中文'},
];

export const PINCODE_LENGTH = 6;

export const GET_GAME_INTERVAL = 10000;

export const SELECT_TICKET_INFO = [
  {objectId: "nN76u5MnNn", GameType: "5/42"},
  {objectId: "ddntFCujc0", GameType: "6/45"},
  {objectId: "i45ottuqwk", GameType: "2/37"},
];

export const MINIMUM_YEAR = 2018;

export const DefaultEnableSoundEffect = true;
export const DefaultEnableSoundMusic = true;

export const LinkPrizeJSON = 'https://asset.aethergames.xyz/apk/prizes.json';

export const InsightFavouriteName = 'Lucky Tickets';