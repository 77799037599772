const InStyle = {
  YellowText: {
    color: '#F2F941', 
    cursor:'pointer'
  },
  WhiteText: {
    color: '#FFFFFF', 
    cursor:'pointer'
  },
  CursorPointer: {
    cursor:'pointer'
  }
};

export default InStyle;