import React from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

class IconLoader extends React.Component {
  render() {
    const {
      type,
      color,
      width,
      height,
      timeout,
      padding,
      isProcessing
    } = this.props;
    return (
      <div style={{display:'flex', justifyContent:'center', padding: padding}}>
        <Loader type={type}
                color={color}
                width={width}
                height={height}
                timeout={timeout}
                visible={isProcessing} /> 
      </div>
    );
  }
}

IconLoader.defaultProps = {
  type: "Oval",
  color: "#ffffff",
  width: 30,
  height: 30,
  timeout: 0,
  padding: 20,
  isProcessing: false
};

export default IconLoader;