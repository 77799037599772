import React from 'react';
import { connect } from 'react-redux';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import PlayPaymentSection from '../../component/card/playPaymentSection';
import PlayTicket from '../../component/listItem/playTicket';
import ScriptLoader from '../../component/scriptLoader';
import Utils from '../../helper/utils';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import PlayAPI from '../../api/playAPI';
import Instyle from '../../theme/instyle';
import {DecimalPointsSettings} from '../../config/constant';
import {USER_NUMBER_TYPE} from '../../config/enum';

class FavouriteDetails extends React.Component {
  constructor(props) {
    super(props);
    const {
      favouriteData
    } = this.props.location.state;
    this.state = {
      name: favouriteData.Name,
      edited: false,
      editingIndex: -1,
      tickets: [],
      freeStatus: [],
      isProcessing: false
    };
    this.originalFreeTicketAmount = 0;
  }
  
  async componentDidMount() {
    const {
      user
    } = this.props;
    if (user) {
      const response = await PlayAPI.getFreeTicketById(user.username);
      if (response.status === 200) {
        const body = await response.json();
        if (!body.Error) {
          let freeTicket = 0;
          const data = body.Data.TicketsData;
          const current = new Date().getTime();
          for (let i=0; i<data.length; i++) {
            if (data[i].ExpireDate > current) {
              freeTicket += data[i].Tickets;
            }
          }        
          this.originalFreeTicketAmount = freeTicket;
        }
      }

      const {
        favouriteData
      } = this.props.location.state;
      const tickets = favouriteData.Numbers;
      let favTicket = [];
      let freeStatus = [];
      for (let i=0; i<tickets.length; i++) {
        favTicket.push(tickets[i]);
        freeStatus.push(false);
      }
      await this.setState({
        tickets: favTicket,
        freeStatus: freeStatus
      });
    }
  }
  
  setTicketFreeStatus=(index)=>{
    let {freeStatus} = this.state;
    if (this.getFreeTicketAmount() === this.originalFreeTicketAmount && freeStatus[index] === false) {
      ToastsStore.warning(LanguageData.getText('CardsTicketCard', 'ctc_001'));
      return;
    }
    
    freeStatus[index] = !freeStatus[index];
    this.setState({
      freeStatus: freeStatus
    });
  }
  
  setTicketRandomNumber=(index)=>{
    
  }
  
  editTicket = async(index, ticketRef) => {
    await this.setState({
      editingIndex: index,
      edited: true
    });
    ticketRef.click();
  }
  
  deleteTicket=(index)=>{
    let {tickets, freeStatus} = this.state;
    if (tickets.length > 1) {
      tickets.splice(index, 1);
      freeStatus.splice(index, 1);
      this.setState({
        tickets: tickets,
        freeStatus: freeStatus,
        edited: true
      });
    }
  }
  
  onPressKey=(index, number)=>{
    const {
      gameData
    } = this.props.location.state;
    const {
      BallsCount
    } = gameData;
    let { tickets } = this.state;
    if (tickets[index].includes(number)) {
      //DESELECT THE NUMBER IF ALREADY SELECTED
      const numberIndex = tickets[index].indexOf(number);
      tickets[index].splice(numberIndex, 1);
    }else {
      //SELECT A NEW NUMBER IF THERE'S STILL EMPTY SLOT
      if (tickets[index].length < BallsCount) {
        tickets[index].push(number);
      }
    }
    tickets[index].sort(function(a, b){return a-b});
    this.setState({
      tickets: tickets
    });
  }
  
  getFreeTicketAmount() {
    const {
      freeStatus
    } = this.state;
    let amount = 0;
    for (let i=0; i<freeStatus.length; i++) {
      if (freeStatus[i] === true) {
        amount++;
      }
    }
    return amount;
  }
  
  deleteAllTicket=()=>{
    SoundManager.playNumber();
  }
  
  handleAddTicket=async()=>{
    SoundManager.playNumber();
    const {
      favouriteData
    } = this.props.location.state;
    if (favouriteData.Type === USER_NUMBER_TYPE.FAVORITE) {
      let {tickets,  freeStatus} = this.state;
      tickets.push([]);
      freeStatus.push(false);
      await this.setState({
        tickets: tickets,
        freeStatus: freeStatus
      });
      var objDiv = document.getElementById("scrollview");
      objDiv.scrollTop = objDiv.scrollHeight;
    }else {
      const {
        history
      } = this.props;
      const {
        gameData
      } = this.props.location.state;
      history.replace({
        pathname: '/insights',
        state: {
          gameData,
          saveChanges: false
        }
      });
    }
  }
  
  playSound=()=>{
    SoundManager.playNumber();
  }
  
  tryBuyTicket = async(pincode) => {
    let {tickets, freeStatus} = this.state;
    if (tickets.length === 0) {
      ToastsStore.warning(LanguageData.getText('GamePlayNew', 'gpn_001'));
      return;
    }
    
    const {
      gameData
    } = this.props.location.state;
    const {
      BallsCount
    } = gameData;
    for (let i=0; i<tickets.length; i++) {
      if (tickets[i].length < BallsCount) {
        const message = LanguageData.getText('GamePlayNew', 'gpn_002').replace("<number>", BallsCount);
        ToastsStore.warning(message);
        return;
      }
    }
    
    const response = await PlayAPI.checkPINCode(this.props.user.username, pincode);
    const body = await response.json();
    if (response.status !== 200  || body.Data === false) {
      ToastsStore.error(LanguageData.getText('GamePlayNew', 'gpn_003'));
      return;
    }
    
    this.setState({ isProcessing: true });
    
    let paidTicket = [];
    let freeTicket = [];
    for (let i=0; i<freeStatus.length; i++) {
      if (freeStatus[i] === true) {
        freeTicket.push(tickets[i]);
      }else {
        paidTicket.push(tickets[i]);
      }
    }
    let params = {
      AccountId: gameData.AccountId,
      UserId: this.props.user.username,
      GameId: gameData.objectId,
      Items: paidTicket,
    };
    
    let managedToBuyPaid = false;
    let managedToBuyFree = false;
    if (paidTicket.length > 0) {
      managedToBuyPaid = await this.buyTicket(params);
    }else {
      managedToBuyPaid = true;
    }
    if (freeTicket.length > 0) {
      if (managedToBuyPaid) {
        params.PromoCode = 'FREE';
        params.UseFreeTicket = true;
        params.Items = freeTicket;
        managedToBuyFree = await this.buyTicket(params);
      }
    }else {
      managedToBuyFree = true;
    }
    
    this.setState({ isProcessing: false });
    if (managedToBuyPaid === true && managedToBuyFree === true) {
      this.props.history.push('/paymentsuccessful');
    }
  }
  
  async buyTicket(params) {
    try {
      if (typeof params.AccountId !== 'string' ||
          typeof params.UserId !== 'string' ||
          typeof params.GameId !== 'string') {
        throw {message: LanguageData.getText('GamePlayNew', 'gpn_006')};
      }
      
      const response = await PlayAPI.buyTicket(params);
      if (response.status !== 200) {
        const body = await response.json();
        if (body.Error) {
          ToastsStore.error(body.ErrorDesc.message);
        }
        return false;
      } else {
        return true;
      }
    } catch (e) {
      ToastsStore.error(e.message);
      return false;
    }
  }
  
  deleteFavourite = async() => {
    const {
      gameData,
      favouriteData
    } = this.props.location.state;
    SoundManager.playNumber();
    const response = await PlayAPI.deleteFavourite(favouriteData.objectId);
    if (response.status === 200) {
      const {
        history
      } = this.props;
      history.push({
        pathname: '/myfavourite',
        state: {gameData: gameData}
      });
    }
  }
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  goBack=()=>{
    this.props.history.goBack();
  }
  
  renderTitle() {
    const {
      gameData
    } = this.props.location.state;
    const {
      GameName,
      GameType,
      JackpotValue,
    } = gameData;
    return (
      <section id="page-header">
        <div className="container clearfix header-container">
          <div className="row clearfix">
            <div className="col-md-9">
              <span className="award-title">{GameName} : <span className="award-amount">{parseFloat(JackpotValue).toFixed(DecimalPointsSettings)}ETH</span></span>
            </div>
            <div className="col-md-3">
              <ul className="top-nav-list">
                <li><a className="justify-content-center" style={Instyle.WhiteText} 
                       onClick={this.goBack}><span><img src="images/pbc/back.png" /> {LanguageData.getText('webapp', 'webapp_004')}</span></a></li>	
                <li><a className="justify-content-center" 
                       style={Instyle.WhiteText} ><span><img src="images/pbc/question.png" /> {LanguageData.getText('webapp', 'webapp_005')}</span></a></li>	
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  renderHeader() {
    const { name } = this.state;
    return (
      <header className="new-ticket-header">
        <div className="row align-items-center">
          <div className="col-md-4">
            <div className="fav-title-wrap">
              <h4 className="nomargin">{name} {/*<a className="text-yellow" style={Instyle.YellowText} onClick={this.playSound}><i className="icon-edit"></i></a>*/}</h4>
            </div>
          </div>
          <div className="col-md-8">
            <ul className="header-option clearfix">
              <li>
                <a className="btn-trush" style={Instyle.WhiteText}
                   onClick={this.deleteFavourite}><i><img src="images/pbc/button-trush.png" /></i><span className="d-none d-sm-block">{LanguageData.getText('webapp', 'webapp_008')}</span></a>
              </li>
            </ul>	
          </div>
        </div>
      </header>
    );
  }
  
  renderTicket=(item, index)=>{
    const {
      gameData,
      favouriteData
    } = this.props.location.state;
    const {
      BallsCount,
      TotalBalls
    } = gameData;
    const {
      freeStatus,
      editingIndex
    } = this.state;
    return (
      <PlayTicket key={index}
                  index={index} 
                  selectedNumbers={item}
                  isFree={freeStatus[index]}
                  onPressKey={index === editingIndex? this.onPressKey : null}
                  setFreeStatus={this.setTicketFreeStatus}
                  setRandomNumber={this.setTicketRandomNumber}
                  onPressEdit={this.editTicket}
                  deleteTicket={this.deleteTicket}
                  ballCount={BallsCount}
                  maxNumber={TotalBalls}
                  ticketType={favouriteData.Type} />
    );
  }
  
  render() {
    const {
      gameData
    } = this.props.location.state;
    const {
      tickets,
      isProcessing
    } = this.state;
    const ticketList = tickets.map(this.renderTicket);
    const freeTicketAmount = this.getFreeTicketAmount();
    const freeTicketLeft = this.originalFreeTicketAmount - this.getFreeTicketAmount();
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          {this.renderTitle()}
          
          <section id="content" className="bg-transparent">
			<div className="content-wrap nopadding nomargin">
              <div className="section pbc-section nobg notoppadding nomargin">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">
                    <PlayPaymentSection gameData={gameData}
                                        ticketAmount={tickets.length}
                                        freeTicketAmount={freeTicketAmount}
                                        buyTicket={this.tryBuyTicket}
                                        isProcessing={isProcessing} />
                    <div className="col-md-8">
                      <section className="main-content clearfix">
                        {this.renderHeader()}
                        <div className="new-ticket-body">
                          <div className="content">
                            <div className="demo">
                              <div id="scrollview" styles={{ height: '100px', overflowY: 'scroll' }}>
                                <div id="accordion" role="tablist">
                                  {ticketList}
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="new-ticket-footer">
                          <span className="ticket-count"><b>{freeTicketLeft}</b> {LanguageData.getText('CardsTicketCard', 'ctc_006')}</span>
                          <a className="add-ticket" onClick={this.handleAddTicket}><i className="add-ticket-btn"></i>{LanguageData.getText('webapp', 'webapp_009')}</a>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        
        <div id="gotoTop" className="icon-angle-up"></div>
        <ToastsContainer store={ToastsStore} 
                         position={ToastsContainerPosition.TOP_RIGHT} />
        <ScriptLoader scriptArray={[
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteDetails);