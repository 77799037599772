import React from 'react';
import BigNumber from 'bignumber.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import FirebaseHelper from '../../helper/firebase';

import Sidebar from '../../component/navigation/sidebar';
import NavigationBar from '../../component/navigation/navigationBar';
import NewNavBar from '../../component/navigation/NewNavBar';
import MainPageHeader from '../../component/card/mainPageHeader';
import CarouselMain from '../../component/carousel/main';
import GameList from '../../component/card/gameList';
import ScriptLoader from '../../component/scriptLoader';
import PlayAPI from '../../api/playAPI';
import SaveData from '../../helper/saveData';
import UserData from '../../helper/userData';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import { setUser, setWallet, setGames, setInboxes, setEnableSound, setEnableMusic } from '../../actions/common';
import { GET_GAME_INTERVAL, LINK_LANGUAGE_JSON } from '../../config/constant';

const styles = {
  hideCarousel: {
    position: 'absolute',
    left: '-2000px',
    width: 0,
    height: 0
  },
};

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gameList: [],
      updateTimer: true,
      currentMode: 0,
    };
    this.usdPerEth = 0;
    this.mounted = false;
  }
  
  async componentDidMount() {
    SoundManager.init();
    this.props.setEnableMusic(SoundManager.enableMusic);
    this.props.setEnableSound(SoundManager.enableEffect);
    SoundManager.playMusicBackground();
    
    this.mounted = true; 
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      if (this.props.user === null || user.username !== this.props.user.username) {
        this.props.setUser(user);
      }
      UserData.setUser(user);
    }catch(err) {
      this.navigateTo('');
    }
    
    if (user) {
      //console.log('user.username', user.username)
      const havePIN = await SaveData.getAgreedToPinCode(user.username);
      if (havePIN) {
        this.updateTimer();
        await this.setLanguage();
        await this.getGame();
        await this.getConversionValue();
        if (!this.props.wallet) {
          await this.getWallet(user.username);
        }
        await this.getInbox(user.username);
        
        try {
          const firebaseMessaging = FirebaseHelper.firebaseMessaging();
          const firebaseToken = await firebaseMessaging.getToken();
          console.log('Firebase Token', firebaseToken)
          await PlayAPI.setUserDevice(user.username, firebaseToken);
        }catch(err) {
          console.log(err)
        }
        
        setInterval(()=>{ this.getGame() }, GET_GAME_INTERVAL);
      }else {
        this.navigateTo('setuppincode', {
          userId: user.username 
        });
      }
    }
  }
  
  componentWillUnmount() {
    this.mounted = false;
  }
  
  updateTimer=()=>{
    if (this.state.updateTimer === true) {
      const dt = new Date();
      const ms = 1000 - dt.getMilliseconds();
      setTimeout(()=>{
        if (this.state.updateTimer) {
          this.setState(()=>{
            return this.state;
          }, this.updateTimer);
        }        
      }, ms);
    }
  }
  
  async setLanguage() {
    const savedLanguage = await SaveData.getLanguageSettings();
    LanguageData.setLanguageCode(savedLanguage);
    const response = await fetch(LINK_LANGUAGE_JSON[savedLanguage]);
    if (response.status === 200) {
      const body = await response.json();
      LanguageData.setJson(body);
    }
  }
  
  async getGame() {
    const response = await PlayAPI.getGame();
    if (response.status === 200) {
      const body = await response.json();
      if (body.Error === false) {
        this.props.setGames(body.Data);
        if (this.mounted) {
          this.setState({
            gameList: body.Data
          });
        }
      }
    }
  }
  
  async getConversionValue() {
    const response = await PlayAPI.checkETHValue();
    if (response.status === 200) {
      const body = await response.json();
      if (body.Error === false) {
        const rate = body.Data.rate? body.Data.rate.usd : 0;
        this.usdPerEth = rate;
      }
    }
  }
  
  async getWallet(userId) {
    let wallet = null;
    const resGetWallet = await PlayAPI.getWallet(userId);
    if (resGetWallet.status === 200) {
      const bodyGetWallet = await resGetWallet.json();
      if (bodyGetWallet.Data.length === 0) {
        const resAddWallet = await PlayAPI.addWallet(userId);
        const resGetWallet2 = await PlayAPI.getWallet(userId);
        if (resGetWallet2.status === 200) {
          const bodyGetWallet2 = await resGetWallet2.json();
          wallet = bodyGetWallet2.Data[0];
        }
      }else {
        wallet = bodyGetWallet.Data[0];
      }
    }
    
    if (wallet !== null) {
      let balanceList = [];
      let USDBalanceList = [];
      for (let i=0; i<wallet.WalletAddresses.length; i++) {
        const resBalance = await PlayAPI.getWalletBalance(userId, i);
        if (resBalance.status === 200) {
          const bodyBalance = await resBalance.json();
          if (bodyBalance.Error === false && bodyBalance.TotalRows > 0) {
            const conversionRate = new BigNumber(this.usdPerEth);
            const balance = bodyBalance.Data.balance? bodyBalance.Data.balance : 0;
            balanceList.push(balance);
            USDBalanceList.push(conversionRate.multipliedBy(balance).toFixed());
          }
        }
      }
      wallet.WalletBalance = balanceList;
      wallet.USDBalance = USDBalanceList;
      await this.props.setWallet(wallet);
    }
  }
  
  async getInbox(userId) {
    const response = await PlayAPI.getInbox(userId);
    if (response.status === 200) {
      const body = await response.json();
      if (body.Error === false) {
        this.props.setInboxes(body.Data, body);
      }
    }
  }
  
  openGamePlay=(gameData)=>{
    this.navigateTo('playnew', {gameData: gameData, useFreeTicket: false});
  }
  
  openGameResult=(gameData)=>{
    this.navigateTo('resulthistory', {gameData: gameData});
  }
  
  openInsights=(gameData)=>{
    this.navigateTo('insights', {gameData: gameData, saveChanges: true});
  }
  
  switchModeList=()=>{
    this.setState({
      currentMode: 1
    });
  }
  
  switchModeCarousel=()=>{
    this.setState({
      currentMode: 0
    });
  }
  
  navigateTo=(page, params)=>{
    this.setState(()=>{
      return {updateTimer: false};
    }, ()=>{
      const {
        history
      } = this.props;
      history.push({
        pathname: '/' + page,
        state: params
      });
    });
  }
  
  render() {
    const {
      history
    } = this.props;
    const {
      gameList,
      currentMode
    } = this.state;
    const carouselStyle = currentMode === 1? styles.hideCarousel : null;
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar history={this.props.history} navigateTo={this.navigateTo} />
          <MainPageHeader 
            current={currentMode}
            onClickCarousel={this.switchModeCarousel}
            onClickList={this.switchModeList} />
          {gameList.length > 0?
            <section id="content" className="bg-transparent">
              <div className="content-wrap nopadding nomargin">
                <div className="section pbc-section nobottommargin notopmargin nobg">
                  <div className="container clear-bottommargin clearfix">
                    
                    <div className="row clearfix"
                         style={carouselStyle}>
                      <div className="col-12 nopadding">

                        <CarouselMain gameList={gameList}
                                      openGame={this.openGamePlay}
                                      openResult={this.openGameResult}
                                      openInsight={this.openInsights} />
                      </div>
                    </div> 
                    {currentMode === 1?
                      <GameList gameList={gameList}
                                openGame={this.openGamePlay}
                                openResult={this.openGameResult}
                                openInsight={this.openInsights} />
                      : null
                    }
                  </div>
                </div>
              </div>
            </section> : null
            }
        </div>
        {gameList.length > 0?
          <ScriptLoader scriptArray={[
            {id: 'jquery', src:"./js/jquery.js"},
            {id: 'plugins', src:"./js/plugins.js"},
            {id: 'mislider', src:"./css/carousel/js/mislider.js"},
            {id: 'slider', src:"./js/html-script-slider.js"},
            {id: 'functions', src:"./js/functions.js"},
          ]} /> : null
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user,
  wallet: state.common.wallet,
  games: state.common.games,
  enableSound: state.common.enableSound,
  enableMusic: state.common.enableMusic,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setUser: bindActionCreators(setUser, dispatch),
  setWallet: bindActionCreators(setWallet, dispatch),
  setGames: bindActionCreators(setGames, dispatch),
  setInboxes: bindActionCreators(setInboxes, dispatch),
  setEnableSound: bindActionCreators(setEnableSound, dispatch),
  setEnableMusic: bindActionCreators(setEnableMusic, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);