export const CONFIG = {
  apiKey: "AIzaSyDVyX-ek0tboBmlxKdBKJpcPG0jh0eNYz0",
  authDomain: "aethermobileapp.firebaseapp.com",
  databaseURL: "https://aethermobileapp.firebaseio.com",
  projectId: "aethermobileapp",
  storageBucket: "aethermobileapp.appspot.com",
  messagingSenderId: "105717082549",
  appId: "1:105717082549:web:eba7c07dd4126f799451fc"
};

export const WPC_KEY_PAIR = "BDOolo0HApmHvp_Vad5PF6JO2Qlaa4C1xyTogyFkH5QruWghr1mAlQqiQ_8g3wuaQ7XYDNo9Ovzq23ZRPnI38Yw";