import React from 'react';

import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
  }
  
  onChangeEmail=(evt)=>{
    this.setState({
      email: evt.target.value
    });
  }
  
  onChangePassword=(evt)=>{
    this.setState({
      password: evt.target.value
    });
  }
  
  handleLogin=()=>{
    const {
      email,
      password
    } = this.state;
    SoundManager.playPlay();
    this.props.loginUser(email, password);
  }
  
  handleForgotPassword=()=>{
    SoundManager.playNumber();
    this.props.navigateTo('forgotPassword');
  }
  
  handleOpenCode=()=>{
    const {
      openPinCode
    } = this.props;
    SoundManager.playNumber();
    openPinCode();
  }
  
  render() {
    const {
      user
    } = this.props;
    const {
      email,
      password
    } = this.state;

    return (
      <div className="tab-content clearfix" id="tabs-2">
        <div className="login-form mb-3">
          <div className="col_full col_mb">
            <input type="email" id="login-form-email" name="login-form-email" className="form-control not-dark" 
                   value={email} placeholder={LanguageData.getText('InitialLogin', 'il_007')} onChange={this.onChangeEmail} />
          </div>

          <div className="col_full col_mb">
            <input type="password" id="login-form-password" name="login-form-password" className="form-control not-dark" 
                   value={password} placeholder={LanguageData.getText('InitialLogin', 'il_008')} onChange={this.onChangePassword} />
          </div>

          <div className="col_full col_mb">
            <a className="fright text-yellow" style={Instyle.YellowText}
               onClick={this.handleForgotPassword}>{LanguageData.getText('InitialLogin', 'il_009')}</a>
            <div className="clearfix"></div>
          </div>

          <div className="col_full nobottommargin">
            <button className="button nomargin btn-block pbc-btn" id="login-form-submit" name="login-form-submit" 
                    value="login" onClick={this.handleLogin}>
              <span>{LanguageData.getText('InitialLogin', 'il_010')}</span>
            </button>
          </div>
          <div className="col_full mt-4 nobottommargin clearfix">
            <span className="float-left"></span>
            {user? 
              <span className="float-right"><a className="text-yellow" style={Instyle.YellowText} 
                                               onClick={this.handleOpenCode}>{LanguageData.getText('InitialLogin', 'il_012')}</a></span> : 
              null
            }
          </div>
        </div>
      </div>
    );
  }
}

Login.defaultProps = {
  user: null,
  openPinCode: null
};

export default Login;