import React from 'react';

import InsightItemRow from './insightItemRow'
import LanguageData from '../../helper/languageData';

class InsightGroup extends React.Component {
  onCheckedOption=(number)=>{
    const {
      onSelectNumber
    } = this.props;
    onSelectNumber(number);
  }
  
  renderOption=(item, index)=>{
    return (
      <InsightItemRow
        key={index}
        isChecked={item.selected}
        number={item.Number}
        frequency={item.Count}
        drawAt={item.DrawAt}
        onChecked={this.onCheckedOption} />
    );
  }
  
  render() {
    const {
      title,
      options
    } = this.props;
    const optionList = options.map(this.renderOption);
    
    return (
      <div className="oc-item">
        <div className="insight-item-container">
          <div className="insights-header">
            <div className="insights-title">
              <h5>{title}</h5>
            </div>
          </div>
          <div className="insights-body">
            <div className="ib-header">
              <div></div>
              <div><span>Number</span></div>
              <div><span>Frequency</span></div>
              <div><span>Last Drawn</span></div>
            </div>
            <div className="ib-body">
              {optionList}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InsightGroup.defaultProps = { 
  title: '',
  options: [],
  selectedNumber: [],
  onSelectNumber: ()=>{}
};

export default InsightGroup;