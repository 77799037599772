import React from 'react';
import BigNumber from 'bignumber.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Sidebar from '../../component/navigation/sidebar';
import NewNavBar from '../../component/navigation/NewNavBar';
import ScriptLoader from '../../component/scriptLoader';
import LanguageData from '../../helper/languageData';
import SoundManager from '../../helper/soundManager';
import PlayAPI from '../../api/playAPI';
import { setWallet } from '../../actions/common';

class PaymentSuccessful extends React.Component {    
  async componentDidMount(){
    SoundManager.playPurchase();
    setTimeout(this.backToMain, 5000);
  }  
  
  navigateTo=(page, params)=>{
    const {
      history
    } = this.props;
    history.push({
      pathname: '/' + page,
      state: params
    });
  }
  
  backToMain=()=>{
    this.navigateTo('main');
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="body-overlay"></div>
        <Sidebar navigateTo={this.navigateTo} />
        
        <div id="wrapper" className="clearfix bg-transparent">
          <NewNavBar navigateTo={this.navigateTo} />
          
          <section id="content" className="bg-transparent">
			<div className="content-wrap">
              <div className="section pbc-section nobg nomargin pt-3">
                <div className="container clear-bottommargin clearfix">
                  <div className="row clearfix">
                    <div className="col-md-12 center payment-seccussful">
                      <h1><span>{LanguageData.getText('GamePaymentSuccessful', 'gps_001')}</span>{LanguageData.getText('GamePaymentSuccessful', 'gps_002')}</h1>
                      <figure>
                        <img src="images/pbc/big_dollar.png"  
                             style={{cursor: 'pointer'}}
                             onClick={this.backToMain}/>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
			</div>
          </section>
        </div>
        
        <div className="pbc-wrap-footer-v2"></div>
        <ScriptLoader scriptArray={[
          {id: 'jquery', src:"./js/jquery.js"},
          {id: 'plugins', src:"./js/plugins.js"},
          {id: 'collapse', src:"./js/html-script-insightCollapse.js"},
          {id: 'scrollbar', src:"./js/jquery.scrollbar.js"},
          {id: 'alignment', src:"./js/html-script-insightAlignment.js"},
          {id: 'functions', src:"./js/functions.js"},
        ]} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.common.user,  
  wallet: state.common.wallet
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setWallet: bindActionCreators(setWallet, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessful);
//export default PaymentSuccessful;