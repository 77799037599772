import React from 'react';

import String from '../../helper/string';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import { PINCODE_LENGTH } from '../../config/constant';

class PINInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pincode: ''
    };
  }
  
  onInputPIN=(evt)=>{
    const pincode = evt.target.value;
    if (pincode.length > PINCODE_LENGTH || String.onlyContainsNumber(pincode) === false) {
      return;
    }
    
    this.setState({
      pincode: pincode
    });
  }
  
  handleEnter=(event)=>{
    event.preventDefault();
    this.submit();
  }
  
  submit=()=>{
    const {
      pincode
    } = this.state;
    const {
      onSubmit
    } = this.props;
    SoundManager.playPlay();
    onSubmit(pincode);
    this.setState({ pincode: '' });
  }
  
  onClose=()=>{
    const {
      onClose
    } = this.props;
    SoundManager.playNumber();
    onClose();
  }
  render() {
    const {
      title,
      text,
      buttonText
    } = this.props;
    const {
      pincode
    } = this.state;
    return (
      <div className="col-lg-3">
        <div className="card nobg divcenter noradius noborder">
          <div className="pbc-card-header center position-relative">
            <div className="btn-close" style={Instyle.CursorPointer} onClick={this.onClose}>
              <a><img src="images/pbc/button-close.png" /></a>
            </div>
            <h3>{title}</h3>
          </div>
          <div className="card-body pbc-card-body">
            <div className="center mb-3">
              <h4 className="text-white">{text}</h4>
            </div>
            <form className="login-form mb-3" onSubmit={this.handleEnter}>
              <div className="col_full col_mb">
                <input type="password" id="create-pin" name="create-pin" className="form-control not-dark" 
                       value={pincode} placeholder="" onChange={this.onInputPIN} />
              </div>
            </form>
            <button className="button nomargin btn-block pbc-btn" id="form-submit" name="form-submit"
                    value="login" onClick={this.submit}>
              <span>{buttonText}</span>
            </button>
          </div>									
        </div>
      </div>
    );
  }
}

PINInput.defaultProps = {
  title: '',
  text: '',
  buttonText: '',
  onClose: null,
  onSubmit: null
};

export default PINInput;