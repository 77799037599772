import React from 'react';
import LoadSpinner from '../button/loadSpinner';
import LanguageData from '../../helper/languageData';
import Instyle from '../../theme/instyle';

class SendFeedbackInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: ''
    };
  }
  
  onEditFeedback=(evt)=>{
    const {
      isProcessing
    } = this.props;
    if (!isProcessing) {
      this.setState({
        feedback: evt.target.value
      });
    }
  }
  
  submitFeedback=()=>{
    const {
      submit,
      isProcessing
    } = this.props;
    if (!isProcessing) {
      submit(this.state.feedback);
    }
  }
  
  render() {
    const {
      goBack,
      isProcessing
    } = this.props;
    return (
      <div className="col-lg-3" style={{maxWidth:'100%', flexBasis:'32%'}}>								
        <div className="card nobg divcenter noradius noborder">
            <div className="pbc-card-header center position-relative">
              <div className="btn-close">
                <a style={Instyle.CursorPointer}
                   onClick={goBack}><img src="images/pbc/button-close.png" /></a>
              </div>
              <h3>{LanguageData.getText('AccountSendFeedback', 'asfb_001')}</h3>
            </div>
            <div className="wallet-send-title">
              <div className="wallet-send-title-wrap">
                <div className="pt-3 pb-3">
                  <div><span className="text-white">{LanguageData.getText('AccountSendFeedback', 'asfb_004')}</span></div>
                  <div><span className="text-white">{LanguageData.getText('AccountSendFeedback', 'asfb_005')}</span></div>
                  <span style={{color:'#9e9e9e', fontSize:'smaller'}}>{LanguageData.getText('AccountSendFeedback', 'asfb_006')}</span>
                </div>
              </div>
            </div>

            <div className="card-body pbc-card-body border-top-0">
              <div className="mb-2">
                <div className="col_full">
                  <span className="text-white mb-2 d-block">{LanguageData.getText('AccountSendFeedback', 'asfb_007')}</span>
                  <textarea className="form-control seed-words-textarea" id="exampleFormControlTextarea1" rows="3" 
                            value={this.state.feedback} onChange={this.onEditFeedback}></textarea>
                </div>
                <div className="col_full nobottommargin">
                  <button className="button nomargin btn-block pbc-btn" id="change-password" name="change-password" value=""
                          onClick={this.submitFeedback}>
                    <div style={{display:'flex', justifyContent:'center'}}>
                      <LoadSpinner isProcessing={isProcessing} />
                      <span>{LanguageData.getText('AccountSendFeedback', 'asfb_009')}</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

SendFeedbackInput.defaultProps = {
  isProcessing: false,
  goBack: ()=>{},
  submit: ()=>{}
};

export default SendFeedbackInput;