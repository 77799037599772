import React from 'react';

import BuyTicketButton from '../button/buyTicketButton';
import LanguageData from '../../helper/languageData';
import DateTime from '../../helper/datetime';
import SoundManager from '../../helper/soundManager';
import Instyle from '../../theme/instyle';
import {DecimalPointsSettings, PINCODE_LENGTH} from '../../config/constant';

class PlayPaymentSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enterPin: false,
      pinNumber: '',
    };
  }
  
  onInputPin=(evt)=>{
    if (evt.target.value.length > PINCODE_LENGTH) {
      return;
    }
    
    this.setState({
      pinNumber: evt.target.value
    });
  }
  
  onPressBuy=()=>{
    SoundManager.playPlay();
    if (this.state.enterPin === false) {
      this.setState({
        enterPin: true
      });
    }else {
      this.props.buyTicket(this.state.pinNumber);
    }
  }
  
  addToFavourite=()=>{
    const {
      addToFavourite
    } = this.props;
    SoundManager.playNumber();
    addToFavourite();
  }
  
  forgotPIN=()=>{
    SoundManager.playNumber();
  }
  
  renderInfo() {
    const {
      gameData,
      ticketAmount
    } = this.props;
    const objReplace = {"<draw number>" : gameData.DrawNumber, "<date>" : DateTime.getDateFromMS(gameData.DrawAt)};
    const draw = LanguageData.getText('GamePaymentFingerprintVerify', 'gpfpv_005').replace(/<draw number>|<date>/gi, function(matched){ 
      return objReplace[matched]; 
    })
    return (
      <div className="sidebar-body-con">
        <span className="ticket-title">{gameData.GameName} : <span className="total-reward">{parseFloat(gameData.JackpotValue).toFixed(DecimalPointsSettings)}ETH</span></span>
        <span className="ticket-date">{draw}</span>
        <span className="tickets">{LanguageData.getText('CardsTicketCard', 'ctc_003')}<span className="total-ticket">{ticketAmount}</span></span>
        <span className="cost-tickets">{LanguageData.getText('webapp', 'webapp_011')}<span className="total-cost-ticket">{gameData.Price}ETH</span></span>
      </div>
    );
  }
  
  render() {
    const {
      gameData,
      ticketAmount,
      freeTicketAmount,
      addToFavourite,
      isProcessing
    } = this.props;
    const {
      enterPin,
      pinNumber
    } = this.state;
    const total = (ticketAmount - freeTicketAmount) * gameData.Price; 
    
    if (!enterPin) {
      return (
        <div className="col-md-4 order-md-8">
          <section className="right-side-bar">
            <div className="sidebar-header">
              <h2>{LanguageData.getText('webapp', 'webapp_013')}</h2>
            </div>
            {this.renderInfo()}
            <div className="line line-sm pbc-line"></div>
            <div className="sidebar-footer-con">
              <span className="total-title">{LanguageData.getText('webapp', 'webapp_012')} : <span className="total-amount">{parseFloat(total).toFixed(DecimalPointsSettings)}ETH</span></span>
              <BuyTicketButton isProcessing={isProcessing}
                               onPress={this.onPressBuy} 
                               buttonText={LanguageData.getText('GamePlayNew', 'gpn_008')} />
            </div>
          </section>
        </div>
      );
    }
    
    return (
      <div className="col-md-4 order-md-8">
        <section className="right-side-bar">
            <div className="sidebar-header">
              <h2>{LanguageData.getText('webapp', 'webapp_013')}<span>{LanguageData.getText('GamePaymentPincodeVerify', 'gppcv_001')}</span></h2>
            </div>
            <div className="col_full col_mb pin-con">
              <label>{LanguageData.getText('webapp', 'webapp_014')}</label>
              <input type="password" id="payment-form-password" name="payement-form-password" className="form-control not-dark"
                     value={pinNumber} placeholder={LanguageData.getText('webapp', 'webapp_015')} onChange={this.onInputPin}
                     style={{background: 'rgb(255,255,255,.3)'}}/>
            </div>
            {this.renderInfo()}
            <div className="line line-sm pbc-line"></div>
            <div className="sidebar-footer-con">
              <span className="total-title mb-4">Total Amount : <span className="total-amount">{parseFloat(total).toFixed(DecimalPointsSettings)}ETH</span></span>
              <BuyTicketButton isProcessing={isProcessing}
                               onPress={this.onPressBuy} 
                               buttonText={LanguageData.getText('GamePlayNew', 'gpn_008')} />
              {addToFavourite === null? null :
                <a className="text-yellow float-left pbc-text-bold" 
                   style={Instyle.YellowText} onClick={this.addToFavourite}>{LanguageData.getText('CardsTicketCard', 'ctc_005')}</a>}
              <a className="text-yellow float-right pbc-text-bold" 
                 style={Instyle.YellowText} onClick={this.forgotPIN}>{LanguageData.getText('GamePaymentPincodeVerify', 'gppcv_007')}</a>
              <div className="clearfix"></div>
            </div>
        </section>
      </div>
    );
  }
}

PlayPaymentSection.defaultProps = {
  gameData: {},
  ticketAmount: 0,
  freeTicketAmount: 0,
  addTicket: null,
  addToFavourite: null,
  isProcessing: false
};

export default PlayPaymentSection;